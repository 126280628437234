import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './globals.css';
import '@statsbomb/kitbag-datavis/dist/style.css';
import './i18n';
import { BrowserRouter, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Providers } from './components/Providers';
import { PlayerRoutes } from './routes/playerRoutes';
import { DataLockerRoutes } from './routes/dataLockerRoutes';
import { RedirectToLocalised } from './components/routes/RedirectToLocalised';
import { ProtectedRoute } from './components/routes/ProtectedRoute';
import { SentryRoutes } from './utils/errorMonitoring';
import { TeamRoutes } from './routes/teamRoutes';
import { Head } from './components/head/Head';
import Report from './pages/Report';
import { RedirectToTeam } from './components/routes/RedirectToTeam';
import { Settings } from './pages/Settings';
import { ReportsPage } from './pages/report/ReportsPage';
import { GameRoutes } from './routes/gameRoutes';
import { InternalOnlyRoute } from './components/routes/InternalOnlyRoute';
import { AppShell } from './components/layout/AppShell';
import { HandleInvalidEntityUser } from './components/HandleInvalidEntityUser';
import { AppShellFullLoadingFallback } from './components/layout/AppShellFallback';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <HelmetProvider>
      <Head />
      <BrowserRouter>
        <Providers>
          <Suspense fallback={<AppShellFullLoadingFallback />}>
            <HandleInvalidEntityUser>
              <SentryRoutes>
                <Route path="/">
                  <Route
                    path=""
                    element={
                      <AppShellFullLoadingFallback>
                        <RedirectToLocalised />
                      </AppShellFullLoadingFallback>
                    }
                  />
                  <Route path=":locale" element={<AppShell />}>
                    <Route path="" element={<ProtectedRoute component={RedirectToTeam} />} />
                    <Route path="player/:playerId/*" element={<ProtectedRoute component={PlayerRoutes} />} />
                    <Route path="team/:teamId/*" element={<ProtectedRoute component={TeamRoutes} />} />
                    <Route path="data-locker/*" element={<ProtectedRoute component={DataLockerRoutes} />} />
                    <Route path="report/:reportId" element={<ProtectedRoute component={Report} />} />
                    <Route path="reports" element={<ProtectedRoute component={ReportsPage} />} />
                    <Route
                      path="match/:gameId/*"
                      element={
                        // TODO (PPC-1751: unhide game dashboard)
                        <InternalOnlyRoute>
                          <ProtectedRoute component={GameRoutes} />
                        </InternalOnlyRoute>
                      }
                    />
                    <Route path="settings/*" element={<ProtectedRoute component={Settings} />} />
                  </Route>
                </Route>
              </SentryRoutes>
            </HandleInvalidEntityUser>
          </Suspense>
        </Providers>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>,
);
