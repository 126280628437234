import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { WidgetVisType } from '@/types/widget';
import { useRadarTemplateName } from '@/hooks/useRadarTemplateName';
import { Card, CardHeader, NextBadge, Typography } from '@statsbomb/kitbag-components';
import { COMING_SOON_WIDGETS, HEADING_ONLY_NAVIGABLE_WIDGETS } from '@/consts/widget';

interface DashboardWidgetProps {
  children: React.ReactNode;
  navigationPath: string;
  vis: WidgetVisType;
}

interface DashboardWidgetWrapperProps {
  children: React.ReactNode;
  navigationPath: string;
  hasHeadingOnlyNavigation: boolean;
}

const WidgetWrapper = ({ navigationPath, hasHeadingOnlyNavigation, children }: DashboardWidgetWrapperProps) =>
  navigationPath && !hasHeadingOnlyNavigation ? (
    <Link to={navigationPath} className="[&_header]:underline [&:not(:hover)_header]:opacity-sb-secondary">
      {children}
    </Link>
  ) : (
    children
  );

const WidgetHeader = ({ navigationPath, hasHeadingOnlyNavigation, children }: DashboardWidgetWrapperProps) =>
  navigationPath && hasHeadingOnlyNavigation ? (
    <Link to={navigationPath} className="underline [&:not(:hover)]:opacity-sb-secondary">
      <CardHeader>{children}</CardHeader>
    </Link>
  ) : (
    <CardHeader>{children}</CardHeader>
  );

export const DashboardWidget = ({ vis, children, navigationPath }: DashboardWidgetProps) => {
  const { t } = useTranslation(['visualisation', 'general']);
  const radarTemplateName = useRadarTemplateName();
  const title = `${t(vis)}${vis === 'radar' ? ` - ${radarTemplateName}` : ''}`;
  const hasHeadingOnlyNavigation = HEADING_ONLY_NAVIGABLE_WIDGETS.includes(vis);
  const isComingSoon = COMING_SOON_WIDGETS.includes(vis);

  return (
    <WidgetWrapper navigationPath={navigationPath} hasHeadingOnlyNavigation={hasHeadingOnlyNavigation}>
      <Card>
        <WidgetHeader navigationPath={navigationPath} hasHeadingOnlyNavigation={hasHeadingOnlyNavigation}>
          <Typography variant="headingMedium" as="h3" className="w-full flex items-center gap-2">
            <div className="flex-1 truncate" title={title}>
              {title}
            </div>
            <div>
              {isComingSoon && (
                <NextBadge variant="subtle" hasIcon={false}>
                  {t('inDevelopment', { ns: 'general' })}
                </NextBadge>
              )}
            </div>
          </Typography>
        </WidgetHeader>
        {children}
      </Card>
    </WidgetWrapper>
  );
};
