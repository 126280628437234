import { StringOption } from '@/types/generic';
import { useTranslation } from 'react-i18next';

// Removes percentages from positions in options
export const useRawPositionOptions = (options: StringOption[]) => {
  const { t } = useTranslation('events');
  return options.map(option => {
    const key = option.value.includes(',') ? 'other' : option.value;
    return { ...option, label: t(`fields.event.attributes.playerPosition.${key}`) };
  });
};
