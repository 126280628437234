import { RadarTable } from '@/components/radar/table/RadarTable';
import { useHasEmptyRadar } from '@/hooks/useHasEmptyRadar';
import { RadarTableHeader } from '@/components/radar/table/RadarTableHeader';
import { PlayerFiltersSummary } from './PlayerFiltersSummary';

export const PlayerRadarSummary = () => {
  const hasEmptyRadar = useHasEmptyRadar();

  return (
    <div className="flex flex-col p-4 gap-2 @container">
      <RadarTable />
      {!hasEmptyRadar && <PlayerFiltersSummary />}
    </div>
  );
};

export const PlayerRadarTableGroup = () => (
  <div className="bg-canvas-tertiary-main flex-1">
    <RadarTableHeader />
    <PlayerRadarSummary />
  </div>
);
