import { useFilterStateHistory } from '@/hooks/useFilterStateHistory';
import { useOnBackButtonClick } from '@/hooks/useOnBackButtonClick';
import { useTheme } from 'styled-components';
import { AppBar, TopBarInfo } from '@statsbomb/kitbag-components';
import { ReactNode, Suspense } from 'react';
import { useApplicationReferenceData } from '@/hooks/dataFetching/useApplicationReferenceData';
import { SideNav } from '@/components/navigation/SideNav';
import { Outlet } from 'react-router-dom';
import { GenericToast } from '../alerts/GenericToast/GenericToast';

export const AppShell = () => (
  <>
    <div className="flex h-full isolate" data-testid="appshell">
      <div className="z-10">
        <SideNav />
      </div>
      <Outlet />
    </div>
    <GenericToast />
  </>
);

export const AppShellContentsBase = ({ topBar, children }: { topBar: ReactNode; children: ReactNode }) => {
  useFilterStateHistory();
  useOnBackButtonClick();

  const theme = useTheme();
  const isTouch = theme.mqMatch('md');

  return (
    <div className="flex flex-col w-full overflow-x-hidden grow appbar-wrapper">
      <AppBar version={2}>
        <TopBarInfo isTouch={isTouch}>{topBar}</TopBarInfo>
      </AppBar>
      {children}
    </div>
  );
};

export const AppShellContentsWithData = ({ topBar, children }: { topBar: ReactNode; children: ReactNode }) => {
  useApplicationReferenceData();
  return <AppShellContentsBase topBar={topBar}>{children}</AppShellContentsBase>;
};

export const AppShellContents = ({ topBar, children }: { topBar: ReactNode; children: ReactNode }) => (
  <Suspense fallback={<AppShellContentsBase topBar={topBar}>{children}</AppShellContentsBase>}>
    <AppShellContentsWithData topBar={topBar}>{children}</AppShellContentsWithData>
  </Suspense>
);
