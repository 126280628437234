import { pageAtom } from '@/atoms/pagination';
import { clientOrderByAtom, clientOrderDirectionAtom, orderByAtom, orderDirectionAtom } from '@/atoms/table';
import { SORT_DIRECTION } from '@/types/ordering';
import { useAtom, useSetAtom } from 'jotai';

export const useSortTable = (hasRetrievedAllAggs = false) => {
  const [orderBy, setOrderTableBy] = useAtom(orderByAtom);
  const [orderDirection, setOrderDirection] = useAtom(orderDirectionAtom);
  const setPageAtom = useSetAtom(pageAtom);
  const setClientOrderTableBy = useSetAtom(clientOrderByAtom);
  const setClientOrderDirection = useSetAtom(clientOrderDirectionAtom);

  const handleSort = (newOrderBy: string, isLowerBetter: boolean = false) => {
    const isDifferentColumn = newOrderBy !== orderBy;
    const initialOrderDirection = isLowerBetter ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC;
    const newOrderDirection = orderDirection === SORT_DIRECTION.DESC ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC;

    const direction = isDifferentColumn ? initialOrderDirection : newOrderDirection;

    setPageAtom(1);
    // If we've got all the data in memory, we just want to perform a client side sort
    if (hasRetrievedAllAggs) {
      setClientOrderDirection(direction);
      setClientOrderTableBy(newOrderBy);
    } else {
      setOrderDirection(direction);
      setOrderTableBy(newOrderBy);
    }
  };

  // The Table.HeaderCell needs the ASC/DESC things in lower case (although good luck working that out as the type is string | undefined!)
  const getSortedState = (key: string) => (orderBy === key ? orderDirection : 'default');

  return { orderBy, orderDirection, handleSort, getSortedState };
};
