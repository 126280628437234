import { HeatMap } from '@/components/visualisations/HeatMap';
import {
  playerEventMarkersForMetricAtom,
  unwrappedPlayerEventMarkersForMetricAtom,
} from '@/atoms/queries/player/playerEventData';
import { MetricKeyFallbackWrapper } from '@/components/metric/MetricKeyFallbackWrapper';

export const PlayerHeatMap = () => (
  <MetricKeyFallbackWrapper>
    <HeatMap
      pitchEventsAtom={playerEventMarkersForMetricAtom}
      unwrappedPitchEventsAtom={unwrappedPlayerEventMarkersForMetricAtom}
    />
  </MetricKeyFallbackWrapper>
);
