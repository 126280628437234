import { EVENT_MAP_LEGEND_XG_LABELS } from '@/consts/visualisations';
import { ClassifiedMarkers, GradientDots } from '@statsbomb/kitbag-datavis';
import { useTranslation } from 'react-i18next';

export const EventMapXgLegend = ({ legendWidth }: { legendWidth: number }) => {
  const { t } = useTranslation('visualisation');

  return (
    <>
      <GradientDots
        labelValues={EVENT_MAP_LEGEND_XG_LABELS}
        title={t('markerColouring.xg')}
        colSpan={2}
        gradientName="thermometer"
        legendWidth={legendWidth}
      />
      <ClassifiedMarkers title={t('xgColouring.noXg')} markers={[{ label: t('xgColouring.nonShotEvent') }]} />
    </>
  );
};
