import { PlayerPreviewEvents } from '@/components/player/radar/preview/PlayerPreviewEvents';
import { EntityPreview } from '@/components/preview/EntityPreview';
import { playerEventsForMetricCountAtom } from '@/atoms/queries/player/playerEventData';
import { useAtomValue } from 'jotai';
import { playerIdAtom } from '@/atoms/player/player';
import { playerPitchVisComponents } from '@/consts/visualisationComponents';

export const PlayerPreview = () => {
  const playerId = useAtomValue(playerIdAtom);

  return (
    <EntityPreview
      EntityEventMap={playerPitchVisComponents.eventMap}
      eventsForMetricCountAtom={playerEventsForMetricCountAtom}
      pathTo={`../../../player/${playerId}/vis`}
      entity="player"
    >
      <PlayerPreviewEvents />
    </EntityPreview>
  );
};

export default PlayerPreview;
