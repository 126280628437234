import { selectedGameTypeAtom } from '@/atoms/filters/gameMetrics';
import { gameTypeOptions } from '@/consts/gameMetrics';
import { getTranslatedOptions } from '@/utils/translations';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { BaseOption } from '@/types/generic';
import { GameSelectorProps } from '@/components/filters/GameSelector';
import { useGetGameCount } from '@/hooks/useGetGameCount';
import { Typography } from '@statsbomb/kitbag-components';
import { selectedEndDateAtom, selectedPeriodRangeAtom, selectedStartDateAtom } from '@/atoms/filters/highLevel';
import { GameType } from '@/types/filters';

const getSelectedGameTypeOption = (options: BaseOption<string>[], selectedValue: string) =>
  options.find(option => option.value === selectedValue);

export const RadarFilterGameSummary = ({ gamesGroupedBySeasonAtom, selectedGamesAtom }: GameSelectorProps) => {
  const { t } = useTranslation(['entity', 'filters']);
  const selectedPeriodRange = useAtomValue(selectedPeriodRangeAtom);
  const selectedStartDate = useAtomValue(selectedStartDateAtom);
  const selectedEndDate = useAtomValue(selectedEndDateAtom);
  const selectedGameType = getSelectedGameTypeOption(
    getTranslatedOptions(gameTypeOptions),
    useAtomValue(selectedGameTypeAtom).value,
  );
  const { allSelected, gameCount } = useGetGameCount(selectedGamesAtom, gamesGroupedBySeasonAtom);
  const startDate = selectedStartDate ? `${t('dateFrom', { ns: 'filters' })} ${selectedStartDate}` : '';
  const endDate = selectedEndDate ? `${t('dateTo', { ns: 'filters' })} ${selectedEndDate}` : '';

  const period =
    selectedPeriodRange.translation.key === 'lastXGames'
      ? t('lastXGames', { ns: 'filters', count: selectedPeriodRange.value })
      : null;
  const date = startDate || endDate ? `${t('dateRange', { ns: 'filters' })} ${startDate} ${endDate}`.trim() : null;
  const gameType = selectedGameType && selectedGameType.value !== GameType.HOME_AWAY ? selectedGameType.label : null;
  const count = !allSelected ? t('countSelected', { ns: 'filters', count: gameCount }) : null;

  const summaryText = [period, date, gameType, count].filter(Boolean).join(', ');

  return (
    // TODO (KC-360: Replace with Card component once available)
    <div className="bg-canvas-secondary-main px-4 py-2">
      <Typography variant="headingMedium" as="h2" className="pb-1">
        {t('game.one')}
      </Typography>
      <div className="flex gap-x-2 flex-wrap">
        <div className="flex gap-x-2 items-center w-max">
          <Typography variant="bodyRegular" as="div">
            {summaryText || t('allGames', { ns: 'filters' })}
          </Typography>
        </div>
      </div>
    </div>
  );
};
