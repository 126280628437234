import React, { Suspense, useState } from 'react';
import { ButtonIcon, Typography, useOutsideClick, SearchField } from '@statsbomb/kitbag-components';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { searchValueAtom } from '@/atoms/search/search';
import { loadableSearchQueryResult, unwrappedSearchQueryResult } from '@/atoms/queries/search';
import { Search as SearchType } from '@statsbomb/parachute-types';
import { useTranslation } from 'react-i18next';
import { resetAllFiltersAtom } from '@/atoms/filters/resetFilters';
import { selectedFilterSetIdAtom } from '@/atoms/filters/userFilters';
import { isSettingsPanelOpenAtom } from '@/atoms/sidebar';
import { twJoin } from 'tailwind-merge';
import { ErrorBoundary } from '@sentry/react';
import { SearchResults } from './SearchResults';

const SearchBase = ({ searchResults }: { searchResults?: SearchType }) => {
  const { t } = useTranslation(['search', 'general']);
  const [searchValue, setSearchValue] = useAtom(searchValueAtom);
  const [value] = useAtom(loadableSearchQueryResult);
  const [isResultsOpen, setIsResultsOpen] = useState(false);
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);
  const wrapperRef = useOutsideClick<HTMLDivElement>(() => setIsResultsOpen(false));
  const resetAllFilters = useSetAtom(resetAllFiltersAtom);
  const isLoading = value.state === 'loading';
  const setSelectedFilterSetId = useSetAtom(selectedFilterSetIdAtom);
  const setIsSettingsPanelOpen = useSetAtom(isSettingsPanelOpenAtom);
  return (
    <>
      <div className="md:hidden">
        <ButtonIcon
          title={t('search')}
          displayText="hidden"
          icon="Search"
          onClick={() => setIsMobileSearchOpen(true)}
          variant="ghost"
        />
      </div>
      <div
        className={twJoin(isMobileSearchOpen ? 'mobile-search-open' : 'hidden', 'search-wrapper md:block')}
        ref={wrapperRef}
      >
        <div
          className={twJoin(
            !isMobileSearchOpen && 'hidden',
            'flex w-full justify-between items-center gap-4 md:!hidden',
          )}
        >
          <Typography variant="headingRegular" data-testid="search-heading">
            {t('search')}
          </Typography>
          <ButtonIcon
            title={t('close', { ns: 'general' })}
            displayText="hidden"
            icon="Close"
            onClick={() => setIsMobileSearchOpen(false)}
            variant="ghost"
          />
        </div>
        <div className="search-field-wrapper">
          <SearchField
            isLoading={isLoading}
            clearTitle={t('clear', { ns: 'general' })}
            isFocused={isMobileSearchOpen}
            searchIconTitle={t('search', { ns: 'general' })}
            onClear={() => {
              setSearchValue('');
              setIsResultsOpen(false);
            }}
            onClick={() => setIsResultsOpen(true)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setIsResultsOpen(true);
              setSearchValue(e.target.value);
            }}
            placeholder={t('placeholder', { ns: 'search' })}
            value={searchValue}
          />
        </div>
        {isResultsOpen && searchResults && (
          <SearchResults
            searchResults={searchResults as SearchType}
            onClick={() => {
              setIsSettingsPanelOpen(false);
              setIsResultsOpen(false);
              setIsMobileSearchOpen(false);
              setSearchValue('');
              setSelectedFilterSetId(null);
              resetAllFilters();
            }}
          />
        )}
      </div>
    </>
  );
};

const SearchWithData = () => {
  const searchResults = useAtomValue(unwrappedSearchQueryResult);

  return <SearchBase searchResults={searchResults as SearchType} />;
};

export const Search = () => (
  <ErrorBoundary fallback={<SearchBase />}>
    <Suspense fallback={<SearchBase />}>
      <SearchWithData />
    </Suspense>
  </ErrorBoundary>
);
