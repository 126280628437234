import { useEffect } from 'react';
import { useSetAtom } from 'jotai';
import { useParams } from 'react-router-dom';
import { gameIdAtom } from '@/atoms/game/game';

export const useSetGameId = () => {
  const { gameId } = useParams();
  if (!gameId) throw new Error('Missing param in URL: gameId');
  const setGameId = useSetAtom(gameIdAtom);
  useEffect(() => setGameId(Number(gameId)), [gameId]);
};
