import { selectedEventNamesAtom } from '@/atoms/filters/events';
import { sectionAtom } from '@/atoms/general';
import { metricKeyAtom } from '@/atoms/metric';
import { selectedVisualisationAtom, showLegendAtom } from '@/atoms/visualisation';
import { useExportInfo } from '@/hooks/exporting/useExportInfo';
import { snakeToCamel } from '@/utils/queries';
import { useAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Suspense } from 'react';
import { MenuItem, MenuItemText, Toggle } from '@statsbomb/kitbag-components';
import { SettingsButton } from '../button/SettingsButton';
import { VisualisationMenu } from '../visualisations/VisualisationMenu';
import { MoreOptionsMenuFallback } from '../menu/MoreOptionsMenu/MoreOptionsMenu';

const VisControlsFallback = () => (
  <>
    <SettingsButton />
    <MoreOptionsMenuFallback />
  </>
);

const VisControlsWithData = () => {
  const { t } = useTranslation(['visualisation', 'events', 'metrics']);
  const selectedVis = useAtomValue(selectedVisualisationAtom);
  const section = useAtomValue(sectionAtom);
  const selectedMetricKey = useAtomValue(metricKeyAtom);
  const entitySecondaryTitle = selectedMetricKey ? t(`${snakeToCamel(selectedMetricKey)}.name`, { ns: 'metrics' }) : '';
  const selectedEvents = useAtomValue(selectedEventNamesAtom);
  const translatedSelectedEvents = selectedEvents.map(key => ({
    label: t(`fields.event.type.${snakeToCamel(key)}`, 'events'),
    value: key,
  }));
  const dataLockerSecondaryTitle = useExportInfo(translatedSelectedEvents, 'Events', 3);

  const secondaryTitle = section === 'dataLocker' ? dataLockerSecondaryTitle : entitySecondaryTitle;

  const [showLegend, setShowLegend] = useAtom(showLegendAtom);

  return (
    <span className="flex" data-testid="vis-controls">
      <SettingsButton />
      <VisualisationMenu
        saveToReportProps={{
          visType: selectedVis.visualisationKey,
          dataType: 'event',
        }}
        exportModalProps={{
          visName: t(selectedVis.visualisationKey, { ns: 'visualisation' }),
          secondaryTitle,
        }}
        keysToExcludeFromClose={['showLegend']}
      >
        <MenuItem key="showLegend">
          <MenuItemText>{t('showLegend')}</MenuItemText>
          <Toggle
            id="legend-toggle"
            checked={showLegend}
            onChange={() => setShowLegend(!showLegend)}
            hasLabel={false}
            size="small"
            label={t('showLegend')}
          />
        </MenuItem>
      </VisualisationMenu>
    </span>
  );
};

export const VisControls = () => (
  <Suspense fallback={<VisControlsFallback />}>
    <VisControlsWithData />
  </Suspense>
);
