/* istanbul ignore file */
import { PlayerLayout } from '@/components/layout/player/PlayerLayout';
import { PlayerRadarPage } from '@/pages/player/radar/PlayerRadarPage';
import { PlayerVideoPage } from '@/pages/player/video/PlayerVideoPage';
import { PlayerVisPage } from '@/pages/player/vis/PlayerVisPage';
import { PlayerVideoPreview } from '@/pages/player/video/PlayerVideoPreview';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotFound } from '@/pages/NotFound';
import { PlayerPreview } from '@/pages/player/preview/PlayerPreview';
import { VideoPreview } from '@/components/video/VideoPreview';
import { PlayerGamesPage } from '@/pages/player/games/PlayerGamesPage';
import { InternalOnlyRoute } from '@/components/routes/InternalOnlyRoute';
import { PlayerGamesVideoPage } from '@/pages/player/games/video/PlayerGamesVideoPage';
import { ProtectedVideoRoute } from '@/components/routes/ProtectedVideoRoute';
import { PlayerDashboardPage } from '@/pages/player/dashboard/PlayerDashboardPage';

export const PlayerRoutes = () => (
  <Routes>
    <Route element={<PlayerLayout />}>
      <Route path="dashboard" element={<PlayerDashboardPage />} />
      <Route path="radar" element={<PlayerRadarPage />}>
        <Route path="preview" element={<PlayerPreview />} />
      </Route>
      <Route path="vis" element={<PlayerVisPage />}>
        <Route path="preview" element={<ProtectedVideoRoute component={<VideoPreview />} />} />
      </Route>
      <Route path="vis/video" element={<ProtectedVideoRoute component={<PlayerVideoPage />} />}>
        <Route path="preview" element={<ProtectedVideoRoute component={<PlayerVideoPreview />} />} />
      </Route>
      <Route path="matches" element={<PlayerGamesPage />} />
      <Route
        path="matches/video"
        element={
          <InternalOnlyRoute>
            <ProtectedVideoRoute component={<PlayerGamesVideoPage />} />
          </InternalOnlyRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Route>
  </Routes>
);
