import { MIN_GAME_TIME, MAX_GAME_TIME_OFF, MAX_GAME_TIME_ON } from '@/consts/minutesPlayedVis';
import { BarChartData, useGradient } from '@statsbomb/kitbag-datavis';
import { PlayerGameMinutesWithRels } from '@statsbomb/parachute-types';

/* 
  These minute value function are a temporary way to render the data as simply as possible
  for v1.0 of the minutes played vis outlined in the notion doc: 
  https://www.notion.so/statsbomb/Player-Minutes-Played-Parachute-Migration-v1-0-20165d59744f4a4b9c6f9f607727a890
*/
const getMinutesOnValue = (gameTimeOn: number) => {
  if (gameTimeOn >= MAX_GAME_TIME_ON) return MAX_GAME_TIME_ON;

  return gameTimeOn;
};

const getMinutesOffTime = (gameTimeOn: number, gameTimeOff: number) => {
  if (gameTimeOn <= MIN_GAME_TIME && gameTimeOff <= MIN_GAME_TIME) return MIN_GAME_TIME;
  if (gameTimeOff >= MAX_GAME_TIME_OFF) return MAX_GAME_TIME_OFF;

  return gameTimeOff;
};

export const useMinutesPlayedData = (minutesPlayedData: PlayerGameMinutesWithRels[]): BarChartData[] => {
  const { gradientScale } = useGradient('unvalued', [0, 90]);

  return minutesPlayedData.map(({ game, minutes }) => {
    const timeOnValue = getMinutesOnValue(minutes.game_time_minute_on);
    const timeOffValue = getMinutesOffTime(minutes.game_time_minute_on, minutes.game_time_minute_off);
    /* we divide by 100 as the scale needs a number from 0 - 1 in the domain range
    so this converts our minutes played value into a 0 - 1 version of that */
    const colour = gradientScale((timeOffValue - timeOnValue) / 100);

    return {
      id: game.game_id,
      /* game.date can be null */
      category: game.date || '',
      value: timeOnValue,
      endValue: timeOffValue,
      colour,
    };
  });
};
