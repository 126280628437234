import { ReportsTable } from '@/components/report/ReportsTable/ReportsTable';
import { PageTitle } from '@/components/head/PageTitle';
import { AppShellContents } from '@/components/layout/AppShell';
import { EntityTopBarBase } from '@/components/topBar/EntityTopBar';
import { CardHeader, Typography } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { FullScreenCard } from '@/components/card/FullScreenCard';
import { CreateNewReportButton } from '@/components/report/CreateNewReportButton/CreateNewReportButton';
import { DeleteReportModal } from '@/components/modal/DeleteReportModal/DeleteReportModal';
import { RenameReportModal } from '@/components/modal/RenameReportModal/RenameReportModal';

export const ReportsPage = () => {
  const { t } = useTranslation('report');

  return (
    <>
      <PageTitle>{t('reportsHeading', { ns: 'report' })}</PageTitle>
      <AppShellContents topBar={<EntityTopBarBase entityName={t('reportsHeading', { ns: 'report' })} />}>
        <FullScreenCard>
          <CardHeader>
            <div className="flex gap-x-4">
              <Typography variant="headingRegular">{t('reportsTitle', { ns: 'report' })}</Typography>
              <CreateNewReportButton />
            </div>
          </CardHeader>
          <ReportsTable />
        </FullScreenCard>
        <RenameReportModal />
        <DeleteReportModal />
      </AppShellContents>
    </>
  );
};
