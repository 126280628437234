import { PitchViz } from '@statsbomb/kitbag-datavis';
import { useAtomValue } from 'jotai';
import { EventMapBaseProps, EventMapProps } from '@/types/visualisation';
import { usePitchCrop } from '@/hooks/usePitchCrop';
import { useGetPitchRotation } from '@/hooks/useGetPitchRotation';
import { showLegendAtom } from '@/atoms/visualisation';
import { useLoadableStatus } from '@/hooks/useLoadableStatus';
import { useStatus } from '@/hooks/useStatus';
import { CardBody } from '@statsbomb/kitbag-components';
import { ReactNode } from 'react';
import { PitchVisWithLegend } from './PitchVisualisation/PitchVisWrapper';
import { PitchVisWithAttackingDirectionArrow } from './PitchVisualisation/PitchVisWithAttackingDirectionArrow';
import { CardHeightWrapper } from '../card/CardHeightWrapper';
import { EventMapLayers } from './EventMap/EventMapLayers';
import { EventMapSelectionLayer } from './EventMap/EventMapSelectionLayer';
import { EventMapPreview } from './EventMap/EventMapPreview';
import { ErrorCard } from '../contentState/ErrorCard';

const EventMapVis = ({ children }: { children: ReactNode }) => {
  const pitchRotation = useGetPitchRotation(false);
  const showLegend = useAtomValue(showLegendAtom);

  return (
    <PitchVisWithLegend>
      {/* Arrow direction is shown and hidden with the legend */}
      {showLegend ? (
        <PitchVisWithAttackingDirectionArrow pitchVisType="eventmap" pitchRotation={pitchRotation}>
          {children}
        </PitchVisWithAttackingDirectionArrow>
      ) : (
        children
      )}
    </PitchVisWithLegend>
  );
};

const EventMapBase = ({ allowInteraction, unwrappedPitchEventsAtom }: EventMapBaseProps) => {
  const pitchRotation = useGetPitchRotation(false);
  const visibleArea = usePitchCrop(false);
  const unwrappedPitchEvents = useAtomValue(unwrappedPitchEventsAtom);

  const eventMapLayers = (
    <EventMapLayers
      markerEvents={unwrappedPitchEvents.markerEvents}
      arrowEvents={unwrappedPitchEvents.arrowEvents}
      allowInteraction={allowInteraction}
    />
  );

  return (
    <EventMapVis>
      <PitchViz
        /* this is intentionally empty to overwrite pitch viz svg styles
to position the arrow correctly */
        className=""
        rotationName={pitchRotation}
        pitchFocusZoneName={visibleArea}
        overlay={
          allowInteraction ? (
            <EventMapSelectionLayer unwrappedPitchEventsAtom={unwrappedPitchEventsAtom}>
              {eventMapLayers}
            </EventMapSelectionLayer>
          ) : (
            eventMapLayers
          )
        }
      />
    </EventMapVis>
  );
};

const EventMapWithData = ({
  pitchEventsAtom,
  unwrappedPitchEventsAtom,
  allowInteraction,
  isPreview,
}: EventMapProps) => {
  const status = useLoadableStatus(pitchEventsAtom);
  const statusProps = useStatus('general', status);

  return (
    <CardHeightWrapper>
      <CardBody {...statusProps}>
        {!isPreview ? (
          <EventMapBase unwrappedPitchEventsAtom={unwrappedPitchEventsAtom} allowInteraction={allowInteraction} />
        ) : (
          <EventMapPreview unwrappedPitchEventsAtom={unwrappedPitchEventsAtom} />
        )}
      </CardBody>
    </CardHeightWrapper>
  );
};

export const EventMap = (props: EventMapProps) => {
  const pitchRotation = useGetPitchRotation(false);
  const visibleArea = usePitchCrop(false);

  return (
    <ErrorCard
      translationNameSpace="general"
      fallbackChildren={
        <EventMapVis>
          <PitchViz
            /* this is intentionally empty to overwrite pitch viz svg styles
      to position the arrow correctly */
            className=""
            rotationName={pitchRotation}
            pitchFocusZoneName={visibleArea}
            overlay={<EventMapLayers markerEvents={[]} arrowEvents={[]} />}
          />
        </EventMapVis>
      }
    >
      <EventMapWithData {...props} />
    </ErrorCard>
  );
};
