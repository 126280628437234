import { Card, CardBody } from '@statsbomb/kitbag-components';
import { useStatus } from '@/hooks/useStatus';
import { Status, StatusTranslationKey } from '@/types/status';
import { ReactNode } from 'react';
import { EmptyTopBar, TopBar } from '../topBar/TopBar';
import { AppShellContentsBase } from './AppShell';
import { CardHeightWrapper } from '../card/CardHeightWrapper';
import { EmptySideNav } from '../navigation/SideNav';

const AppShellFallbackBase = ({
  statusDescription,
  statusTitle,
  statusType,
  topBar = <TopBar />,
  children,
}: {
  statusDescription: string;
  statusTitle: string;
  statusType: string;
  topBar?: ReactNode;
  children?: ReactNode;
}) => (
  <AppShellContentsBase topBar={topBar}>
    <Card>
      <CardHeightWrapper>
        <CardBody statusDescription={statusDescription} statusTitle={statusTitle} statusType={statusType} />
      </CardHeightWrapper>
    </Card>
    {children}
  </AppShellContentsBase>
);

export const AppShellFallback = ({
  topBar,
  status,
  children,
}: {
  topBar?: ReactNode;
  status: StatusTranslationKey;
  children?: ReactNode;
}) => {
  const statusProps = useStatus('appShell', status);

  if (!statusProps.statusType) return null;

  return (
    <AppShellFallbackBase topBar={topBar} {...statusProps}>
      {children}
    </AppShellFallbackBase>
  );
};

export const AppShellFullBase = ({
  status,
  topBar = <EmptyTopBar />,
  children,
}: {
  status: Status;
  topBar?: ReactNode;
  children?: ReactNode;
}) => (
  <div className="flex h-full isolate" data-testid="appshell">
    <div className="z-10">
      <EmptySideNav />
    </div>
    <AppShellContentsBase topBar={topBar}>
      <Card>
        <CardHeightWrapper>
          <CardBody {...status} />
        </CardHeightWrapper>
      </Card>
    </AppShellContentsBase>
    {children}
  </div>
);

export const AppShellFullLoadingFallback = ({ children }: { children?: ReactNode }) => {
  const status = useStatus('appShell', StatusTranslationKey.LOADING) as Status;

  return (
    <AppShellFullBase status={status} topBar={<TopBar />}>
      {children}
    </AppShellFullBase>
  );
};

export const AppShellErrorFallback = () => <AppShellFallback status={StatusTranslationKey.ERROR} />;
export const AppShellLoadingFallback = () => <AppShellFallback status={StatusTranslationKey.LOADING} />;
