export const PITCH_ORIENTATION = ['HORIZONTAL', 'VERTICAL', 'VERTICAL_REVERSE', 'HORIZONTAL_REVERSE'] as const;
export const PITCH_CROP = [
  'FULL_PITCH',
  'HOME_BOX',
  'HOME_THIRD',
  'HOME_HALF',
  'OPPOSITION_BOX',
  'OPPOSITION_THIRD',
  'OPPOSITION_HALF',
  'CENTRE_THIRD',
] as const;
export const EVENT_MARKERS_LIMIT = 1500;

export const EVENT_MAP_LEGEND_XG_LABELS = ['0', '0.05', '0.1', '0.2', '0.5', '1'];

export const SAVED_SHOT_OUTCOMES = ['saved', 'saved_off_t', 'saved_to_post'];

export const SHOT_MAP_MARKER_STROKE_WIDTH = {
  DEFAULT: 0.2,
  GOAL: 0.3,
};

export const SHOT_MAP_GOAL_DASHARRAY = '0.3 0.1';

export const PITCH_COLOURING_MODES = ['default', 'passSuccess', 'xg'] as const;
