import { toastDataAtom } from '@/atoms/toast';
import { useDeleteUserConfig } from '@/hooks/useDeleteUserConfig';
import { useAtom, useSetAtom } from 'jotai';
import { Suspense } from 'react';
import { filterSetIdToDeleteAtom, filterSetNameAtom } from '@/atoms/filters/userFilters';
import { useTranslation } from 'react-i18next';
import { RESET } from 'jotai/utils';
import { GenericDeleteModal } from '../GenericDeleteModal/GenericDeleteModal';

const DeleteFilterSetModalWithData = () => {
  const deleteUserConfig = useDeleteUserConfig();
  const setToastData = useSetAtom(toastDataAtom);

  const [filterSetIdToDelete, setFilterSetIdToDelete] = useAtom(filterSetIdToDeleteAtom);
  const [filterSetName, setFilterSetName] = useAtom(filterSetNameAtom);

  const { t } = useTranslation('filters');

  return (
    filterSetIdToDelete && (
      <GenericDeleteModal
        id="delete-filters-modal"
        itemToDelete={filterSetName}
        itemType={t('filterSet.one').toLowerCase()}
        onConfirm={async () => {
          const isSuccess = await deleteUserConfig(filterSetIdToDelete);
          setToastData('deleteFilters', isSuccess, filterSetName);

          setFilterSetIdToDelete(RESET);
          setFilterSetName(RESET);
        }}
        onCancel={() => {
          setFilterSetIdToDelete(RESET);
          setFilterSetName(RESET);
        }}
      />
    )
  );
};

export const DeleteFilterSetModal = () => (
  <Suspense>
    <DeleteFilterSetModalWithData />
  </Suspense>
);
