import { useGetPitchRotation } from '@/hooks/useGetPitchRotation';
import { usePitchCrop } from '@/hooks/usePitchCrop';
import { FallBackProps } from '@/types/visualisation';
import { PitchViz } from '@statsbomb/kitbag-datavis';
import { useAtomValue } from 'jotai';
import { showLegendAtom } from '@/atoms/visualisation';
import { useStatus } from '@/hooks/useStatus';
import { StatusTranslationKey } from '@/types/status';
import { CardBody } from '@statsbomb/kitbag-components';
import { PitchVisWithAttackingDirectionArrow } from './PitchVisualisation/PitchVisWithAttackingDirectionArrow';
import { PitchVisWithLegend } from './PitchVisualisation/PitchVisWrapper';
import { CardHeightWrapper } from '../card/CardHeightWrapper';

export const FallbackLoading = ({ isPreview = false }: FallBackProps) => {
  const pitchRotation = useGetPitchRotation(isPreview);
  const visibleArea = usePitchCrop(isPreview);
  const statusProps = useStatus('general', StatusTranslationKey.LOADING);
  const showLegend = useAtomValue(showLegendAtom);

  const pitchViz = <PitchViz rotationName={pitchRotation} pitchFocusZoneName={visibleArea} />;

  return (
    <PitchVisWithLegend>
      <CardHeightWrapper>
        <CardBody {...statusProps}>
          {showLegend ? (
            <PitchVisWithAttackingDirectionArrow pitchRotation={pitchRotation}>
              {pitchViz}
            </PitchVisWithAttackingDirectionArrow>
          ) : (
            pitchViz
          )}
        </CardBody>
      </CardHeightWrapper>
    </PitchVisWithLegend>
  );
};
