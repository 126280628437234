import { unwrappedTeamGameDataVisibleColumnsAtom } from '@/atoms/dataLocker/team/game/table';
import { defaultSeasonsAtom } from '@/atoms/filters/highLevel/seasons';
import { tableColumnsAtom } from '@/atoms/queries/tableColumns';
import { teamGameAggsAtom } from '@/atoms/queries/team/teamGameAggData';
import { hasVideoLicenceAtom } from '@/atoms/user';
import { GeneralErrorBoundaryFallback } from '@/components/errorBoundary/ErrorBoundaryFallback';
import { GameAggregatesTable } from '@/components/tables/GameAggregatesTable';
import { TEAM_GAMES_DEFAULT_COLUMNS } from '@/components/tables/GameAggregatesTable.constants';
import { ErrorBoundary } from '@sentry/react';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';

const TeamGameAggsTableWithData = () => {
  const data = useAtomValue(teamGameAggsAtom);
  const hasDefaultSeason = useAtomValue(defaultSeasonsAtom);
  const isVideoColumnVisible = useAtomValue(hasVideoLicenceAtom);
  const tableColumns = useAtomValue(tableColumnsAtom);
  const visibleColumns = useAtomValue(unwrappedTeamGameDataVisibleColumnsAtom);

  return (
    <GameAggregatesTable
      data={data}
      visibleColumns={visibleColumns}
      availableColumns={tableColumns}
      isLoading={!hasDefaultSeason}
      isVideoColumnVisible={isVideoColumnVisible}
    />
  );
};

export const TeamGameAggsTable = () => (
  <ErrorBoundary fallback={<GeneralErrorBoundaryFallback />}>
    <Suspense fallback={<GameAggregatesTable isLoading visibleColumns={[...TEAM_GAMES_DEFAULT_COLUMNS]} />}>
      <TeamGameAggsTableWithData />
    </Suspense>
  </ErrorBoundary>
);
