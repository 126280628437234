import { Playlist, VideoEvent } from '@statsbomb/video-client-js';
import { WideFirstColumnLayout } from '@/components/layout/WideFirstColumnLayout';
import { VideoProvider } from '@/components/Providers';
import { useAtomValue } from 'jotai';
import { useSetGameIdForVideos } from '@/hooks/useSetGameIdForVideos';
import { gameGoalEventDescriptionsAtom, gameGoalsEventVideoIdsAtom } from '@/atoms/queries/game';
import { useFormatVideoEvents } from '@/hooks/useFormatVideoEvents';

export const GameGoalsVideoPage = () => {
  useSetGameIdForVideos();

  const gameGoalsEventVideoIds = useAtomValue(gameGoalsEventVideoIdsAtom);
  const gameGoalEventDescriptions = useAtomValue(gameGoalEventDescriptionsAtom);
  const videoEvents = useFormatVideoEvents(gameGoalsEventVideoIds, gameGoalEventDescriptions);

  return (
    <div className="h-full video-column-order [&>div]:h-full">
      <VideoProvider>
        <WideFirstColumnLayout>
          <Playlist />
          <VideoEvent autoPlay events={videoEvents} />
        </WideFirstColumnLayout>
      </VideoProvider>
    </div>
  );
};
