import { QueryClient } from '@tanstack/query-core';
import env from '@/env';
import * as Sentry from '@sentry/react';
import { BEARER_TOKEN_NOT_SET, CLIENT_RETRY_COUNT, FETCH_ERROR } from '@/consts/client';

/* this returns the error code from the message thrown in the fetch
if it can't get that, i.e it's not a `Fetch error:` returns undefined */
const getFetchErrorCode = (errorMessage: string) => {
  const errorCode = errorMessage.split(`${FETCH_ERROR} `)[1];
  if (!errorCode) return undefined;
  return errorCode;
};

export const getQueryClientCanRetry = (failureCount: number, error: Error) => {
  if (error.message === BEARER_TOKEN_NOT_SET) return true;

  const fetchErrorCode = getFetchErrorCode(error.message);
  /* this checks for the fetch error code being a 4xx error
  and if it is we don't try to refetch as somethings got wrong with the request */
  if (fetchErrorCode && fetchErrorCode.length > 0 && fetchErrorCode[0] === '4') {
    return false;
  }

  /* this check is because sometimes our tests won't fail correctly
  because it will retry 3 times which takes too long so they fail
  doing this so we don't retry in our tests as it's unnecessary  */
  if (env.VITE_NODE_ENV === 'test') {
    return false;
  }

  /* ignoring as due to the test clause this will never get here */
  /* istanbul ignore next */
  return failureCount <= CLIENT_RETRY_COUNT;
};

export const getQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 3600000, // 1 hour
        retry: getQueryClientCanRetry,
        throwOnError: true,
      },
    },
  });

export const getFetchClient = (authToken: Promise<string>) => async (input: RequestInfo, init?: RequestInit) => {
  const bearer = await authToken;

  // Wrapping the fetch within Sentry startSpan to allow Sentry to capture how long API calls take
  // https://docs.sentry.io/platforms/javascript/performance/instrumentation/custom-instrumentation/#start-span
  const result = await Sentry.startSpan({ op: 'fetch', name: `Fetch: ${input}` }, async span => {
    const response = await window.fetch(`${env.VITE_PARACHUTE_REST_API_ENDPOINT}${input}`, {
      ...init,
      headers: {
        ...init?.headers,
        Authorization: `Bearer ${bearer}`,
      },
    });

    if (!response.ok) {
      span?.setStatus('unknown_error');
      throw new Error(`${FETCH_ERROR} ${response.status} at ${input}`);
    }

    span?.setStatus('ok');

    // for requests that don't return a body, response.json() gives errors
    // this check is to confirm there is a request body and then parse it
    // else just return the response
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      const data = await response.json();
      return data;
    }
    return response;
  });

  return result;
};
