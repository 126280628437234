import { NestedObject } from '@/types/object';

export const convertGameTimeToSeconds = (gameTime: string) => {
  const [hours, minutes, seconds] = gameTime.split(':');

  // checks if are valid numbers
  if (!Number.isFinite(+hours) || !Number.isFinite(+minutes) || !Number.isFinite(+seconds)) return 0;

  const hoursValue = +hours * 60 * 60;
  const minsValue = +minutes * 60;
  const totalSeconds = hoursValue + +minsValue + +seconds;

  return totalSeconds;
};

export const sortGameEventsByGameTime = (eventData: NestedObject[]) =>
  [...eventData].sort((a, b) => String(a['event.gameTime']).localeCompare(String(b['event.gameTime'])));

export const formatGameTimestamp = (minute: number, second: number) =>
  // Ensure mins/secs are two characters long - if not prefix with a zero (e.g 2 mins becomes 02)
  `${String(minute).padStart(2, '0')}:${String(second).padStart(2, '0')}`;
