import { useGetPitchRotation } from '@/hooks/useGetPitchRotation';
import { usePitchCrop } from '@/hooks/usePitchCrop';
import { useStatus } from '@/hooks/useStatus';
import { StatusTranslationKey } from '@/types/status';
import { FallBackProps } from '@/types/visualisation';
import { PitchViz } from '@statsbomb/kitbag-datavis';
import { CardBody } from '@statsbomb/kitbag-components';
import { CardHeightWrapper } from '../card/CardHeightWrapper';

export const FallbackNoData = ({ isPreview = false }: FallBackProps) => {
  const pitchRotation = useGetPitchRotation(isPreview);
  const visibleArea = usePitchCrop(isPreview);
  const statusProps = useStatus('general', StatusTranslationKey.NO_DATA);

  return (
    <CardHeightWrapper>
      <CardBody {...statusProps}>
        <PitchViz className="h-full w-full" rotationName={pitchRotation} pitchFocusZoneName={visibleArea} />
      </CardBody>
    </CardHeightWrapper>
  );
};
