import { EventMap } from '@/components/visualisations/EventMap';
import { PitchVisComponentProps } from '@/types/visualisation';
import { playerPitchEventsAtom, playerUnwrappedPitchEventsAtom } from '@/atoms/player/vis';
import { MetricKeyFallbackWrapper } from '@/components/metric/MetricKeyFallbackWrapper';

export const PlayerEventMap = ({ allowInteraction, isPreview }: PitchVisComponentProps) => (
  <MetricKeyFallbackWrapper>
    <EventMap
      allowInteraction={allowInteraction}
      pitchEventsAtom={playerPitchEventsAtom}
      unwrappedPitchEventsAtom={playerUnwrappedPitchEventsAtom}
      isPreview={isPreview}
    />
  </MetricKeyFallbackWrapper>
);
