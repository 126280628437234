import { useHydrateAtoms } from 'jotai/utils';
import { metricKeyAtom } from '@/atoms/metric';
import { HeatMapBase } from '@/components/visualisations/HeatMap';
import { ErrorCard } from '@/components/contentState/ErrorCard';
import { PlayerHeatMap } from './PlayerHeatMap';

const PlayerDefensiveActivityWithData = () => {
  useHydrateAtoms([[metricKeyAtom, 'defensive_actions']]);

  return <PlayerHeatMap />;
};

export const PlayerDefensiveActivity = () => (
  <ErrorCard translationNameSpace="general" fallbackChildren={<HeatMapBase eventsForMetric={[]} />}>
    <PlayerDefensiveActivityWithData />
  </ErrorCard>
);
