import { ConfirmationDialog, Table, Typography } from '@statsbomb/kitbag-components';
import dayjs from 'dayjs';
import { useAtomValue, useSetAtom } from 'jotai';
import { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  isLoadFilterSetModalOpenAtom,
  isSaveFilterModalOpenAtom,
  filterSetNameAtom,
  filterSetIdToDeleteAtom,
} from '@/atoms/filters/userFilters';
import { filterSetsAtom } from '@/atoms/queries/userConfigs';
import { GenericToast } from '@/components/alerts/GenericToast/GenericToast';
import { TableCell } from '@/components/tables/TableCell';
import { TableWrapper } from '@/components/tables/TableWrapper';
import { DATE_FORMAT } from '@/consts/date';
import { useSortObjectArray } from '@/hooks/useSortObjectArray';
import { useSortTable } from '@/hooks/useSortTable';
import { getFilterCount } from '@/utils/filters';
import { useNavigateToFilterSetId } from '@/hooks/useNavigateToFilterSetId';
import { UserConfigMenu } from '@/components/menu/UserConfigMenu/UserConfigMenu';
import { userConfigIdToRenameAtom } from '@/atoms/userConfigs';
import { TableScopeProvider } from '@/components/tables/TableScopeProvider';
import { twJoin } from 'tailwind-merge';
import { ScopeProvider } from 'jotai-scope';
import { SaveFilterModal } from '../SaveFilterModal/SaveFilterModal';

const LoadFilterSetModalWithData = () => {
  const { t } = useTranslation(['filters', 'general']);
  const filterSets = useAtomValue(filterSetsAtom);
  const [selectedFilterIdToLoad, setSelectedFilterIdToLoad] = useState<string | null>(null);
  const { orderBy, orderDirection, handleSort, getSortedState } = useSortTable();
  const userConfigIdToRename = useAtomValue(userConfigIdToRenameAtom);
  const setFilterSetName = useSetAtom(filterSetNameAtom);
  const setIsSaveModalOpen = useSetAtom(isSaveFilterModalOpenAtom);
  const navigateToFilterSetId = useNavigateToFilterSetId();
  const setIsLoadModalOpen = useSetAtom(isLoadFilterSetModalOpenAtom);
  const setFilterSetIdToDelete = useSetAtom(filterSetIdToDeleteAtom);

  useEffect(() => handleSort('renderedName', true), []);
  const sortedFilterSets = useSortObjectArray(
    orderBy,
    filterSets.map(filterSet => ({
      ...filterSet,
      renderedName: filterSet.definition.name || '',
    })),
    orderDirection,
  );

  const dialogId = 'load-filter-set-modal';
  return (
    <ConfirmationDialog
      id={dialogId}
      testId={dialogId}
      title={t('filterSet.other')}
      isOpen
      onConfirm={() => {
        /* this handles the logic for if selectedFilterIdToLoad is null
          so that we don't need to appease TS with the ! operator
          but we can't actually test this part of the onConfirm since
          the button is disabled if it's null */
        /* istanbul ignore if */
        if (!selectedFilterIdToLoad) return;
        navigateToFilterSetId(selectedFilterIdToLoad);
        setIsLoadModalOpen(false);
      }}
      onCancel={() => setIsLoadModalOpen(false)}
      cancelLabel={t('cancel', { ns: 'general' })}
      confirmLabel={t('load', { ns: 'general' })}
      isDisabledConfirm={selectedFilterIdToLoad === null || filterSets.length === 0}
    >
      {/* doing this as the table wrapper has a z-1 and that causes the menu to be hidden
      so this removes that for this table */}
      <TableWrapper classes="z-0">
        <div className="overflow-x-auto">
          <Table withBorder={false}>
            <Table.Head>
              <Table.Row>
                <Table.HeaderCell
                  sortCb={() => handleSort('renderedName', true)}
                  sortState={getSortedState('renderedName')}
                  size="regular"
                >
                  {/* This is only to keep the text inline with the row values below due to the border they have */}
                  <span className="border-transparent border-l-4">
                    {t('filterSetsWithCount', { count: filterSets.length })}
                  </span>
                </Table.HeaderCell>
                <Table.HeaderCell
                  sortCb={() => handleSort('updatedAt', false)}
                  sortState={getSortedState('updatedAt')}
                  size="regular"
                >
                  {t('lastModified', { ns: 'general' })}
                </Table.HeaderCell>
                <Table.HeaderCell size="regular" />
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {sortedFilterSets.map(filterSet => {
                const id = filterSet.configId;
                const isSelected = selectedFilterIdToLoad === id;

                return (
                  <Table.Row
                    key={id}
                    onClick={() => setSelectedFilterIdToLoad(id)}
                    className="cursor-pointer"
                    data-testid="filter-set-row"
                  >
                    <TableCell
                      className={twJoin('border-l-4', isSelected ? 'border-l-interface-main' : 'border-l-transparent')}
                    >
                      <div className="flex items-center gap-x-2 py-2">
                        <div className="flex items-center gap-x-1">
                          <Typography variant="bodyRegular" as="span">
                            {filterSet.renderedName}
                          </Typography>
                        </div>
                        <Typography variant="bodySmall" as="span">
                          {t('filtersCount', {
                            count: getFilterCount(filterSet.definition.filters),
                          })}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <Typography variant="bodyRegular" as="span">
                        {dayjs(filterSet.updatedAt).format(DATE_FORMAT)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <UserConfigMenu
                        configId={id}
                        onRenameClick={(filterName: string) => {
                          setFilterSetName(filterName);
                          setIsSaveModalOpen(true);
                        }}
                        onDeleteClick={(id: string, name: string) => {
                          setFilterSetIdToDelete(id);
                          setFilterSetName(name);
                        }}
                      />
                    </TableCell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      </TableWrapper>
      {/* Have added this here as without this the Toast shows underneath the modal */}
      <GenericToast />
      <SaveFilterModal filterSetIdToRename={userConfigIdToRename} />
    </ConfirmationDialog>
  );
};

export const LoadFilterSetModal = () => {
  const isLoadModalOpen = useAtomValue(isLoadFilterSetModalOpenAtom);

  return (
    <ScopeProvider atoms={[isSaveFilterModalOpenAtom]}>
      <TableScopeProvider>
        {isLoadModalOpen && (
          <Suspense>
            <LoadFilterSetModalWithData />
          </Suspense>
        )}
      </TableScopeProvider>
    </ScopeProvider>
  );
};
