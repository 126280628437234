import { ALL_ITEMS } from '@/consts/interface';
import { Nullable, Section } from '@/types/generic';
import { toObject } from '@/utils/object';
import { camelToSnakeCase } from '@/utils/queries';
import { SortOrder } from '@statsbomb/parachute-types';
import { atom } from 'jotai';
import { PREFETCH_ROW_COUNT } from '@/consts/pagination';
import { itemsPerPageAtom, pageAtom } from './pagination';
import { clientOrderByAtom, clientOrderDirectionAtom, serverOrderByAtom, serverOrderDirectionAtom } from './table';

export const pageAndOrderParamsAtom = atom(get => {
  const itemsPerPage = get(itemsPerPageAtom);
  const page = get(pageAtom);
  const orderBy = get(serverOrderByAtom);
  const orderDirection = get(serverOrderDirectionAtom).toUpperCase();

  return {
    limit: itemsPerPage,
    offset: itemsPerPage === ALL_ITEMS ? 0 : (page - 1) * itemsPerPage,
    order_by: toObject(orderBy && camelToSnakeCase(orderBy), `${orderDirection} NULLS LAST`) as Record<
      string,
      SortOrder
    >,
  };
});

export const clientOrderParamsAtom = atom(get => {
  const orderBy = get(clientOrderByAtom);
  const orderDirection = get(clientOrderDirectionAtom).toUpperCase();

  return {
    order_by: toObject(orderBy && camelToSnakeCase(orderBy), `${orderDirection} NULLS LAST`) as Record<
      string,
      SortOrder
    >,
  };
});

export const adjustedOffsetAtom = atom(get => {
  const { offset } = get(pageAndOrderParamsAtom);
  return Math.floor(offset / PREFETCH_ROW_COUNT) * PREFETCH_ROW_COUNT;
});

export const sectionAtom = atom<Nullable<Section>>(null);
