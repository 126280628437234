import { ShotMapEventMarker } from '@/types/visualisation';
import { EventMarkerLayer, PitchViz } from '@statsbomb/kitbag-datavis';
import { usePitchCrop } from '@/hooks/usePitchCrop';
import { useGetPitchRotation } from '@/hooks/useGetPitchRotation';
import { useShotMarkerFill } from '@/hooks/shotMap/useShotMarkerFill';
import { useShotMarkerStroke } from '@/hooks/shotMap/useShotMarkerStroke';
import { getShotMarkerShape } from '@/utils/visualisations';
import { SHOT_MAP_GOAL_DASHARRAY, SHOT_MAP_MARKER_STROKE_WIDTH } from '@/consts/visualisations';

export const ShotMap = ({ events }: { events: ShotMapEventMarker[] }) => {
  const pitchRotation = useGetPitchRotation(false);
  const visibleArea = usePitchCrop(false);
  const getMarkerFill = useShotMarkerFill();
  const getMarkerStroke = useShotMarkerStroke();
  const rotation = pitchRotation === 'VERTICAL' || pitchRotation === 'VERTICAL_REVERSE' ? 90 : undefined;

  const ShotMapMarkersLayer = (
    <EventMarkerLayer
      markers={events.map(marker => ({
        id: marker.eventId,
        x: marker.startX,
        y: marker.startY,
        radius: 0.9,
        rotation,
        fill: getMarkerFill(marker),
        stroke: getMarkerStroke(marker),
        strokeWidth:
          marker.outcome === 'goal' ? SHOT_MAP_MARKER_STROKE_WIDTH.GOAL : SHOT_MAP_MARKER_STROKE_WIDTH.DEFAULT,
        strokeDasharray: marker.outcome === 'goal' ? SHOT_MAP_GOAL_DASHARRAY : undefined,
        shapeName: getShotMarkerShape(marker),
      }))}
    />
  );

  return (
    <figure className="h-full">
      <PitchViz
        rotationName={pitchRotation}
        pitchFocusZoneName={visibleArea}
        overlay={ShotMapMarkersLayer}
        className="h-full w-full"
      />
    </figure>
  );
};
