import { useTranslation } from 'react-i18next';
import { getTranslationKeys } from '@statsbomb/video-client-js';

// https://video-client-js.staging.statsbomb.com/?path=/docs/providers-videoprovider-translationsupport--docs#building-the-translation-overrides-in-a-consuming-application
export const useVideoPlayerTranslations = () => {
  const { i18n } = useTranslation();
  const translationValues = { videoClient: i18n.getResourceBundle(i18n.language, 'videoClient') };

  return getTranslationKeys().reduce((result, key) => {
    if (key in translationValues.videoClient) {
      const { [key]: value } = translationValues.videoClient;
      return { ...result, [key]: value };
    }
    return result;
  }, {});
};
