import { adjustedOffsetAtom, clientOrderParamsAtom, pageAndOrderParamsAtom } from '@/atoms/general';
import { teamsNormalisedAggregatesUrl } from '@/query/url';
import { objSnakeToCamel } from '@/utils/queries';
import { MultiTeamAggregate } from '@statsbomb/parachute-types';
import { atomWithQuery, atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { addLink, flattenAndAddPrefix, optionsToValueStrings, toObject } from '@/utils/object';
import { competitionIdsToFilterByAtom } from '@/atoms/filters/highLevel/competitions';
import { seasonIdsToFilterByAtom } from '@/atoms/filters/highLevel/seasons';
import { atom } from 'jotai';
import { atomWithDebounce } from '@/atoms/debounce';
import { Nullable } from '@/types/generic';
import { NestedObject } from '@/types/object';
import { selectedEndDateAtom, selectedStartDateAtom } from '@/atoms/filters/highLevel';
import { selectedTeamIdsAtom } from '@/atoms/filters/highLevel/teams';
import { convertFilterParamsToString } from '@/utils/api';
import { teamIdAtom } from '@/atoms/team/team';
import { unwrappedTeamSelectedGamesForQueryAtom } from '@/atoms/filters/team/teamGames';
import { PREFETCH_ROW_COUNT } from '@/consts/pagination';
import { limitArray, sortFetchResultObjectArray } from '@/utils/array';
import { unwrap } from 'jotai/utils';
import { fetchClientAtom } from '../client';

export const teamAggsFilterParamsAtom = atom(get => ({
  lte: {
    ...toObject('input.game_date', get(selectedEndDateAtom)),
  },
  gte: {
    ...toObject('input.game_date', get(selectedStartDateAtom)),
  },
  in: {
    ...toObject('input.competition_id', get(competitionIdsToFilterByAtom)),
    ...toObject('input.season_id', get(seasonIdsToFilterByAtom)),
    ...toObject('output.team.team_id', get(selectedTeamIdsAtom)),
  },
}));

export const teamSquadFilterParamsAtom = atom(get => ({
  in: {
    ...toObject('input.team_id', get(teamIdAtom)),
    'input.game_id': optionsToValueStrings(get(unwrappedTeamSelectedGamesForQueryAtom)),
  },
}));

export const teamGameStatsFilterParamsAtom = atom(get => ({
  in: {
    'input.game_id': optionsToValueStrings(get(unwrappedTeamSelectedGamesForQueryAtom)),
  },
}));

export const teamAggsFilterParamsDebounceObject = atomWithDebounce<Nullable<NestedObject>>(null);
const { debouncedValueAtom: debouncedTeamAggsFilterParamsAtom } = teamAggsFilterParamsDebounceObject;

const rawTeamAggsDataAtom = atomWithQuery(get => {
  const adjustedOffset = get(adjustedOffsetAtom);
  const { order_by: orderBy } = get(pageAndOrderParamsAtom);
  const queryKey = ['teamAggs', adjustedOffset, orderBy, get(debouncedTeamAggsFilterParamsAtom)] as const;

  const queryFn = async ({
    queryKey: [, adjustedOffset, orderBy, teamAggsFilterParams],
  }: {
    queryKey: typeof queryKey;
  }) => {
    const { fetch } = get(fetchClientAtom);
    const data: MultiTeamAggregate[] = await fetch(
      teamsNormalisedAggregatesUrl({
        limit: PREFETCH_ROW_COUNT,
        offset: adjustedOffset,
        order_by: orderBy,
        ...teamAggsFilterParams,
      }),
    );
    return data.map(data => flattenAndAddPrefix(objSnakeToCamel(data), '.', false));
  };
  const enabled = get(debouncedTeamAggsFilterParamsAtom) !== null;
  return { queryKey, queryFn, enabled };
});

export const teamAggsDataAtom = atom(get => {
  const { offset, limit } = get(pageAndOrderParamsAtom);
  const { data, isPending } = get(rawTeamAggsDataAtom);

  if (!data) return { data, isPending };

  const sortedPlayerAggsToDisplay = sortFetchResultObjectArray(data, get(clientOrderParamsAtom));
  const teamAggsData = limitArray(sortedPlayerAggsToDisplay, { offset: offset % PREFETCH_ROW_COUNT, limit });

  return {
    data: teamAggsData.map(data =>
      addLink(data, data['team.name'] as string, data['team.teamId'] as number, 'teamLink'),
    ),
    isPending,
  };
});

const rawTeamAggsCountAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['teamAggsCount', get(debouncedTeamAggsFilterParamsAtom)] as const;
  const queryFn = async ({ queryKey: [, teamAggsFilterParams] }: { queryKey: typeof queryKey }) => {
    if (!teamAggsFilterParams) return null;
    const { fetch } = get(fetchClientAtom);
    return (await fetch(
      `/teams/normalised-aggregates/count${convertFilterParamsToString({
        ...teamAggsFilterParams,
      })}`,
    )) as Promise<number>;
  };
  return { queryKey, queryFn };
});

export const teamAggsCountAtom = atom(async get => (await get(rawTeamAggsCountAtom)).data);

const unwrappedTeamAggsCountAtom = unwrap(teamAggsCountAtom);

export const hasRetrievedAllTeamAggsAtom = atom(get => {
  const teamAggsCount = get(unwrappedTeamAggsCountAtom);
  const teamAggsDataCount = get(rawTeamAggsDataAtom).data?.length;
  return (teamAggsCount && teamAggsDataCount && teamAggsCount === teamAggsDataCount) || false;
});
