import { TEAM_GAMES_DEFAULT_COLUMNS } from '@/components/tables/GameAggregatesTable.constants';
import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';
import { getReadWriteVisibleColumnsAtom } from '../../utils';

export const teamGameDataVisibleColumnsUserSelectedAtom = atom<string[] | undefined>(undefined);

export const teamGameDataDefaultColumnsAtom = atom(async () => [...TEAM_GAMES_DEFAULT_COLUMNS]);

export const teamGameDataVisibleColumnsAtom = getReadWriteVisibleColumnsAtom(
  teamGameDataVisibleColumnsUserSelectedAtom,
  teamGameDataDefaultColumnsAtom,
);

export const unwrappedTeamGameDataVisibleColumnsAtom = unwrap(teamGameDataVisibleColumnsAtom, prev => prev || []);
