import { unwrappedPlayerAggsMinutesPlayedAtom } from '@/atoms/queries/player/playerAggData';
import { Suspense } from 'react';
import {
  filteredPlayerGamesAtom,
  playerGamesBySeasonAtom,
  playerSelectedGamesAtom,
} from '@/atoms/filters/player/playerGames';
import { RadarFilterMinsSummary } from '@/components/radar/filterSummaries/RadarFilterMinsSummary';
import { RadarFilterCompetitionSummary } from '@/components/radar/filterSummaries/RadarFilterCompetitionSummary';
import { RadarFilterSeasonSummary } from '@/components/radar/filterSummaries/RadarFilterSeasonSummary';
import { RadarFilterTeamSummary } from '@/components/radar/filterSummaries/RadarFilterTeamSummary';
import { RadarFilterGameSummary } from '@/components/radar/filterSummaries/RadarFilterGameSummary';
import { RadarFilterPositionSummary } from '@/components/radar/filterSummaries/RadarFilterPositionSummary';
import { playerCyclesOrderedAtom } from '@/atoms/queries/players';

export const PlayerFiltersSummary = () => (
  <Suspense>
    <div data-testid="player-radar-filter-summary" className="grid gap-2 grid-cols-1 @md:grid-cols-[1fr_2fr_1fr]">
      <RadarFilterMinsSummary minutesAtom={unwrappedPlayerAggsMinutesPlayedAtom} />
      <RadarFilterCompetitionSummary
        filteredGamesDataAtom={filteredPlayerGamesAtom}
        selectedGamesAtom={playerSelectedGamesAtom}
        orderedCyclesAtom={playerCyclesOrderedAtom}
      />
      <RadarFilterSeasonSummary
        filteredGamesDataAtom={filteredPlayerGamesAtom}
        selectedGamesAtom={playerSelectedGamesAtom}
      />
    </div>
    <div className="grid gap-2 grid-cols-1 @md:grid-cols-3">
      <RadarFilterTeamSummary
        filteredGamesDataAtom={filteredPlayerGamesAtom}
        selectedGamesAtom={playerSelectedGamesAtom}
      />
      <RadarFilterGameSummary
        gamesGroupedBySeasonAtom={playerGamesBySeasonAtom}
        selectedGamesAtom={playerSelectedGamesAtom}
      />
      <RadarFilterPositionSummary />
    </div>
  </Suspense>
);
