import { Suspense } from 'react';
import { atom, useAtom, useSetAtom } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Button, CardBody, Checkbox, ConfirmationDialog, Table, Typography } from '@statsbomb/kitbag-components';
import { saveWidgetToReportDataAtom, isSaveWidgetToReportModalOpenAtom } from '@/atoms/report/report';
import { reportConfigsAtom } from '@/atoms/queries/userConfigs';
import { closeToastAtom, toastDataAtom } from '@/atoms/toast';
import { CreateNewReportButton } from '@/components/report/CreateNewReportButton/CreateNewReportButton';
import { TableCell } from '@/components/tables/TableCell';
import { TableWrapper } from '@/components/tables/TableWrapper';
import { useStatus } from '@/hooks/useStatus';
import { useSaveItemToReport } from '@/hooks/useSaveItemToReport';
import { toggleValue } from '@/utils/array';
import { noop } from '@/utils/noop';
import { TableScopeProvider } from '@/components/tables/TableScopeProvider';
import { useSortTable } from '@/hooks/useSortTable';
import { useSortObjectArray } from '@/hooks/useSortObjectArray';
import { useReportsWithFallback } from '@/hooks/useReportsWithFallback';
import { orderByAtom, orderDirectionAtom } from '@/atoms/table';
import { SORT_DIRECTION } from '@/types/ordering';
import { REPORTS_TABLE_HEADERS } from '@/consts/report';
import { DATE_FORMAT } from '@/consts/date';
import { useLoadableStatus } from '@/hooks/useLoadableStatus';
import { defaultWidgetReportData } from '@/consts/defaults/report';
import { ErrorCardBody } from '@/components/contentState/ErrorCard';
import { ErrorBoundary } from '@sentry/react';
import { UserConfigReportDefinition } from '@/types/userConfigs';

const selectedReportIdsAtom = atom<string[]>([]);

const ReportTableBase = ({ reports }: { reports: UserConfigReportDefinition[] }) => {
  // initialize the table order by and direction
  useHydrateAtoms([
    [orderByAtom, REPORTS_TABLE_HEADERS[0].sortKey],
    [orderDirectionAtom, SORT_DIRECTION.ASC],
  ]);

  const { t } = useTranslation('report');
  const [selectedReportIds, setSelectedReportIds] = useAtom(selectedReportIdsAtom);

  const { orderBy, orderDirection, handleSort, getSortedState } = useSortTable();
  const orderedReports = useSortObjectArray(orderBy, reports, orderDirection);

  return (
    <TableWrapper classes="min-h-[4.25rem] !border-0 !m-0">
      {/* TODO (KC-350: Table variant with non alternating row colours) */}
      <Table>
        <Table.Head>
          <Table.Row>
            {REPORTS_TABLE_HEADERS.map(header => (
              <Table.HeaderCell
                key={header.sortKey}
                size="regular"
                sortState={getSortedState(header.sortKey)}
                sortCb={() => handleSort(header.sortKey)}
              >
                {t(header.translation, { ns: header.ns, count: reports.length })}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {orderedReports?.map(report => {
            const id = report.configId;
            const isChecked = selectedReportIds.includes(id);
            const reportName = report.definition.name;

            return (
              <Table.Row
                key={id}
                data-testid={`report-row-${id}`}
                onClick={() => setSelectedReportIds(toggleValue(id, selectedReportIds))}
                className="cursor-pointer"
              >
                <TableCell>
                  <div className="flex items-center gap-x-2">
                    <Checkbox
                      id={`report-row-${id}-checkbox`}
                      size="small"
                      checked={isChecked}
                      onChange={noop}
                      label={`${reportName}-checkbox`}
                      withLabel={false}
                    />
                    <Typography variant="bodyRegular" as="span">
                      {reportName}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell>
                  <Typography as="span" variant="bodyRegular">
                    {dayjs(report.updatedAt).format(DATE_FORMAT)}
                  </Typography>
                </TableCell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </TableWrapper>
  );
};

const ReportTableWithData = () => {
  const tableStatus = useLoadableStatus(reportConfigsAtom);
  const statusProps = useStatus('reports', tableStatus);
  const reports = useReportsWithFallback();

  return (
    <CardBody {...statusProps}>
      <TableScopeProvider>
        <ReportTableBase reports={reports} />
      </TableScopeProvider>
    </CardBody>
  );
};

const ReportTableWithFallback = () => (
  <ErrorCardBody translationNameSpace="reports" fallbackChildren={<ReportTableBase reports={[]} />}>
    <ReportTableWithData />
  </ErrorCardBody>
);

const SaveWidgetToReportModalBase = ({
  onConfirm,
  onCancel,
  isDisabledConfirm,
}: {
  onConfirm: () => void;
  onCancel: () => void;
  isDisabledConfirm?: boolean;
}) => {
  const { t } = useTranslation(['report', 'general']);

  return (
    <ConfirmationDialog
      id="add-widget-to-report-modal"
      title={t('selectReports')}
      isOpen
      confirmLabel={t('add', { ns: 'general' })}
      onConfirm={onConfirm}
      onCancel={onCancel}
      isDisabledConfirm={isDisabledConfirm}
    >
      <div className="w-[600px]">
        <div className="flex justify-end">
          <CreateNewReportButton />
        </div>
        <ReportTableWithFallback />
      </div>
    </ConfirmationDialog>
  );
};

const SaveWidgetToReportModalWithData = () => {
  const [selectedReportIds, setSelectedReportIds] = useAtom(selectedReportIdsAtom);
  const setIsSaveWidgetToReportModalOpen = useSetAtom(isSaveWidgetToReportModalOpenAtom);
  const [{ visType, dataType }, setSaveWidgetToReportData] = useAtom(saveWidgetToReportDataAtom);

  const navigate = useNavigate();
  const setToastData = useSetAtom(toastDataAtom);
  const closeToast = useSetAtom(closeToastAtom);

  const {
    t,
    i18n: { language },
  } = useTranslation(['report', 'general']);

  const translatedVisType = t(visType, { ns: 'visualisation' });

  const saveItemToReport = useSaveItemToReport();

  const closeAndResetModal = () => {
    setIsSaveWidgetToReportModalOpen(false);
    setSelectedReportIds([]);
  };

  const onConfirm = async () => {
    const isSuccess = await saveItemToReport(visType, dataType, selectedReportIds);
    const reportIdsLength = selectedReportIds.length;
    const hasOneReportId = reportIdsLength === 1;

    const destination = hasOneReportId ? `/${language}/report/${selectedReportIds[0]}` : `/${language}/reports`;

    const text = t(hasOneReportId ? 'viewReport' : 'viewAllReports', { ns: 'widget' });

    const toastContent =
      /* we only want to show the button if the widget was saved successfully */
      isSuccess ? (
        <Button
          size="small"
          variant="secondary"
          onClick={() => {
            closeToast();
            navigate(destination);
          }}
        >
          {text}
        </Button>
      ) : null;

    const toastName = hasOneReportId ? 'addToReport' : 'addToReports';
    setToastData(toastName, isSuccess, translatedVisType, toastContent, { count: reportIdsLength.toString() });
    setSaveWidgetToReportData(defaultWidgetReportData);

    closeAndResetModal();
  };

  return (
    <SaveWidgetToReportModalBase
      onConfirm={onConfirm}
      onCancel={closeAndResetModal}
      isDisabledConfirm={selectedReportIds.length === 0}
    />
  );
};

export const SaveWidgetToReportModal = () => {
  const [isSaveWidgetToReportModalOpen, setIsSaveWidgetToReportModalOpen] = useAtom(isSaveWidgetToReportModalOpenAtom);

  return (
    isSaveWidgetToReportModalOpen && (
      <Suspense>
        <ErrorBoundary
          fallback={
            <SaveWidgetToReportModalBase
              onConfirm={noop}
              onCancel={() => setIsSaveWidgetToReportModalOpen(false)}
              isDisabledConfirm
            />
          }
        >
          <SaveWidgetToReportModalWithData />
        </ErrorBoundary>
      </Suspense>
    )
  );
};
