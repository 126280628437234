import { atomWithDebounce } from '@/atoms/debounce';
import {
  maxAgeBirthDateToQueryAtom,
  maxAgeSelectedAtom,
  minAgeBirthDateToQueryAtom,
  minAgeSelectedAtom,
} from '@/atoms/filters/dataLocker/ageRange';
import { maxHeightSelectedAtom, minHeightSelectedAtom } from '@/atoms/filters/dataLocker/heightRange';
import { maxMinutesSelectedAtom, minMinutesSelectedAtom } from '@/atoms/filters/dataLocker/minMinutes';
import { selectedPreferredFootAtom } from '@/atoms/filters/dataLocker/preferredFoot';
import { selectedEndDateAtom, selectedStartDateAtom } from '@/atoms/filters/highLevel';
import { competitionIdsToFilterByAtom } from '@/atoms/filters/highLevel/competitions';
import { selectedPlayerIdsAtom } from '@/atoms/filters/highLevel/players';
import { selectedPositionsFilterParamsAtom } from '@/atoms/filters/highLevel/positions';
import { seasonIdsToFilterByAtom } from '@/atoms/filters/highLevel/seasons';
import { selectedTeamIdsAtom } from '@/atoms/filters/highLevel/teams';
import { unwrappedPlayerSelectedGamesForQueryAtom } from '@/atoms/filters/player/playerGames';
import { adjustedOffsetAtom, clientOrderParamsAtom, pageAndOrderParamsAtom } from '@/atoms/general';
import { fetchClientAtom } from '@/atoms/queries/client';
import { MAX_AGE, MIN_AGE } from '@/consts/filters/ageRange';
import { MAX_HEIGHT, MIN_HEIGHT } from '@/consts/filters/heightRange';
import { MAX_MINUTES, MIN_MINUTES } from '@/consts/filters/minMinutes';
import { PREFETCH_ROW_COUNT } from '@/consts/pagination';
import { playersNormalisedAggregatesUrl } from '@/query/url';
import { Nullable } from '@/types/generic';
import { NestedObject } from '@/types/object';
import { convertFilterParamsToString, hasEmptyInFilters } from '@/utils/api';
import { limitArray, sortFetchResultObjectArray } from '@/utils/array';
import { addLink, flattenAndAddPrefix, optionsToValueStrings, toObject } from '@/utils/object';
import { formatPlayerName } from '@/utils/player';
import { objSnakeToCamel } from '@/utils/queries';
import { MultiPlayerAggregate } from '@statsbomb/parachute-types';
import { atom } from 'jotai';
import { atomWithQuery, atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { unwrap } from 'jotai/utils';
import { rawPlayerRadarDataAtom } from './playerRadar';

const playerAggsAgeFilterParamsAtom = atom(get => {
  const minAgeSelected = get(minAgeSelectedAtom);
  const maxAgeSelected = get(maxAgeSelectedAtom);

  const minAgeDate =
    minAgeSelected !== MIN_AGE ? toObject('output.player.date_of_birth', get(minAgeBirthDateToQueryAtom)) : {};
  const maxAgeDate =
    maxAgeSelected !== MAX_AGE ? toObject('output.player.date_of_birth', get(maxAgeBirthDateToQueryAtom)) : {};

  return { minAgeDate, maxAgeDate };
});

const playerAggsHeightFilterParamsAtom = atom(get => {
  const minHeightSelected = get(minHeightSelectedAtom);
  const maxHeightSelected = get(maxHeightSelectedAtom);

  const minHeight = minHeightSelected !== MIN_HEIGHT ? toObject('output.player.height', minHeightSelected) : {};
  const maxHeight = maxHeightSelected !== MAX_HEIGHT ? toObject('output.player.height', maxHeightSelected) : {};

  return { minHeight, maxHeight };
});

const playerAggsMinutesFilterParamsAtom = atom(get => {
  const minMinutesSelected = get(minMinutesSelectedAtom);
  const maxMinutesSelected = get(maxMinutesSelectedAtom);

  const minMinutes =
    minMinutesSelected !== MIN_MINUTES ? toObject('output.aggregates.minutes_played', minMinutesSelected) : {};
  const maxMinutes =
    maxMinutesSelected !== MAX_MINUTES ? toObject('output.aggregates.minutes_played', maxMinutesSelected) : {};

  return { minMinutes, maxMinutes };
});

export const playerAggsFilterParamsAtom = atom(get => ({
  lte: {
    ...toObject('input.game_date', get(selectedEndDateAtom)),
    ...get(playerAggsAgeFilterParamsAtom).minAgeDate,
    ...get(playerAggsHeightFilterParamsAtom).maxHeight,
    ...get(playerAggsMinutesFilterParamsAtom).maxMinutes,
  },
  gte: {
    ...toObject('input.game_date', get(selectedStartDateAtom)),
    ...get(playerAggsAgeFilterParamsAtom).maxAgeDate,
    ...get(playerAggsHeightFilterParamsAtom).minHeight,
    ...get(playerAggsMinutesFilterParamsAtom).minMinutes,
  },
  in: {
    ...toObject('input.competition_id', get(competitionIdsToFilterByAtom)),
    ...toObject('output.player.player_id', get(selectedPlayerIdsAtom)),
    ...toObject('input.team_id', get(selectedTeamIdsAtom)),
    ...toObject('input.season_id', get(seasonIdsToFilterByAtom)),
    ...toObject('output.player.preferred_foot', get(selectedPreferredFootAtom)),
    ...toObject('input.position', get(selectedPositionsFilterParamsAtom)),
  },
}));

export const playerGameStatsFilterParamsAtom = atom(get => ({
  in: {
    'input.game_id': optionsToValueStrings(get(unwrappedPlayerSelectedGamesForQueryAtom)),
  },
}));

export const playerAggsFilterParamsDebounceObject = atomWithDebounce<Nullable<NestedObject>>(null);
export const { debouncedValueAtom: debouncedPlayerAggsFilterParamsAtom } = playerAggsFilterParamsDebounceObject;

const rawPlayerAggsDataAtom = atomWithQuery(get => {
  const adjustedOffset = get(adjustedOffsetAtom);
  const { order_by: orderBy } = get(pageAndOrderParamsAtom);
  const queryKey = ['playerAggs', adjustedOffset, orderBy, get(debouncedPlayerAggsFilterParamsAtom)] as const;
  const queryFn = async ({
    queryKey: [, adjustedOffset, orderBy, playerAggsFilterParams],
  }: {
    queryKey: typeof queryKey;
  }) => {
    if (hasEmptyInFilters(playerAggsFilterParams)) return [];
    const { fetch } = get(fetchClientAtom);
    const data: MultiPlayerAggregate[] = await fetch(
      playersNormalisedAggregatesUrl({
        limit: PREFETCH_ROW_COUNT,
        offset: adjustedOffset,
        order_by: orderBy,
        ...playerAggsFilterParams,
      }),
    );
    return data.map(data => flattenAndAddPrefix(objSnakeToCamel(data), '.', false));
  };
  const enabled = get(debouncedPlayerAggsFilterParamsAtom) !== null;
  return { queryKey, queryFn, enabled };
});

export const playerAggsDataAtom = atom(get => {
  const { offset, limit } = get(pageAndOrderParamsAtom);
  const { data, isPending } = get(rawPlayerAggsDataAtom);

  if (!data) return { data, isPending };

  const sortedPlayerAggsToDisplay = sortFetchResultObjectArray(data, get(clientOrderParamsAtom));
  const playerAggsData = limitArray(sortedPlayerAggsToDisplay, { offset: offset % PREFETCH_ROW_COUNT, limit });

  return {
    data: playerAggsData.map(data =>
      addLink(
        data,
        formatPlayerName(data['player.name'] as string, data['player.nickname'] as Nullable<string>),
        data['player.playerId'] as number,
        'playerLink',
      ),
    ),
    isPending,
  };
});

const rawPlayerAggsCountAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['playerAggsCount', get(debouncedPlayerAggsFilterParamsAtom)] as const;
  const queryFn = async ({ queryKey: [, playerAggsFilterParams] }: { queryKey: typeof queryKey }) => {
    if (!playerAggsFilterParams) return null;
    const { fetch } = get(fetchClientAtom);
    return (await fetch(
      `/players/normalised-aggregates/count${convertFilterParamsToString({
        ...playerAggsFilterParams,
      })}`,
    )) as Promise<number>;
  };
  return { queryKey, queryFn };
});
export const playerAggsCountAtom = atom(async get => (await get(rawPlayerAggsCountAtom)).data);

const unwrappedPlayerAggsCountAtom = unwrap(playerAggsCountAtom);

export const hasRetrievedAllPlayerAggsAtom = atom(get => {
  const playerAggsCount = get(unwrappedPlayerAggsCountAtom);
  const playerAggsDataCount = get(rawPlayerAggsDataAtom).data?.length;
  return (playerAggsCount && playerAggsDataCount && playerAggsCount === playerAggsDataCount) || false;
});

export const playerAggsMinutesPlayedAtom = atom(async get => {
  const rawPlayerRadarData = (await get(rawPlayerRadarDataAtom)).data;
  return rawPlayerRadarData.minutes_played || 0;
});

export const unwrappedPlayerAggsMinutesPlayedAtom = unwrap(playerAggsMinutesPlayedAtom, prev => prev || 0);
