import { Nullable } from '@/types/generic';
import { atom } from 'jotai';
import { unwrappedReportWidgetsToRenderAtom } from './reportSaving';

export const widgetIdAtom = atom<Nullable<string>>(null);
export const isWidgetFiltersModalOpenAtom = atom(false);
export const isEditWidgetTitleModalOpenAtom = atom(false);
export const widgetNameToDeleteAtom = atom('');

export const isWidgetDeleteModalOpenAtom = atom(get => !!get(widgetNameToDeleteAtom));

export const widgetConfigAtom = atom(get => {
  const widgetId = get(widgetIdAtom);
  const reportWidgets = get(unwrappedReportWidgetsToRenderAtom);
  const widget = reportWidgets.find(widget => widget.widgetId === widgetId);

  return widget;
});
