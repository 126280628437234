import { ErrorBoundary } from '@sentry/react';
import { ReactNode, Suspense } from 'react';
import { StatusTranslationKey } from '@/types/status';
import { DashboardContent, DashboardContentHeading } from './DashboardContent';
import { ContentState } from '../contentState/ContentState';

export const DashboardContentFallback = ({ children }: { children: ReactNode }) => (
  <ErrorBoundary fallback={<DashboardContent entityId={null} dashboardWidgets={[]} />}>
    <Suspense
      fallback={
        <DashboardContentHeading>
          <ContentState status={StatusTranslationKey.LOADING} />
        </DashboardContentHeading>
      }
    >
      {children}
    </Suspense>
  </ErrorBoundary>
);
