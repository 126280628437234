import { Icon, Typography } from '@statsbomb/kitbag-components';

export const Accordion = ({
  title,
  isDefaultExpanded,
  children,
}: {
  title: string;
  isDefaultExpanded: boolean;
  children: React.ReactNode;
}) => (
  <details data-testid="accordion" className="group" {...(isDefaultExpanded ? { open: true } : null)}>
    <summary className="block p-4 cursor-pointer bg-canvas-secondary-main">
      <span className="flex">
        <Typography variant="headingRegular" as="span" className="w-full">
          {title}
        </Typography>
        <span className="block group-open:rotate-180">
          <Icon variant="ChevronDown" colour="primary" />
        </span>
      </span>
    </summary>
    <div className="flex flex-col gap-4 p-4">{children}</div>
  </details>
);
