import { useTranslation } from 'react-i18next';

export const useDataLockerNavItems = () => {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation(['dataLocker', 'entity']);

  return [
    {
      id: 'data-locker-events',
      label: t('landingPage.link', { entity: t('event.one', { ns: 'entity' }) }),
      route: `/${locale}/data-locker/events`,
    },
    {
      id: 'data-locker-players',
      label: t('landingPage.link', { entity: t('player.one', { ns: 'entity' }) }),
      route: `/${locale}/data-locker/players`,
    },
    {
      id: 'data-locker-teams',
      label: t('landingPage.link', { entity: t('team.one', { ns: 'entity' }) }),
      route: `/${locale}/data-locker/teams`,
    },
  ];
};
