import { ReactNode } from 'react';
import { VisDisplayMode } from '@/types/visualisation';
import { useVisDisplayMode } from '@/hooks/useVisDisplayMode';
import { twJoin } from 'tailwind-merge';

export const VisSectionContainer = ({ children }: { children: ReactNode }) => {
  const [visDisplayMode] = useVisDisplayMode();

  return (
    <div className={twJoin('flex flex-col h-full', visDisplayMode === VisDisplayMode.BOTH && 'gap-2')}>{children}</div>
  );
};
