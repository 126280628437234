import { BaseOption, Nullable } from '@/types/generic';
import { LinkType } from '@/types/link';
import { NestedObject, NestedObjectValue } from '@/types/object';
import { isArray, isObject } from 'lodash/fp';

interface Link {
  label: string;
  id: number;
  type: LinkType;
}

export const addLink = (object: NestedObject, label: string, id: number, linkType: LinkType) => ({
  ...object,
  [linkType]: {
    id,
    label,
  },
});

export const addLinks = (object: NestedObject, links: Link[]) =>
  links.reduce((obj, link) => addLink(obj, link.label, link.id, link.type), object);

/**
 * Function to flatten any object into a hyphen delimited reference
 */
export const flattenAndAddPrefix = (
  objectToFlatten: NestedObject | NestedObject[] | NestedObjectValue[],
  delimiter: string = '-',
  removeEmptyValues: boolean = true,
) => {
  const output: { [key: string]: NestedObjectValue } = {};

  Object.entries(objectToFlatten).forEach(([objKey, objValue]) => {
    if (objValue == null && removeEmptyValues) return;

    if (typeof objValue === 'object' && objValue != null) {
      const flatObject = flattenAndAddPrefix(objValue, delimiter, removeEmptyValues);

      Object.entries(flatObject)
        .filter(([_, flatObjValue]) => flatObjValue != null || !removeEmptyValues)
        .forEach(([flatObjKey, flatObjValue]) => {
          output[objKey + delimiter + flatObjKey] = flatObjValue;
        });
    } else {
      output[objKey] = objValue;
    }
  });

  return output;
};

export const flattenObject = ({
  objectToFlatten,
  keysToExclude,
}: {
  objectToFlatten: NestedObject;
  keysToExclude?: string[];
}): NestedObject => {
  const output: NestedObject = {};

  Object.entries(objectToFlatten).forEach(([key, value]) => {
    if (!keysToExclude?.includes(key) && value && typeof value === 'object' && !Array.isArray(value)) {
      Object.assign(output, flattenObject({ objectToFlatten: value, keysToExclude }));
    } else {
      output[key] = value;
    }
  });

  return output;
};

export const convertDotNotationToObjectWithValue = <T>(objectKeys: string, value: T) =>
  objectKeys
    .split('.')
    .reverse()
    .reduce((acc, key, i) => {
      if (i === 0) {
        return { [key]: value };
      }
      return { [key]: acc };
    }, {});

export const convertToDropdownOption = <T>(label: unknown, value: T, optional?: {}) => ({
  label: String(label),
  value,
  optional,
});

export const optionsToValueStrings = <T>(options: Nullable<BaseOption<T>[]>) =>
  options?.map(({ value }) => `${value}`) || [];

export const toObject = (key: Nullable<string>, value: Nullable<string | number | number[] | string[]>) =>
  key && value && (!Array.isArray(value) || value.length) ? { [key]: value } : {};

export const isEmptyObject = (data: unknown) => !isArray(data) && isObject(data) && !Object.keys(data).length;

export const isObjectWithEmptyValues = (data: unknown): boolean => {
  if (!isObject(data) || isArray(data)) return false;

  return Object.values(data).every(
    value => (isArray(value) && !value.length) || (isObject(value) && !Object.keys(value).length),
  );
};
