import { useTranslation } from 'react-i18next';
import { PLAYER_RADAR_TEMPLATES } from '@/consts/radar';
import { RadarCard } from '@/components/radar/RadarCard';
import { OverviewHeader } from '@/components/section/OverviewHeader';
import { RadarExportModal } from '@/components/radar/RadarExportModal';
import { VisContentsContainer } from '@/components/layout/VisContentsContainer';
import { PlayerRadarChart } from './PlayerRadarChart';
import { PlayerRadarSummary, PlayerRadarTableGroup } from './PlayerRadarTableGroup';

export const PlayerRadar = () => {
  const { t } = useTranslation(['radar', 'entity']);
  const label = t('dropdownLabel', { entity: t('player.one', { ns: 'entity' }) });

  return (
    <>
      <div className="flex flex-col gap-2 min-h-full">
        <OverviewHeader />
        <VisContentsContainer>
          <RadarCard templates={PLAYER_RADAR_TEMPLATES} label={label}>
            <PlayerRadarChart id="main" />
          </RadarCard>
          <PlayerRadarTableGroup />
        </VisContentsContainer>
      </div>

      <RadarExportModal>
        <PlayerRadarChart id="export" isInteractive={false} />
        <PlayerRadarSummary />
      </RadarExportModal>
    </>
  );
};
