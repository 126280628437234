import { SAVED_SHOT_OUTCOMES } from '@/consts/visualisations';
import { ShotMapEventMarker } from '@/types/visualisation';
import { useXGScale } from '@statsbomb/kitbag-datavis';
import { useTheme } from 'styled-components';

export const useShotMarkerStroke = () => {
  const theme = useTheme();
  const getXgColor = useXGScale();

  const getMarkerStroke = (event: ShotMapEventMarker) => {
    if (event.type === 'own_goal_for' || event.outcome === 'goal' || SAVED_SHOT_OUTCOMES.includes(event.outcome!)) {
      return theme.colours.visualisations.dataObjects.inverse;
    }
    return getXgColor(event.xg);
  };

  return getMarkerStroke;
};
