import { Accordion } from '@/components/accordion/Accordion';
import { ResetAllFiltersButton } from '@/components/button/ResetAllFiltersButton';
import { CompetitionSelector } from '@/components/filters/CompetitionSelector';
import { DateRangeSelector } from '@/components/filters/DateRangeSelector';
import { SeasonSelector } from '@/components/filters/SeasonSelector';
import { TeamSelector } from '@/components/filters/TeamSelector';
import { useTranslation } from 'react-i18next';

export const DataLockerTeamHighLevelFilters = () => {
  const { t } = useTranslation('filters');

  return (
    <>
      <Accordion title={t('general')} isDefaultExpanded>
        <ResetAllFiltersButton />
        <CompetitionSelector />
        <SeasonSelector />
        <TeamSelector />
      </Accordion>
      <Accordion title={t('games')} isDefaultExpanded>
        <DateRangeSelector />
      </Accordion>
    </>
  );
};
