// TODO (PPC-461: PitchVisualisation - Composition Pattern)
import { hasVideoLicenceAtom } from '@/atoms/user';
import { ColumnControlsWrapper } from '@/components/controls/ColumnControlsWrapper';
import { VisControls } from '@/components/controls/VisControls';
import { ExportModal } from '@/components/export/ExportModal';
import { VisualisationMap } from '@/types/visualisation';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { useVisDisplayMode } from '@/hooks/useVisDisplayMode';
import { VisualisationComponent } from './VisualisationComponent';

const PitchVisualisationWrapper = ({
  headerComponent,
  visualisations,
  footerComponent,
}: {
  headerComponent?: JSX.Element;
  footerComponent?: JSX.Element;
  visualisations: VisualisationMap;
}) => {
  const hasVideoLicence = useAtomValue(hasVideoLicenceAtom);
  const [visDisplayMode] = useVisDisplayMode();

  const pitchVisualisation = (
    <VisualisationComponent allowInteraction={hasVideoLicence} visualisations={visualisations} />
  );

  return (
    <>
      {visDisplayMode === 'both' && (
        <ColumnControlsWrapper headerComponent={headerComponent}>
          <VisControls />
        </ColumnControlsWrapper>
      )}
      {pitchVisualisation}
      <div className="mx-auto max-w-[1000px]">{footerComponent}</div>
      <ExportModal>{pitchVisualisation}</ExportModal>
    </>
  );
};

export const PitchVisualisation = ({
  headerComponent,
  visualisations,
  footerComponent,
}: {
  headerComponent?: JSX.Element;
  footerComponent?: JSX.Element;
  visualisations: VisualisationMap;
}) => (
  <Suspense>
    <PitchVisualisationWrapper {...{ headerComponent, visualisations, footerComponent }} />
  </Suspense>
);
