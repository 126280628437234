import { EventMap } from '@/components/visualisations/EventMap';
import { PitchVisComponentProps } from '@/types/visualisation';
import { dataLockerPitchEventsAtom, dataLockerUnwrappedPitchEventsAtom } from '@/atoms/dataLocker/vis';

export const DataLockerEventMap = ({ allowInteraction, isPreview }: PitchVisComponentProps) => (
  <EventMap
    allowInteraction={allowInteraction}
    pitchEventsAtom={dataLockerPitchEventsAtom}
    unwrappedPitchEventsAtom={dataLockerUnwrappedPitchEventsAtom}
    isPreview={isPreview}
  />
);
