import { PageTitle } from '@/components/head/PageTitle';
import { AppShellContents } from '@/components/layout/AppShell';
import { LocalisationSwitcher } from '@/components/locale/LocalisationSwitcher';
import { GenderToggle } from '@/components/toggles/GenderToggle';
import { ThemeToggle } from '@/components/toggles/ThemeToggle';
import { TopBar } from '@/components/topBar/TopBar';
import { TopBarHeading } from '@/components/topBar/TopBarHeading';
import { Card, CardBody, Typography } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { UserDefaultTeamSelector } from '@/components/dropdowns/UserDefaultTeamDropdown';

const SettingsSection = ({ children }: { children: React.ReactNode }) => (
  <section className="flex flex-col gap-4 max-w-80">{children}</section>
);

export const Settings = () => {
  const { t } = useTranslation(['navigation', 'preferences']);

  return (
    <>
      <PageTitle>{t('primary.settings')} </PageTitle>
      <AppShellContents
        topBar={
          <TopBar>
            <TopBarHeading>{t('primary.settings')}</TopBarHeading>
          </TopBar>
        }
      >
        <Card>
          <CardBody>
            <div className="flex flex-col gap-4">
              <SettingsSection>
                <Typography variant="headingRegular">{t('display.title', { ns: 'preferences' })}</Typography>
                <LocalisationSwitcher />
                <ThemeToggle />
              </SettingsSection>
              <hr className="border-canvas-secondary-main" />
              <SettingsSection>
                <Typography variant="headingRegular">{t('data.title', { ns: 'preferences' })}</Typography>
                <GenderToggle />
                <UserDefaultTeamSelector />
              </SettingsSection>
            </div>
          </CardBody>
        </Card>
      </AppShellContents>
    </>
  );
};
