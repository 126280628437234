import { MINUTES_PLAYED_COLUMN } from '@/consts/dataLocker/table';

export const PERMANENT_COLUMNS = ['game.name', 'game.date', 'competition.name'] as const;

export const PLAYER_GAMES_DEFAULT_COLUMNS = [
  ...PERMANENT_COLUMNS,
  MINUTES_PLAYED_COLUMN,
  'aggregates.goals',
  'aggregates.shots',
  'aggregates.shotsOutsideBox',
  'aggregates.nonThrowInPasses',
  'aggregates.dribbles',
  'aggregates.aerials',
  'aggregates.nonAerialDuels',
  'aggregates.pressures',
  'aggregates.counterpressures',
  'aggregates.nonThrowInPassesIntoF3',
  'aggregates.nonThrowInThroughBalls',
  'aggregates.nonThrowInKeyPasses',
  'aggregates.crosses',
  'aggregates.nonThrowInPassesStartingInD3',
  'aggregates.d3PressuredPasses',
  'aggregates.receivedPasses',
  'aggregates.touchesInsideBox',
  'aggregates.tackles',
  'aggregates.interceptions',
  'aggregates.shotBlocks',
  'aggregates.dribblePasts',
  'aggregates.ballRecoveries',
  'aggregates.clearances',
  'aggregates.fhalfPressures',
  'aggregates.fouls',
  'aggregates.foulsWon',
  'aggregates.freeKicks',
  'aggregates.corners',
  'aggregates.penaltyShots',
  'aggregates.throwIns',
] as const;

export const GOALKEEPER_GAMES_DEFAULT_COLUMNS = [
  ...PERMANENT_COLUMNS,
  MINUTES_PLAYED_COLUMN,
  'aggregates.gsaa',
  'aggregates.saves',
  'aggregates.goalsAndOwnGoalsConceded',
  'aggregates.nonThrowInPasses',
  'aggregates.shotsConceded',
  'aggregates.shotsOutsideBoxConceded',
  'aggregates.goalKicks',
  'aggregates.shortGoalKicks',
  'aggregates.longGoalKicks',
  'aggregates.penaltyShotsConceded',
  'aggregates.fouls',
] as const;

export const TEAM_GAMES_DEFAULT_COLUMNS = [
  ...PERMANENT_COLUMNS,
  'aggregates.cumulativeXg',
  'aggregates.goals',
  'aggregates.shots',
  'aggregates.shotsOutsideBox',
  'aggregates.nonThrowInPassesIntoF3',
  'aggregates.nonThrowInPassesStartingInD3',
  'aggregates.nonThrowInThroughBalls',
  'aggregates.nonThrowInKeyPasses',
  'aggregates.crosses',
  'aggregates.corners',
  'aggregates.goalKicks',
  'aggregates.shortGoalKicks',
  'aggregates.longGoalKicks',
  'aggregates.pressures',
  'aggregates.fhalfPressures',
  'aggregates.d3PressuredPasses',
  'aggregates.counterpressures',
  'aggregates.freeKicksStartingInF3',
  'aggregates.freeKicksStartingInM3',
  'aggregates.freeKicksStartingInD3',
  'aggregates.aerials',
  'aggregates.nonAerialDuels',
  'aggregates.penaltyShots',
  'aggregates.throwIns',
  'aggregates.freeKicks',
  'aggregates.tackles',
  'aggregates.interceptions',
  'aggregates.shotBlocks',
  'aggregates.ballRecoveries',
  'aggregates.clearances',
  'aggregates.fouls',
  'aggregates.foulsWon',
  'aggregates.dribbles',
  'aggregates.dribblePasts',
  'aggregates.touchesInsideBox',
  'aggregates.throwInsStartingInF3',
  'aggregates.throwInsStartingInM3',
  'aggregates.throwInsStartingInD3',
] as const;
