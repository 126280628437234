import { FETCH_ERROR } from '@/consts/client';
import env from '@/env';
import { Environment } from '@/types/environment';
import * as Sentry from '@sentry/react';
import React from 'react';
import { Routes, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

// https://github.com/getsentry/sentry-javascript/issues/10985#issuecomment-1985652158
// this is here because throwing the error from the fetch
// does not mark the error as being handled even though it should be
// by the wrapping error boundaries.
export const sentryMonitoringBeforeSend = (event: Sentry.Event) => {
  const errorMessage = event.exception?.values?.[0].value;
  const isFetchError = errorMessage?.includes(FETCH_ERROR);
  if (isFetchError) {
    event.exception?.values?.forEach(value => {
      if (value.mechanism) {
        // eslint-disable-next-line no-param-reassign
        value.mechanism.handled = true;
      } else {
        // eslint-disable-next-line no-param-reassign
        value.mechanism = {
          type: 'generic',
          handled: true,
        };
      }
    });
  }
  return event;
};

export const initialiseErrorMonitoring = (environment: Environment) => {
  Sentry.init({
    debug: !!Number(env.VITE_SENTRY_DEBUG),
    dsn: env.VITE_SENTRY_DSN,
    environment,
    integrations: [
      // https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Breadcrumbs(),
      new Sentry.HttpContext(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // we can remove this potentially if this issue gets addressed by them
    // https://github.com/getsentry/sentry-javascript/issues/14051
    beforeSend: sentryMonitoringBeforeSend,
  });
};

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
