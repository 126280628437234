import { pitchMarkerColouringModeAtom } from '@/atoms/visualisation';
import { useAtomValue } from 'jotai';
import { useXGScale } from '@statsbomb/kitbag-datavis';
import { MARKER_HIGHLIGHTED_COLOUR } from '@/consts/markerColours';
import { EventSuccessFail, MarkerEvent } from '@/types/event';
import { isNullish } from '@/utils/general';
import { useSuccessFailColouring } from '../useSuccessFailColouring';
import { useCurrentlySelectedVideoId } from '../useCurrentlySelectedVideoId';

export const useEventMarkerColour = () => {
  const pitchMarkerColouringMode = useAtomValue(pitchMarkerColouringModeAtom);

  const currentlySelectedVideoId = useCurrentlySelectedVideoId();
  const successFailColouring = useSuccessFailColouring();
  const xgScale = useXGScale();

  const getMarkerColour = (videoEventId: string | undefined, successFail: EventSuccessFail, xg: MarkerEvent['xg']) => {
    if (pitchMarkerColouringMode === 'default') {
      return videoEventId === currentlySelectedVideoId ? MARKER_HIGHLIGHTED_COLOUR : undefined;
    }

    if (pitchMarkerColouringMode === 'xg') {
      return isNullish(xg) ? successFailColouring.neutral : xgScale(xg);
    }

    return successFailColouring[successFail];
  };

  return getMarkerColour;
};
