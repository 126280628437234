import { ALL_ITEMS } from '@/consts/interface';
import { Nullable } from '@/types/generic';
import { NestedObject } from '@/types/object';
import qs from 'qs';

export const IN_SEPARATOR_CHAR = `␞`;

export const convertFilterParamsToString = <T>(params: Nullable<T>) => {
  // Where params has an in key, join array values with in separator special character
  const updatedIn = Object.entries((params as NestedObject)?.in || {}).reduce<NestedObject>((acc, [key, value]) => {
    if (value.length) {
      const separatedString = value.join(IN_SEPARATOR_CHAR);
      return { ...acc, [key]: [separatedString] };
    }
    return { ...acc, [key]: value };
  }, {});

  const updatedParams = { ...params, in: updatedIn };

  return qs.stringify(updatedParams, { encode: false, addQueryPrefix: true, arrayFormat: 'repeat' });
};

export const hasEmptyInFilters = (filterParams: Nullable<NestedObject>) =>
  !filterParams || Object.values(filterParams.in || {}).some(eqFilter => eqFilter.length === 0);

export const gameEventParams = (gameId: Nullable<number>) => {
  if (!gameId) return undefined;

  return {
    limit: ALL_ITEMS,
    eq: { 'event.game_id': gameId },
    order_by: { 'event.game_time': 'ASC' },
  };
};
