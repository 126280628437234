import { Navigate } from 'react-router-dom';
import { useKitbagAuth } from '@statsbomb/kitbag-auth';
import { useTranslation } from 'react-i18next';

export const RedirectToLocalised = () => {
  const { isLoading } = useKitbagAuth();
  const {
    i18n: { language },
  } = useTranslation();

  return isLoading ? null : <Navigate to={`/${language}`} replace />;
};
