import { metricKeyAtom } from '@/atoms/metric';
import { METRIC_KEY } from '@/consts/searchKeys';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useSetMetricKey = () => {
  const [searchParams] = useSearchParams();

  /* it seems as though due to metricKey value never being used
    anywhere and only being set by this hook, the atomsWithQuery
    that get called when it changed never recall it when you F5 or refresh in the browser
    changing this to a useAtom fixes it. Another fix would be to just setMetricKey
    before the useEffect 
  */
  const [metricKey, setMetricKey] = useAtom(metricKeyAtom);
  const metricKeyParam = searchParams.get(METRIC_KEY);

  useEffect(() => {
    if (metricKeyParam && metricKeyParam !== metricKey) {
      setMetricKey(metricKeyParam);
    }
  }, [metricKeyParam, metricKey]);
};
