import { CamelCaseValues, KeysToCamelCase } from '@/types/generic';
import { NestedObject } from '@/types/object';
import { isString, memoize } from 'lodash/fp';

const isNestedObject = (val: NestedObject[string]): val is NestedObject =>
  !Array.isArray(val) && typeof val === 'object' && val !== null;

const snakeToCamelConversion = <T>(value: T) =>
  isString(value) ? value.replace(/_([a-z0-9])/gi, (_, letter) => letter.toUpperCase()) : value;
export const kebabToCamel = (str: string) => str.replace(/-([a-z0-9])/gi, (_, letter) => letter.toUpperCase());

export const camelToSnakeCase = (str: string) =>
  str
    .split(/(?=[A-Z]\d*|90)/)
    .join('_')
    .toLowerCase();

export const snakeToCamel = memoize(snakeToCamelConversion);

export const objSnakeToCamel = <T extends NestedObject>(record: T): KeysToCamelCase<T> => {
  const newObj = {} as KeysToCamelCase<T>;

  Object.entries(record).forEach(([key, value]) => {
    const camelKey = snakeToCamel(key) as keyof KeysToCamelCase<T>;
    if (Array.isArray(value)) {
      newObj[camelKey] = value.map(v => (isNestedObject(v) ? objSnakeToCamel(v) : v)) as CamelCaseValues<T>;
    } else {
      newObj[camelKey] = (isNestedObject(value) ? objSnakeToCamel(value) : value) as CamelCaseValues<T>;
    }
  });

  return newObj;
};

export const objValsSnakeToCamel = <T extends NestedObject>(record: T): T =>
  Object.entries(record).reduce((newObj, [key, value]) => {
    if (Array.isArray(value)) {
      return {
        ...newObj,
        [key]: value.map(v => (isNestedObject(v) ? objValsSnakeToCamel(v) : snakeToCamel(v))),
      };
    }
    return {
      ...newObj,
      [key]: isNestedObject(value) ? objValsSnakeToCamel(value) : snakeToCamel(value),
    };
  }, {} as T);
