import { GAME_ID } from '@/consts/searchKeys';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { MenuItemWithIcon } from '../menu/MenuItemWithIcon';

export const TeamGameVideosMenu = ({ gameId, gameTotalGoals }: { gameId: number; gameTotalGoals: number }) => {
  const navigate = useNavigate();

  return (
    <MenuItemWithIcon
      onClick={() => navigate(`./video/goals?${GAME_ID}=${gameId}`)}
      disabled={!gameTotalGoals}
      icon="Play"
      text={t('gameGoals', { ns: 'games', count: gameTotalGoals })}
    />
  );
};
