/* istanbul ignore file */
import { Route, Routes } from 'react-router-dom';
import { GameLayout } from '@/components/layout/game/GameLayout';
import { NotFound } from '@/pages/NotFound';
import { GameDashboardPage } from '@/pages/game/dashboard/GameDashboardPage';

export const GameRoutes = () => (
  <Routes>
    <Route element={<GameLayout />}>
      <Route path="dashboard" element={<GameDashboardPage />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  </Routes>
);
