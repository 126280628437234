import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';
import {
  GOALKEEPER_GAMES_DEFAULT_COLUMNS,
  PLAYER_GAMES_DEFAULT_COLUMNS,
} from '@/components/tables/GameAggregatesTable.constants';
import { playerGameAggsPositionsAtom } from '@/atoms/queries/player/playerGameAggData';
import { Position } from '@/types/player';
import { getReadWriteVisibleColumnsAtom } from '../../utils';

export const playerGameDataVisibleColumnsUserSelectedAtom = atom<string[] | undefined>(undefined);

export const playerGameDataDefaultColumnsAtom = atom(async get => {
  const { data: playerGameAggsPositions } = await get(playerGameAggsPositionsAtom);

  // positions are already sorted in a descending order from most played position to least played position
  return [
    ...(playerGameAggsPositions[0]?.position === Position.GK
      ? GOALKEEPER_GAMES_DEFAULT_COLUMNS
      : PLAYER_GAMES_DEFAULT_COLUMNS),
  ];
});

export const playerGameDataVisibleColumnsAtom = getReadWriteVisibleColumnsAtom(
  playerGameDataVisibleColumnsUserSelectedAtom,
  playerGameDataDefaultColumnsAtom,
);

export const unwrappedPlayerGameDataVisibleColumnsAtom = unwrap(playerGameDataVisibleColumnsAtom, prev => prev || []);
