import { playerDataVisibleColumnsUserSelectedAtom } from '@/atoms/dataLocker/player/table';
import { eventAtoms, radarAtoms, tableAtoms, visConfigAtoms } from '@/consts/scope';
import { ScopeProvider } from 'jotai-scope';
import { ReactNode } from 'react';

export const DashboardWidgetScopeProvider = ({ children }: { children: ReactNode }) => (
  <ScopeProvider
    atoms={[...visConfigAtoms, ...radarAtoms, ...tableAtoms, ...eventAtoms, playerDataVisibleColumnsUserSelectedAtom]}
  >
    {children}
  </ScopeProvider>
);
