import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';

export const VisContentsContainer = ({ children }: { children: ReactNode }) => {
  const { pathname } = useLocation();
  const isPreview = pathname.includes('preview');

  return <div className={twJoin('flex flex-col gap-2 flex-1', !isPreview && 'lg:!flex-row')}>{children}</div>;
};
