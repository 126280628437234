import { EventWithRels } from '@statsbomb/parachute-types';
import { atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { atom } from 'jotai';
import { convertEventDataIntoDescriptions, processEventDataAtom } from '@/utils/atoms/eventData';
import { getVideoIdsFromEvents } from '@/utils/video';
import { convertFilterParamsToString, gameEventParams } from '@/utils/api';
import { selectedGameIdForVideosAtom } from '../video';
import { fetchClientAtom } from './client';

const rawGameGoalEventsAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['gameGoalEvents', get(selectedGameIdForVideosAtom)] as const;

  const queryFn = async ({ queryKey: [, gameId] }: { queryKey: typeof queryKey }) => {
    if (!gameId) return [];
    const { fetch } = get(fetchClientAtom);

    return (await fetch(
      `/metric/goals_and_own_goals/events${convertFilterParamsToString(gameEventParams(gameId))}`,
    )) as Promise<EventWithRels[]>;
  };

  return { queryKey, queryFn };
});

export const gameGoalsEventVideoIdsAtom = atom(async get => {
  const processedEventData = processEventDataAtom(await get(rawGameGoalEventsAtom));

  return getVideoIdsFromEvents(processedEventData);
});

export const gameGoalEventDescriptionsAtom = atom(async get => {
  const rawGameGoalEvents = await get(rawGameGoalEventsAtom);
  return convertEventDataIntoDescriptions(rawGameGoalEvents.data);
});
