import { ReactNode } from 'react';
import { EventMapLegend } from '../EventMap/EventMapLegend';

export const PitchVisWrapper = ({ children }: { children: ReactNode }) => (
  <figure className="h-full flex flex-col gap-2 justify-center" data-testid="pitch-figure">
    {children}
  </figure>
);

export const PitchVisWithLegend = ({ children }: { children: ReactNode }) => (
  <PitchVisWrapper>
    {children}
    <EventMapLegend />
  </PitchVisWrapper>
);
