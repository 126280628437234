import React from 'react';
import { CardBody, Grid } from '@statsbomb/kitbag-components';
import { useAtomValue } from 'jotai';
import { loadableReportWidgetsToRenderAtom, reportWidgetsToRenderAtom } from '@/atoms/report/reportSaving';
import { useStatus } from '@/hooks/useStatus';
import { ReportWidgetVis } from '@/types/widget';
import { CardHeightWrapper } from '@/components/card/CardHeightWrapper';
import { ReportWidgetWrapper } from '@/components/widget/ReportWidgetWrapper';
import { useLoadableStatus } from '@/hooks/useLoadableStatus';
import { ErrorCard } from '@/components/contentState/ErrorCard';

const ReportWidgetsBase = ({ widgets }: { widgets: ReportWidgetVis[] }) => (
  <div className="py-2 md:p-2">
    <Grid>
      {widgets.map(widget => (
        <Grid item sm={12} md={6} xl={4} key={widget.widgetId}>
          <ReportWidgetWrapper {...widget} />
        </Grid>
      ))}
    </Grid>
  </div>
);

const ReportWidgetsWithData = () => {
  const loadableReportWidgets = useAtomValue(loadableReportWidgetsToRenderAtom);
  const reportWidgetsStatusKey = useLoadableStatus(reportWidgetsToRenderAtom);
  const { state } = loadableReportWidgets;

  const reportWidgetsStatus = useStatus('report', reportWidgetsStatusKey);

  return !reportWidgetsStatusKey && state === 'hasData' ? (
    <ReportWidgetsBase widgets={loadableReportWidgets.data} />
  ) : (
    <CardHeightWrapper>
      <CardBody {...reportWidgetsStatus} />
    </CardHeightWrapper>
  );
};

export const ReportWidgets = () => (
  <ErrorCard translationNameSpace="report">
    <ReportWidgetsWithData />
  </ErrorCard>
);
