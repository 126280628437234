import { defaultWidgetReportData } from '@/consts/defaults/report';
import { Nullable } from '@/types/generic';
import { atom } from 'jotai';

export const isCreateReportModalOpenAtom = atom(false);
export const isRenameReportModalOpenAtom = atom(false);

export const reportNameAtom = atom('');
export const reportIdToDeleteAtom = atom<Nullable<string>>(null);

/* this defaults to undefined as it gets set by a useParams call for reportId which can be undefined */
export const selectedReportIdAtom = atom<string | undefined>(undefined);
export const isSaveWidgetToReportModalOpenAtom = atom(false);

export const saveWidgetToReportDataAtom = atom(defaultWidgetReportData);
