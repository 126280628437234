import { userDefaultTeamIdAtom } from '@/atoms/user';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { userDetailsAtom } from '@/atoms/queries/user';
import {
  AppShellErrorFallback,
  AppShellFullLoadingFallback,
  AppShellLoadingFallback,
} from '../layout/AppShellFallback';

const RedirectToTeamBase = ({ teamId }: { teamId: number }) => (
  <AppShellFullLoadingFallback>
    <Navigate to={`team/${teamId}/dashboard`} replace />
  </AppShellFullLoadingFallback>
);

const RedirectToTeamWithData = () => {
  const userDefaultTeamId = useAtomValue(userDefaultTeamIdAtom);

  return <RedirectToTeamBase teamId={userDefaultTeamId} />;
};

const RedirectToTeamFallback = () => {
  const userDetails = useAtomValue(userDetailsAtom);

  return <RedirectToTeamBase teamId={userDetails.data.entity.team_id} />;
};

export const RedirectToTeam = () => (
  <Suspense fallback={<AppShellLoadingFallback />}>
    <ErrorBoundary fallback={<AppShellErrorFallback />}>
      <ErrorBoundary fallback={<RedirectToTeamFallback />}>
        <RedirectToTeamWithData />
      </ErrorBoundary>
    </ErrorBoundary>
  </Suspense>
);
