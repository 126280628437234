import { PitchOrientation, UnwrappedPitchEventsAtom } from '@/types/visualisation';
import { PitchViz } from '@statsbomb/kitbag-datavis';
import { useAtomValue } from 'jotai';
import { EventMapLayers } from './EventMapLayers';
import { PitchVisWithAttackingDirectionArrow } from '../PitchVisualisation/PitchVisWithAttackingDirectionArrow';

export const EventMapPreview = ({
  unwrappedPitchEventsAtom,
}: {
  unwrappedPitchEventsAtom: UnwrappedPitchEventsAtom;
}) => {
  const { markerEvents, arrowEvents } = useAtomValue(unwrappedPitchEventsAtom);
  const eventMapLayers = (
    <EventMapLayers markerEvents={[...markerEvents, ...arrowEvents]} arrowEvents={[]} useDefaultColor />
  );
  const rotationName: PitchOrientation = 'HORIZONTAL';

  return (
    <PitchVisWithAttackingDirectionArrow pitchVisType="eventmap" pitchRotation={rotationName}>
      <PitchViz
        data-testid="event-map-preview"
        /* this is intentionally empty to overwrite pitch viz svg styles
      to position the arrow correctly */
        className=""
        rotationName={rotationName}
        pitchFocusZoneName="FULL_PITCH"
        overlay={eventMapLayers}
      />
    </PitchVisWithAttackingDirectionArrow>
  );
};
