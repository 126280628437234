import { selectedVisEventsAtom } from '@/atoms/vis/selection';
import { showMarkerStartAtom } from '@/atoms/visualisation';
import { useEventMarkerColour } from '@/hooks/eventMap/useEventMarkerColour';
import { useCurrentlySelectedVideoId } from '@/hooks/useCurrentlySelectedVideoId';
import { useNavigateToPreview } from '@/hooks/useNavigateToPreview';
import { useResetSelectionLayer } from '@/hooks/useResetSelectionLayer';
import { ArrowEvent, MarkerEvent } from '@/types/event';
import { isNullish } from '@/utils/general';
import { EventArrowLayer, EventMarkerLayer } from '@statsbomb/kitbag-datavis';
import { useAtom, useAtomValue } from 'jotai';
import { unwrappedMarkersDescriptionsAtom } from '@/atoms/eventMarkers';
import { EventMapTooltip } from './EventMapTooltip';

export const EventMapLayers = ({
  allowInteraction,
  markerEvents,
  arrowEvents,
  useDefaultColor,
}: {
  allowInteraction?: boolean;
  markerEvents: MarkerEvent[];
  arrowEvents: ArrowEvent[];
  useDefaultColor?: boolean;
}) => {
  const [selectedVisEvents, setSelectedEvents] = useAtom(selectedVisEventsAtom);
  const markersDescriptions = useAtomValue(unwrappedMarkersDescriptionsAtom);

  const allEvents = [...markerEvents, ...arrowEvents];
  const resetSelectionLayer = useResetSelectionLayer();
  const navigateToPreview = useNavigateToPreview();

  const showMarkerStart = useAtomValue(showMarkerStartAtom);

  const currentlySelectedVideoId = useCurrentlySelectedVideoId();
  const getEventMarkerColour = useEventMarkerColour();
  const currentVideoEventId = [allEvents.find(item => item.videoEventId === currentlySelectedVideoId)?.eventId];

  const onMarkerClick: React.ComponentProps<typeof EventMarkerLayer>['onMarkerClick'] = allowInteraction
    ? (_, id) => {
        resetSelectionLayer();
        setSelectedEvents(allEvents.filter(({ eventId }) => eventId === id));

        navigateToPreview();
      }
    : undefined;

  const selectedItemIds = selectedVisEvents?.map(({ eventId }) => eventId);

  return (
    <>
      <EventMarkerLayer
        onMarkerClick={onMarkerClick}
        markers={markerEvents?.map(({ eventId: id, startX, startY, endX, endY, successFail, videoEventId, xg }) => {
          const x = showMarkerStart || isNullish(endX) ? startX : endX;
          const y = showMarkerStart || isNullish(endY) ? startY : endY;
          const markerDescription = markersDescriptions.find(marker => marker.eventId === id);

          return {
            id,
            x,
            y,
            fill: useDefaultColor ? undefined : getEventMarkerColour(videoEventId, successFail, xg),
            shapeName: 'CIRCLE',
            tooltip:
              allowInteraction && markerDescription ? <EventMapTooltip markerDescription={markerDescription} /> : null,
          };
        })}
        selectedItemIds={selectedItemIds}
        highlightedItemIds={currentVideoEventId}
      />
      <EventArrowLayer
        onMarkerClick={onMarkerClick}
        arrows={arrowEvents?.map(({ eventId: id, startX, startY, endX, endY, successFail, videoEventId, xg }) => {
          const markerDescription = markersDescriptions.find(marker => marker.eventId === id);
          return {
            id,
            startX,
            startY,
            endX,
            endY,
            stroke: useDefaultColor ? undefined : getEventMarkerColour(videoEventId, successFail, xg),
            arrowHeadName: 'EQUILATERAL',
            tooltip:
              allowInteraction && markerDescription ? <EventMapTooltip markerDescription={markerDescription} /> : null,
          };
        })}
        selectedItemIds={selectedItemIds}
        highlightedItemIds={currentVideoEventId}
      />
    </>
  );
};
