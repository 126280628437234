import { getOptionsAtomForSection } from '@/utils/atoms/highLevelFilters';
import { atom } from 'jotai';
import { EventDescriptionsMapAtom } from '@/types/event';
import { unwrap } from 'jotai/utils';
import { eventMarkersDescriptionsAtom } from './queries/eventData';
import { playerEventMarkersDescriptionsAtom } from './queries/player/playerEventData';
import { teamEventMarkersDescriptionsAtom } from './queries/team/teamEventData';

const eventMarkersDescriptionsSectionMapAtom = atom<EventDescriptionsMapAtom>(() => ({
  player: playerEventMarkersDescriptionsAtom,
  team: teamEventMarkersDescriptionsAtom,
  dataLocker: eventMarkersDescriptionsAtom,
}));

export const markersDescriptionsAtom = atom(getOptionsAtomForSection(eventMarkersDescriptionsSectionMapAtom));

export const unwrappedMarkersDescriptionsAtom = unwrap(markersDescriptionsAtom, prev => prev || []);
