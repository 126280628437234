import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Entity } from '@/types/entity';
import { RadarTemplate } from '@/types/radar';
import { PLAYER_RADAR_TEMPLATES, TEAM_RADAR_TEMPLATES } from '@/consts/radar';
import { unwrappedRadarTemplateIdAtom } from '@/atoms/radar';
import { useGetEntity } from './useGetEntity';

const entityTemplateNameMap: Record<Entity, RadarTemplate[]> = {
  player: PLAYER_RADAR_TEMPLATES,
  team: TEAM_RADAR_TEMPLATES,
};

// a hook for getting the correct translated radar template name for an entity
export const useRadarTemplateName = () => {
  const { entity } = useGetEntity();
  const { t } = useTranslation('radar');
  const radarTemplateId = useAtomValue(unwrappedRadarTemplateIdAtom);

  if (entity !== 'team' && entity !== 'player') {
    return '';
  }

  const selectedTemplateKey = entityTemplateNameMap[entity].find(template => template.id === radarTemplateId)?.labelKey;

  const selectedTemplateName = t(`templates.${selectedTemplateKey}`, {
    ns: 'radar',
  });

  return selectedTemplateName;
};
