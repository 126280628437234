import { useSetAtom } from 'jotai';
import { ReportWidgetVis } from '@/types/widget';
import { entityVisualisationComponents } from '@/consts/widget';
import { useWidgetNavigationPath } from '@/hooks/useWidgetNavigationPath';
import { getWidgetAtomsToHydrate } from '@/utils/atoms/atomHydration';
import { atomsToHydrateAtom } from '@/atoms/hydration';
import { getFilterCount } from '@/utils/filters';
import { Suspense } from 'react';
import { useHydrateAtoms } from 'jotai/utils';
import { useSetWidgetDataDebouncedFilterParams } from '@/hooks/apiFilterParams/useSetWidgetDataDebouncedFilterParams';
import { ReportWidget } from './ReportWidget';
import { ReportWidgetScopeProvider } from './ReportWidgetScopeProvider';
import { WidgetFiltersModal } from '../modal/WidgetFiltersModal/WidgetFiltersModal';

const ReportWidgetWithData = ({
  filters,
  displaySettings,
  entity,
  entityId,
  vis,
  widgetId,
  title,
  description,
}: ReportWidgetVis) => {
  useSetWidgetDataDebouncedFilterParams();
  const widgetAtomsToHydrate = getWidgetAtomsToHydrate({ widgetId, entity, entityId, filters, displaySettings });
  useHydrateAtoms(widgetAtomsToHydrate);
  const setAtomsToHydrate = useSetAtom(atomsToHydrateAtom);

  const navigationPath = useWidgetNavigationPath(entity, entityId, vis, filters.metric);

  const Component = entityVisualisationComponents[entity]?.[vis]!;

  return (
    <>
      <ReportWidget
        title={title}
        description={description}
        navigationPath={navigationPath}
        onClickTitle={() => setAtomsToHydrate(widgetAtomsToHydrate)}
        filtersCount={getFilterCount(filters)}
      >
        <Component isWidget id={widgetId} />
      </ReportWidget>
      <WidgetFiltersModal />
    </>
  );
};

export const ReportWidgetWrapper = (props: ReportWidgetVis) => (
  <Suspense>
    <ReportWidgetScopeProvider>
      <ReportWidgetWithData {...props} />
    </ReportWidgetScopeProvider>
  </Suspense>
);
