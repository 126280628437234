import { GameRangeFilterGroup } from '@/components/filters/GameRangeFilterGroup';
import { ResetAllFiltersButton } from '@/components/button/ResetAllFiltersButton';
import { useTranslation } from 'react-i18next';
import { Accordion } from '@/components/accordion/Accordion';
import { CompetitionSelector } from '@/components/filters/CompetitionSelector';
import { TeamSelector } from '@/components/filters/TeamSelector';
import { GameSelector } from '@/components/filters/GameSelector';
import { playerSelectedGamesAtom, playerGamesBySeasonAtom } from '@/atoms/filters/player/playerGames';
import { PositionFilter } from '@/components/filters/PositionFilter';
import { GameTypeFilter } from '@/components/filters/GameTypeFilter';
import { useSetPlayerEventDataDebouncedFilterParams } from '@/hooks/apiFilterParams/useSetPlayerEventDataDebouncedFilterParams';
import { SeasonSelector } from '@/components/filters/SeasonSelector';
import { UserFilters } from '@/components/filters/UserFilters/UserFilters';

export const PlayerHighLevelFilters = () => {
  useSetPlayerEventDataDebouncedFilterParams();
  const { t } = useTranslation(['filters', 'entity']);

  return (
    <div className="flex flex-col">
      <UserFilters />
      <Accordion title={t('general')} isDefaultExpanded>
        <ResetAllFiltersButton />
        <CompetitionSelector />
        <SeasonSelector />
        <TeamSelector />
      </Accordion>
      <Accordion title={t('games')} isDefaultExpanded>
        <GameRangeFilterGroup />
        <GameTypeFilter />
        <GameSelector gamesGroupedBySeasonAtom={playerGamesBySeasonAtom} selectedGamesAtom={playerSelectedGamesAtom} />
      </Accordion>
      <Accordion title={t('player.one', { ns: 'entity' })} isDefaultExpanded>
        <PositionFilter />
      </Accordion>
    </div>
  );
};
