import { Typography } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';

export const OverviewHeader = () => {
  const { t } = useTranslation(['radar', 'export']);

  return (
    <div className="bg-canvas-tertiary-main w-full flex items-center px-4 py-3">
      <Typography variant="headingRegular">{t('header.title')}</Typography>
    </div>
  );
};
