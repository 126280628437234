import { pitchMarkerColouringModeAtom, showArrowsAtom, showLegendAtom } from '@/atoms/visualisation';
import { useRect } from '@statsbomb/kitbag-components';
import { useAtomValue } from 'jotai';
import { Legend } from '@statsbomb/kitbag-datavis';
import { PitchMarkerColouringMode } from '@/types/visualisation';
import { EventMapMarkerLegend } from './EventMapMarkerLegend';
import { EventMapArrowMarkerLegend } from './EventMapArrowMarkerLegend';
import { EventMapXgLegend } from './EventMapXgLegend';

const getEventMapLegendComponent = (
  showArrows: boolean,
  pitchMarkerColouringMode: PitchMarkerColouringMode,
  legendWidth: number,
) => {
  if (pitchMarkerColouringMode === 'xg') {
    return <EventMapXgLegend legendWidth={legendWidth} />;
  }

  return showArrows ? <EventMapArrowMarkerLegend /> : <EventMapMarkerLegend />;
};

export const EventMapLegend = () => {
  const [legendRef, legendRect] = useRect<HTMLLegendElement>();
  const showLegend = useAtomValue(showLegendAtom);
  const showArrows = useAtomValue(showArrowsAtom);
  const pitchMarkerColouringMode = useAtomValue(pitchMarkerColouringModeAtom);

  if (!showLegend || pitchMarkerColouringMode === 'default') {
    return null;
  }

  const eventMapLegendComponent = getEventMapLegendComponent(showArrows, pitchMarkerColouringMode, legendRect.width);

  return <Legend legendRef={legendRef}>{eventMapLegendComponent}</Legend>;
};
