import { atom } from 'jotai';
import { loadable, unwrap } from 'jotai/utils';
import { ConfigDisplaySettings } from '@/types/userConfigs';
import { radarTemplateIdAtom } from '../radar';
import {
  pitchOrientationAtom,
  pitchCropAtom,
  showArrowsAtom,
  isStartPlacementAtom,
  pitchMarkerColouringModeAtom,
  barChartOrientationAtom,
} from '../visualisation';
import { reportConfigsAtom } from '../queries/userConfigs';
import { selectedReportIdAtom } from './report';
import { orderByAtom, orderDirectionAtom } from '../table';

const selectedReportAtom = atom(async get => {
  const savedReports = await get(reportConfigsAtom);
  const selectedReportId = get(selectedReportIdAtom);

  const selectedReport = savedReports.find(({ configId }) => configId === selectedReportId);
  return selectedReport || null;
});

export const unwrappedSelectedReportAtom = unwrap(selectedReportAtom);

export const reportWidgetsToRenderAtom = atom(async get => {
  const reportConfigs = await get(reportConfigsAtom);

  if (reportConfigs.length === 0) return [];

  const selectedReport = get(unwrappedSelectedReportAtom);
  return selectedReport?.definition.widgets || [];
});

export const loadableReportWidgetsToRenderAtom = loadable(reportWidgetsToRenderAtom);

export const unwrappedReportWidgetsToRenderAtom = unwrap(reportWidgetsToRenderAtom, prev => prev || []);

export const widgetDisplaySettingsAtom = atom(async get => {
  const persistValues: ConfigDisplaySettings = {
    radarTemplateId: await get(radarTemplateIdAtom),
    pitchOrientation: get(pitchOrientationAtom),
    pitchCrop: get(pitchCropAtom),
    showArrows: get(showArrowsAtom),
    isStartPlacement: get(isStartPlacementAtom),
    pitchMarkerColouringMode: get(pitchMarkerColouringModeAtom),
    orderBy: get(orderByAtom),
    orderDirection: get(orderDirectionAtom),
    barChartOrientation: get(barChartOrientationAtom),
  };
  return persistValues;
});
