import { activeRectAtom } from '@/atoms/vis/selection';
import { useDragEndSelectEvents } from '@/hooks/useDragEndSelectEvents';
import { useResetSelectionLayer } from '@/hooks/useResetSelectionLayer';
import { UnwrappedPitchEventsAtom } from '@/types/visualisation';
import { PitchSelectionLayer } from '@statsbomb/kitbag-datavis';
import { useAtom } from 'jotai';
import { ReactNode } from 'react';

export const EventMapSelectionLayer = ({
  unwrappedPitchEventsAtom,
  children,
}: {
  unwrappedPitchEventsAtom: UnwrappedPitchEventsAtom;
  children: ReactNode;
}) => {
  const resetSelectionLayer = useResetSelectionLayer();
  const [activeRect, setActiveRect] = useAtom(activeRectAtom);

  const onDragEnd = useDragEndSelectEvents(unwrappedPitchEventsAtom);

  return (
    <PitchSelectionLayer
      onDragStart={resetSelectionLayer}
      onDragEnd={onDragEnd}
      selectionLayerAttributes={activeRect}
      setSelectionLayerAttributes={setActiveRect}
    >
      {children}
    </PitchSelectionLayer>
  );
};
