import { playerMinutesPlayedAtom } from '@/atoms/queries/player/playerMinsPlayed';
import { barChartOrientationAtom } from '@/atoms/visualisation';
import { useMinutesPlayedData } from '@/hooks/minutesPlayed/useMinutesPlayedData';
import { useStatus } from '@/hooks/useStatus';
import { StatusTranslationKey } from '@/types/status';
import { ErrorBoundary } from '@sentry/react';
import { Card, CardBody } from '@statsbomb/kitbag-components';
import { BarChart, BarChartData } from '@statsbomb/kitbag-datavis';
import { useAtomValue } from 'jotai';
import { Suspense, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const PlayerMinutesPlayedBase = ({ data, status }: { data: BarChartData[]; status?: StatusTranslationKey }) => {
  const { t } = useTranslation('visualisation');
  const statusProps = useStatus('general', status);
  const scrollRef = useRef<HTMLDivElement>(null);
  const barChartOrientation = useAtomValue(barChartOrientationAtom);

  /* This is to scroll the barchart to the bottom initially as per nats request
  will only happen when the data set is large and the scroll bar is shown */
  useEffect(() => {
    /* istanbul ignore else */
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [scrollRef?.current, data.length]);

  return (
    <Card>
      <CardBody {...statusProps}>
        <div
          ref={scrollRef}
          /* heights here are to create a max so that if the barChart
            has too much data we overflow with a scroll */
          className="h-[300px] md:h-[500px] lg:h-[400px] overflow-y-auto content-center"
        >
          {/* hiding the chart completely when in loading or error state
            to not render any axis */}
          {!status && (
            <BarChart
              data={data}
              chartOrientation={barChartOrientation}
              barSize={18}
              bottomAxis={{
                label: t('gameTime'),
                notches: [0, 15, 30, 45, 60, 75, 90],
              }}
              leftAxis={{
                width: 80,
                format: 'date',
              }}
            />
          )}
        </div>
      </CardBody>
    </Card>
  );
};

const PlayerMinutesPlayedWithData = () => {
  const playerMinutesPlayed = useAtomValue(playerMinutesPlayedAtom);
  const data = useMinutesPlayedData(playerMinutesPlayed.data);
  return <PlayerMinutesPlayedBase data={data} />;
};

export const PlayerMinutesPlayed = () => (
  <ErrorBoundary fallback={<PlayerMinutesPlayedBase data={[]} status={StatusTranslationKey.ERROR} />}>
    <Suspense fallback={<PlayerMinutesPlayedBase data={[]} status={StatusTranslationKey.LOADING} />}>
      <PlayerMinutesPlayedWithData />
    </Suspense>
  </ErrorBoundary>
);
