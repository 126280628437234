import { UserConfigDashboardTemplateDefinition, UserConfigWidgetDefinition } from '@/types/userConfigs';
import { Entity } from '@/types/entity';

// get a list of all the widget configs that are referenced in any dashboard template of a given entity
export const getDashboardTemplateWidgetsByEntity = (
  dashboardTemplates: UserConfigDashboardTemplateDefinition[],
  dashboardWidgets: UserConfigWidgetDefinition[],
  entity: Entity,
) => {
  const entityDashboardWidgetIds = dashboardTemplates
    .filter(dashboardTemplate => dashboardTemplate.definition.entity === entity)
    .flatMap(entityDashboardTemplates => entityDashboardTemplates.definition.widgetIds);

  const entityDashboardWidgets = entityDashboardWidgetIds
    .map(widgetId => dashboardWidgets.find(widget => widget.configId === widgetId))
    .filter(Boolean);

  return entityDashboardWidgets as UserConfigWidgetDefinition[];
};
