import { NON_NAVIGABLE_WIDGETS } from '@/consts/widget';
import { EntityOrDataLocker } from '@/types/entity';
import { WidgetVisType } from '@/types/widget';
import { useTranslation } from 'react-i18next';

export const useWidgetNavigationPath = (
  entity: EntityOrDataLocker,
  entityId: number | null,
  vis: WidgetVisType,
  metricKey?: string,
  radarTemplateId?: number,
) => {
  const {
    i18n: { language },
  } = useTranslation();

  if (NON_NAVIGABLE_WIDGETS.includes(vis)) return '';

  const metricKeyParam = metricKey ? `&metricKey=${metricKey}` : '';

  const getVisNavigationPath = () => {
    if (vis === 'squad') return 'squad';
    if (vis === 'defensiveActivity') return `vis?vis=heatMap&metricKey=defensive_actions`;

    if (vis === 'radar') {
      const radarTemplateParam = radarTemplateId ? `?radarTemplate=${radarTemplateId}` : '';
      return `radar${radarTemplateParam}`;
    }

    return `vis?vis=${vis}`;
  };

  const getPageNavigationPath = () => {
    if (entity === 'dataLocker') {
      return `data-locker/events?displayMode=vis&vis=${vis}`;
    }

    return `${entity}/${entityId}/${getVisNavigationPath()}`;
  };

  return `/${language}/${getPageNavigationPath()}${metricKeyParam}`;
};
