import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSetAtom } from 'jotai';
import { widgetNameToDeleteAtom, isEditWidgetTitleModalOpenAtom } from '@/atoms/report/widget';
import { MenuItemWithIcon } from '../menu/MenuItemWithIcon';
import { MoreOptionsMenu } from '../menu/MoreOptionsMenu/MoreOptionsMenu';
import { DeleteWidgetModal } from '../modal/DeleteWidgetModal/DeleteWidgetModal';
import { EditWidgetTitleModal } from '../modal/EditWidgetTitleModal/EditWidgetTitleModal';

export const WidgetSettingsMenu = ({ widgetName }: { widgetName: string }) => {
  const { t } = useTranslation(['general', 'widget']);

  const setWidgetNameToDelete = useSetAtom(widgetNameToDeleteAtom);
  const setIsWidgetEditModalOpenAtom = useSetAtom(isEditWidgetTitleModalOpenAtom);

  return (
    <>
      <MoreOptionsMenu title={t('settings', { ns: 'widget' })} size="small">
        <MenuItemWithIcon onClick={() => setIsWidgetEditModalOpenAtom(true)} icon="Edit" text={t('edit')} />
        <MenuItemWithIcon onClick={() => setWidgetNameToDelete(widgetName)} icon="Delete" text={t('delete')} />
      </MoreOptionsMenu>
      <EditWidgetTitleModal />
      <DeleteWidgetModal />
    </>
  );
};
