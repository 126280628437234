import { Grid } from '@statsbomb/kitbag-components';
import { GRID_ITEM_STYLES, SCROLLABLE_GRID_ITEM_STYLES, SCROLLABLE_GRID_PARENT_STYLES } from '@/consts/grids';
import React, { Children } from 'react';
import { twJoin } from 'tailwind-merge';

export const WideFirstColumnLayout = ({ children }: { children: ReadonlyArray<React.ReactNode> & { length: 2 } }) => {
  const childrenArray = Children.toArray(children);
  const [columnOne, columnTwo] = childrenArray;

  return (
    <div className="grid-wrapper [&>div]:h-full [&>div>div]:pr-0 md:[&>div>div]:pr-2">
      <Grid>
        <Grid item xs={12} md={6} lg={4}>
          <div data-column="1" className={twJoin(SCROLLABLE_GRID_PARENT_STYLES, GRID_ITEM_STYLES, 'p-4')}>
            <div className={twJoin(SCROLLABLE_GRID_ITEM_STYLES, 'md:p-4 md:-my-4 md:left-0')}>{columnOne}</div>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <div data-column="2" className={twJoin(GRID_ITEM_STYLES, 'p-4')}>
            {columnTwo}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
