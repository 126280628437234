import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';
import { atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { atomWithDebounce } from '@/atoms/debounce';
import { pageAndOrderParamsAtom } from '@/atoms/general';
import { metricKeyAtom } from '@/atoms/metric';
import { teamIdAtom } from '@/atoms/team/team';
import { EVENT_MARKERS_LIMIT } from '@/consts/visualisations';
import { Nullable } from '@/types/generic';
import { NestedObject } from '@/types/object';
import { convertFilterParamsToString, gameEventParams, hasEmptyInFilters } from '@/utils/api';
import {
  convertEventDataIntoDescriptions,
  convertMarkerDataIntoMarkers,
  convertMarkerDataIntoDescriptions,
  convertMarkerDataToShotMapMarkers,
  processEventDataAtom,
} from '@/utils/atoms/eventData';
import { EventMarker, EventWithRels } from '@statsbomb/parachute-types';
import { teamMetricEventsUrl } from '@/query/url';
import { selectedGameIdForVideosAtom } from '@/atoms/video';
import { getVideoIdsFromEvents } from '@/utils/video';
import { createUnwrappedPitchEventsAtom } from '@/utils/atoms/vis';
import { fetchClientAtom } from '../client';

export const teamEventMetricFiltersDebounceObject = atomWithDebounce<Nullable<NestedObject>>(null);
export const { debouncedValueAtom: teamEventMetricFiltersAtom } = teamEventMetricFiltersDebounceObject;

const teamRawEventMarkersAtom = atomWithSuspenseQuery(get => {
  const queryKey = [
    'eventMarkersForMetric',
    get(metricKeyAtom),
    get(teamIdAtom),
    get(teamEventMetricFiltersAtom),
  ] as const;

  const queryFn = async ({ queryKey: [, metricKey, teamId, filterParams] }: { queryKey: typeof queryKey }) => {
    if (!teamId || !metricKey || hasEmptyInFilters(filterParams)) return [];
    const { fetch } = get(fetchClientAtom);
    return (await fetch(
      `/team/${teamId}/metric/${metricKey}/event-markers${convertFilterParamsToString({
        ...filterParams,
        limit: EVENT_MARKERS_LIMIT,
      })}`,
    )) as Promise<EventMarker[]>;
  };

  return { queryKey, queryFn };
});
export const teamEventMarkersForMetricAtom = atom(async get => {
  const teamEventMarkersForMetricAtom = await get(teamRawEventMarkersAtom);
  return convertMarkerDataIntoMarkers(teamEventMarkersForMetricAtom.data);
});

export const unwrappedTeamEventMarkersForMetricAtom = createUnwrappedPitchEventsAtom(teamEventMarkersForMetricAtom);

const teamRawEventsAtom = atomWithSuspenseQuery(get => {
  const queryKey = [
    'eventsForMetric',
    get(metricKeyAtom),
    get(teamIdAtom),
    get(teamEventMetricFiltersAtom),
    get(pageAndOrderParamsAtom),
  ] as const;

  const queryFn = async ({
    queryKey: [, metricKey, teamId, filterParams, pageAndOrderParams],
  }: {
    queryKey: typeof queryKey;
  }) => {
    if (!teamId || !metricKey || hasEmptyInFilters(filterParams)) return [];
    const { fetch } = get(fetchClientAtom);
    const url = teamMetricEventsUrl(teamId, metricKey, {
      ...pageAndOrderParams,
      ...filterParams,
    });
    if (!url) return [];
    return (await fetch(url)) as Promise<EventWithRels[]>;
  };

  return { queryKey, queryFn };
});
export const teamEventsForMetricAtom = atom(async get => processEventDataAtom(await get(teamRawEventsAtom)));

const teamRawEventsCountAtom = atomWithSuspenseQuery(get => {
  const queryKey = [
    'eventsForMetricCount',
    get(metricKeyAtom),
    get(teamIdAtom),
    get(teamEventMetricFiltersAtom),
  ] as const;

  const queryFn = async ({ queryKey: [, metricKey, teamId, filterParams] }: { queryKey: typeof queryKey }) => {
    if (!teamId || !metricKey || hasEmptyInFilters(filterParams)) return 0;
    const { fetch } = get(fetchClientAtom);
    return (await fetch(
      `/team/${teamId}/metric/${metricKey}/events/count${convertFilterParamsToString(filterParams)}`,
    )) as Promise<number>;
  };

  return { queryKey, queryFn };
});
export const teamEventsForMetricCountAtom = atom(async get => (await get(teamRawEventsCountAtom)).data);

const teamRawEventsForMetricInGameAtom = atomWithSuspenseQuery(get => {
  const queryKey = [
    'teamEventsForMetricInGame',
    get(metricKeyAtom),
    get(teamIdAtom),
    get(selectedGameIdForVideosAtom),
  ] as const;

  const queryFn = async ({ queryKey: [, metricKey, teamId, gameId] }: { queryKey: typeof queryKey }) => {
    const url = teamMetricEventsUrl(teamId, metricKey, gameEventParams(gameId));
    if (!url || !gameId) return [];
    const { fetch } = get(fetchClientAtom);
    return (await fetch(url)) as Promise<EventWithRels[]>;
  };

  return { queryKey, queryFn };
});

export const teamEventVideoIdsAtom = atom(async get => {
  const processedEventData = processEventDataAtom(await get(teamRawEventsForMetricInGameAtom));

  return getVideoIdsFromEvents(processedEventData);
});

export const teamEventMarkersDescriptionsAtom = atom(async get => {
  const teamEventMarkersForMetricAtom = await get(teamRawEventMarkersAtom);
  return convertMarkerDataIntoDescriptions(teamEventMarkersForMetricAtom.data);
});

export const teamGameEventDescriptionsAtom = atom(async get => {
  const teamRawEventsForMetricInGame = await get(teamRawEventsForMetricInGameAtom);
  return convertEventDataIntoDescriptions(teamRawEventsForMetricInGame.data);
});

const rawTeamShotMarkersAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['teamShotMarkers', get(teamIdAtom), get(teamEventMetricFiltersAtom)] as const;

  const queryFn = async ({ queryKey: [, teamId, filterParams] }: { queryKey: typeof queryKey }) => {
    if (!teamId || hasEmptyInFilters(filterParams)) return [];
    const { fetch } = get(fetchClientAtom);
    return (await fetch(
      `/event-markers${convertFilterParamsToString({
        ...filterParams,
        limit: EVENT_MARKERS_LIMIT,
      })}&eq[event.team_id]=${teamId}&in[event.type]=shot␞own_goal_for&neq[event.period]=5`,
    )) as Promise<EventMarker[]>;
  };

  return { queryKey, queryFn };
});

export const teamShotMarkersAtom = atom(async get => {
  const teamShotMarkers = await get(rawTeamShotMarkersAtom);
  return convertMarkerDataToShotMapMarkers(teamShotMarkers.data);
});

export const unwrappedTeamShotMarkersAtom = unwrap(teamShotMarkersAtom, prev => prev || []);
