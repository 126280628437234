import { unwrappedSelectedPlayerNameAtom } from '@/atoms/player/info';
import { PageTitle } from '@/components/head/PageTitle';
import { PlayerLayoutWithFilters } from '@/components/layout/player/PlayerLayoutWithFilters';
import { PlayerRadar } from '@/components/player/radar/PlayerRadar';
import { useEntityPageTitle } from '@/hooks/useEntityPageTitle';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

export const PlayerRadarPage = () => {
  const { t } = useTranslation('navigation');
  const playerName = useAtomValue(unwrappedSelectedPlayerNameAtom);
  const pageTitle = useEntityPageTitle(t('secondary.radar'), playerName);

  return (
    <>
      <PageTitle>{pageTitle}</PageTitle>
      <PlayerLayoutWithFilters>
        <PlayerRadar />
      </PlayerLayoutWithFilters>
    </>
  );
};
