import { eventDataFilterParamsDebounceObject } from '@/atoms/queries/eventData';
import { playerEventMetricFiltersDebounceObject } from '@/atoms/queries/player/playerEventData';
import { playerRadarFilterDebounceObject } from '@/atoms/queries/player/playerRadar';
import { playerPositionsFilterDebounceObject } from '@/atoms/queries/players';
import { teamEventMetricFiltersDebounceObject } from '@/atoms/queries/team/teamEventData';
import { teamRadarFilterDebounceObject } from '@/atoms/queries/team/teamRadar';
import { layoutScopeAtoms, reportWidgetAtoms } from '@/consts/scope';
import { ScopeProvider } from 'jotai-scope';
import { ReactNode } from 'react';

export const ReportWidgetScopeProvider = ({ children }: { children: ReactNode }) => (
  <ScopeProvider
    atoms={[
      ...layoutScopeAtoms,
      ...reportWidgetAtoms,

      // api filter params (these things are objects, but we have to scope all the values in the object to ensure each
      // debounce timeout is scoped to each widget)
      ...Object.values(eventDataFilterParamsDebounceObject),
      ...Object.values(playerEventMetricFiltersDebounceObject),
      ...Object.values(playerRadarFilterDebounceObject),
      ...Object.values(playerPositionsFilterDebounceObject),
      ...Object.values(teamRadarFilterDebounceObject),
      ...Object.values(teamEventMetricFiltersDebounceObject),
    ]}
  >
    {children}
  </ScopeProvider>
);
