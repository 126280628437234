import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@statsbomb/kitbag-components';
import { Search, SearchResult } from '@statsbomb/parachute-types';
import { useTranslation } from 'react-i18next';
import { FlagIcon } from '../icons/FlagIcon';

const SearchItem = ({ item, onClick }: { item: SearchResult; onClick: () => void }) => {
  const {
    i18n: { language },
  } = useTranslation('gender');
  const { id, type, name, nickname, code, age } = item;
  const displayName = nickname ? `${nickname}, (${name})` : name;
  const nameWithAge = age ? `${displayName}, ${age}` : displayName;
  const url = `/${language}/${type}/${id}/dashboard`;

  return (
    <Link
      to={url}
      onClick={onClick}
      className="flex items-center justify-between w-full gap-2 px-4 py-2 bg-canvas-tertiary-strong hover:bg-canvas-tertiary-weak"
      data-testid={`${type}-${id}`}
    >
      <Typography variant="bodyMedium" title={nameWithAge} className="flex-1 truncate">
        {nameWithAge}
      </Typography>
      <div className="flex items-center gap-2">
        <FlagIcon countryCode={code} />
      </div>
    </Link>
  );
};

export const SearchResults = ({ searchResults, onClick }: { searchResults: Search; onClick: () => void }) => {
  const { t } = useTranslation('entity');
  return (
    <div className="absolute right-0 bg-canvas-tertiary-main md:min-w-96 md:!w-96 md:shadow-sb-regular md:mt-2 z-10 w-full">
      {Object.entries(searchResults).map(([key, results]) => (
        <React.Fragment key={key}>
          <Typography variant="headingMedium" data-testid={`${key}-results`} className="px-4 py-4">
            {t(`${key}.other`)} ({results.length})
          </Typography>
          {results.map(result => (
            <SearchItem key={`${result.type}-${result.id}`} item={result} onClick={onClick} />
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};
