import { pitchMarkerColouringModeAtom } from '@/atoms/visualisation';
import { RadioButton, RadioGroup } from '@statsbomb/kitbag-components';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

export const PitchMarkerColouring = () => {
  const { t } = useTranslation('visualisation');
  const [pitchMarkerColouringMode, setPitchMarkerColouringMode] = useAtom(pitchMarkerColouringModeAtom);

  return (
    <RadioGroup groupId="pitch-marker-colouring" groupTitle={t('markerColouring.title')} size="regular">
      <RadioButton
        checked={pitchMarkerColouringMode === 'default'}
        onChange={() => setPitchMarkerColouringMode('default')}
        id="marker-colours-default-option"
        label={t('markerColouring.default')}
        value="false"
        size="small"
      />
      <RadioButton
        checked={pitchMarkerColouringMode === 'passSuccess'}
        onChange={() => setPitchMarkerColouringMode('passSuccess')}
        id="marker-colours-pass-success-option"
        label={t('markerColouring.passSuccess')}
        value="false"
        size="small"
      />
      <RadioButton
        checked={pitchMarkerColouringMode === 'xg'}
        onChange={() => setPitchMarkerColouringMode('xg')}
        id="marker-colours-xg-option"
        label={t('markerColouring.xg')}
        value="false"
        size="small"
      />
    </RadioGroup>
  );
};
