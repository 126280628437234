import { useStatus } from '@/hooks/useStatus';
import { StatusTranslationKey } from '@/types/status';
import { CardBody } from '@statsbomb/kitbag-components';
import { ReactNode } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { CardHeightWrapper } from '../card/CardHeightWrapper';

interface ErrorCardProps {
  translationNameSpace: string;
  children: ReactNode;
  fallbackChildren?: ReactNode;
}

export const ErrorCardBody = ({ translationNameSpace, children, fallbackChildren }: ErrorCardProps) => {
  const status = useStatus(translationNameSpace, StatusTranslationKey.ERROR);
  return <ErrorBoundary fallback={<CardBody {...status}>{fallbackChildren}</CardBody>}>{children}</ErrorBoundary>;
};

export const ErrorCard = ({ translationNameSpace, children, fallbackChildren }: ErrorCardProps) => {
  const status = useStatus(translationNameSpace, StatusTranslationKey.ERROR);
  return (
    <ErrorBoundary
      fallback={
        <CardHeightWrapper>
          <CardBody {...status}>{fallbackChildren}</CardBody>
        </CardHeightWrapper>
      }
    >
      {children}
    </ErrorBoundary>
  );
};
