import { userConfigsAtom } from '@/atoms/queries/userConfigs';
import { userConfigIdToRenameAtom } from '@/atoms/userConfigs';
import { useAtomValue, useSetAtom } from 'jotai';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItemWithIcon } from '../MenuItemWithIcon';
import { MoreOptionsMenu, MoreOptionsMenuFallback } from '../MoreOptionsMenu/MoreOptionsMenu';

const RenameUserConfigMenuItem = ({ configId, onClick }: { configId: string; onClick: (name: string) => void }) => {
  const { t } = useTranslation('general');
  const userConfigs = useAtomValue(userConfigsAtom);

  return (
    <MenuItemWithIcon
      onClick={() => {
        const userConfigName =
          userConfigs.data.find(userConfig => userConfig.configId === configId)?.definition.name || '';

        onClick(userConfigName);
      }}
      icon="Edit"
      text={t('rename')}
    />
  );
};

const DeleteUserConfigMenuItem = ({
  configId,
  onClick,
}: {
  configId: string;
  onClick: (id: string, name: string) => void;
}) => {
  const { t } = useTranslation('general');
  const userConfigs = useAtomValue(userConfigsAtom);

  return (
    <MenuItemWithIcon
      onClick={() => {
        const userConfigNameToDelete =
          userConfigs.data.find(userConfig => userConfig.configId === configId)?.definition.name || t('untitled');

        onClick(configId, userConfigNameToDelete);
      }}
      icon="Delete"
      text={t('delete')}
    />
  );
};

export interface UserConfigMenuProps {
  configId: string;
  onRenameClick: (name: string) => void;
  onDeleteClick: (id: string, name: string) => void;
}

export const UserConfigMenu = ({ configId, onRenameClick, onDeleteClick }: UserConfigMenuProps) => {
  const setUserConfigIdToRename = useSetAtom(userConfigIdToRenameAtom);
  const { t } = useTranslation('userConfig');

  return (
    <Suspense fallback={<MoreOptionsMenuFallback />}>
      <MoreOptionsMenu title={t('menuTitle')}>
        {/* suspense here as the options use async atoms */}
        <Suspense>
          <RenameUserConfigMenuItem
            configId={configId}
            onClick={(name: string) => {
              onRenameClick(name);

              setUserConfigIdToRename(configId);
            }}
          />
          <DeleteUserConfigMenuItem configId={configId} onClick={onDeleteClick} />
        </Suspense>
      </MoreOptionsMenu>
    </Suspense>
  );
};
