import { AGGS_TABLE_CONFIG, MINUTES_PLAYED_COLUMN } from '@/consts/dataLocker/table';
import { useSortTable } from '@/hooks/useSortTable';
import { NestedObject } from '@/types/object';
import { DataTableColumn } from '@/types/table';
import { getMetricKeyFromColumnKey } from '@/utils/metrics';
import {
  getFormatRule,
  alignTableCell,
  isLowerBetter,
  getHighlightedColumnIndex,
  getValidColumnKey,
} from '@/utils/table';
import { getTranslationColumns } from '@/utils/translations';
import { Table } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Entity } from '@/types/entity';
import { StatusTranslationKey } from '@/types/status';
import { CellValueToDisplay } from './CellValueToDisplay';
import { TableWrapper } from './TableWrapper';
import { ContentState } from '../contentState/ContentState';

export const AggsTable = ({
  data = [],
  availableColumns,
  isLoading = false,
  visibleColumns = [],
  entity,
  hasRetrievedAllAggs = false,
}: {
  data?: NestedObject[];
  availableColumns?: DataTableColumn[];
  isLoading?: boolean;
  visibleColumns?: string[];
  entity: Entity;
  hasRetrievedAllAggs?: boolean;
}) => {
  const { t } = useTranslation('events');
  const { orderBy, handleSort, getSortedState } = useSortTable(hasRetrievedAllAggs);
  const [searchParams] = useSearchParams();
  const { param, entityKey } = AGGS_TABLE_CONFIG[entity];
  const entityId = searchParams.get(param);

  const visibleColumnsSortKeys = visibleColumns.map(key => getValidColumnKey(key));
  const sortedColumnIndex = orderBy ? visibleColumnsSortKeys.findIndex(key => key === orderBy) : -1;
  const highlightedColumnIndex = getHighlightedColumnIndex(sortedColumnIndex, false);

  const selectedRow = () => {
    const rowIndex = data.findIndex(obj => String(obj[entityKey]) === entityId);
    return [rowIndex + 1];
  };

  const hasData = data.length > 0;

  return (
    <TableWrapper>
      <div className={hasData ? 'overflow-x-auto' : 'overflow-x-hidden'}>
        <Table isHeadSticky withBorder={false} highlightedColumn={highlightedColumnIndex} selectedRows={selectedRow()}>
          <Table.Head>
            <Table.Row>
              {visibleColumnsSortKeys.map((key, index) => {
                const { translationKey, translationNs } = getTranslationColumns(key);
                const formatRule = getFormatRule(key, availableColumns);

                return (
                  <Table.HeaderCell
                    key={key}
                    textAlign={alignTableCell(formatRule)}
                    sortCb={() => handleSort(key, isLowerBetter(formatRule))}
                    sortState={getSortedState(key)}
                    size="regular"
                  >
                    <span data-testid={`column-${index}`}>{t(translationKey, { ns: translationNs })}</span>
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          </Table.Head>
          {hasData && (
            <Table.Body>
              {data.map(row => (
                <Table.Row key={String(row[entityKey])}>
                  {visibleColumns.map(key => {
                    const formatRule = getFormatRule(key, availableColumns);
                    const path =
                      key.startsWith('aggregates.') && key !== MINUTES_PLAYED_COLUMN
                        ? `metricKey=${getMetricKeyFromColumnKey(key)}&${param}=${String(row[entityKey])}`
                        : undefined;

                    return (
                      <CellValueToDisplay
                        key={key}
                        value={row[key]}
                        columnRule={formatRule}
                        eventKey={key}
                        previewLinkParams={path}
                      />
                    );
                  })}
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>
      </div>
      {!isLoading && !hasData && <ContentState status={StatusTranslationKey.NO_DATA} />}
      {isLoading && <ContentState status={StatusTranslationKey.LOADING} />}
    </TableWrapper>
  );
};
