import { EventDescription } from '@/types/event';
import { Nullable } from '@/types/generic';
import { SelectedVideoIds } from '@/types/video';
import { formatGameTimestamp } from '@/utils/games';
import { formatPlayerName } from '@/utils/player';
import { snakeToCamel } from '@/utils/queries';
import { usePlaylistCurrentlySelected } from '@statsbomb/video-client-js';
import { useTranslation } from 'react-i18next';

export const useFormatVideoEvents = (
  selectedVideoIds: Nullable<SelectedVideoIds[]>,
  descriptionsData: EventDescription[],
) => {
  const { t } = useTranslation('events');
  const [currentlySelectedVideo] = usePlaylistCurrentlySelected();

  return selectedVideoIds?.map(({ eventId, videoEventId }) => {
    const eventData = descriptionsData.find(event => event.eventId === eventId);

    const coreVideoData = {
      id: videoEventId,
      selectedEntry: videoEventId === currentlySelectedVideo?.id,
    };

    if (!eventData) return coreVideoData;

    const playerName = formatPlayerName(eventData.playerName, eventData.playerNickname);
    const eventType = t(`fields.event.type.${snakeToCamel(eventData.type)}`);
    const timestamp = formatGameTimestamp(eventData.minute, eventData.second);
    const { gameName, competitionName, gameDate } = eventData;

    return {
      ...coreVideoData,
      description: `${playerName} - ${eventType}`,
      descriptionLineTwo: `${timestamp} - ${gameName}`,
      descriptionLineThree: `${competitionName} - ${gameDate}`,
    };
  });
};
