import { FlagIcon } from '@/components/icons/FlagIcon';
import { BaseOption } from '@/types/generic';
import { Typography } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';

export const FILTER_DISPLAY_LIMIT = 3;

const Flag = ({ optional }: { optional: {} | undefined }) => {
  if (!optional) return null;
  const { countryCode } = optional as { countryCode: string };
  return <FlagIcon countryCode={countryCode} />;
};

const ListFilters = <T,>({
  filtersToDisplay,
  showFlags,
}: {
  filtersToDisplay: BaseOption<T>[];
  showFlags?: boolean;
}) => {
  const { t } = useTranslation('general');
  const showExtraCount = filtersToDisplay.length > FILTER_DISPLAY_LIMIT;
  const extraCount = t('plusMore', { count: filtersToDisplay.length - FILTER_DISPLAY_LIMIT });

  if (!filtersToDisplay.length) return null;

  return (
    <div>
      {!showFlags ? (
        <Typography variant="bodyRegular" as="span">
          {filtersToDisplay
            .slice(0, FILTER_DISPLAY_LIMIT)
            .map(({ label }) => label)
            .join(', ')}
          {showExtraCount && ` ${extraCount}`}
        </Typography>
      ) : (
        <div className="flex gap-x-2 flex-wrap">
          {filtersToDisplay.slice(0, FILTER_DISPLAY_LIMIT).map(({ label, value, optional }) => (
            <div className="flex gap-x-2 items-center" key={`${label}-${value}`}>
              <Flag optional={optional} />
              <Typography variant="bodyRegular" as="div">
                {label}
              </Typography>
            </div>
          ))}
          {showExtraCount && (
            <Typography className="flex items-center" variant="bodyRegular" as="span">
              {` ${extraCount}`}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};

export const RadarFilterSummaryItem = <T,>({
  label,
  options,
  selectedOptions,
  allSummaryText,
  showFlags,
}: {
  label: string;
  options: BaseOption<T>[];
  selectedOptions: BaseOption<T>[];
  allSummaryText?: string;
  showFlags?: boolean;
}) => {
  const defaultOptions = allSummaryText ? [] : options;
  const filtersToDisplay = selectedOptions.length ? selectedOptions : defaultOptions;

  return (
    // TODO (KC-360: Replace with Card component once available)
    <div className="bg-canvas-secondary-main px-4 py-2">
      <Typography variant="headingMedium" as="h2" className="pb-1">
        {label}
      </Typography>
      {(filtersToDisplay.length === options.length || filtersToDisplay.length === 0) && allSummaryText ? (
        <Typography variant="bodyRegular" as="span">
          {allSummaryText}
        </Typography>
      ) : (
        <ListFilters filtersToDisplay={filtersToDisplay} showFlags={showFlags} />
      )}
    </div>
  );
};
