import { selectedVideoIdsAtom } from '@/atoms/video';
import { useFormatVideoEvents } from '@/hooks/useFormatVideoEvents';
import { Typography } from '@statsbomb/kitbag-components';
import { Playlist, VideoEvent } from '@statsbomb/video-client-js';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { markersDescriptionsAtom } from '@/atoms/eventMarkers';
import { VideoProvider } from '../Providers';

const VideoPlayerBase = () => {
  const selectedVideoIds = useAtomValue(selectedVideoIdsAtom);
  const videoEventMarkersDescriptions = useAtomValue(markersDescriptionsAtom);
  const videoEvents = useFormatVideoEvents(selectedVideoIds, videoEventMarkersDescriptions);

  return (
    <div className="p-4">
      <VideoProvider>
        <VideoEvent autoPlay events={videoEvents || []} />
        <div className="max-h-[300px] md:max-h-[700px] overflow-auto">
          <Playlist />
        </div>
      </VideoProvider>
    </div>
  );
};

export const VideoPlayer = () => {
  const { t } = useTranslation('video');

  return (
    <Suspense
      fallback={
        <Typography variant="bodyMedium" className="p-4">
          {t('loadingVideo')}
        </Typography>
      }
    >
      <VideoPlayerBase />
    </Suspense>
  );
};
