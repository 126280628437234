import { ALL_ITEMS } from '@/consts/interface';
import { BaseOption, Nullable, Option } from '@/types/generic';
import { SORT_DIRECTION } from '@/types/ordering';
import { PaginationAndOrderParams } from '@/types/pagination';
import i18next from 'i18next';
import { get, isArray } from 'lodash/fp';
import { convertToDropdownOption } from './object';
import { snakeToCamel } from './queries';

export const sortAscArrayOptions = <T>(array: BaseOption<T>[]) =>
  array.sort((a, b) => {
    const aLabel = a.label;
    const bLabel = b.label;

    if (aLabel < bLabel) {
      return -1;
    }

    return aLabel > bLabel ? 1 : 0;
  });

export const convertOptionsToValues = <T>(array: BaseOption<T>[]) => array.map(({ value }) => value);
export const convertOptionsToLabels = <T>(array: BaseOption<T>[]) => array.map(({ label }) => label);

export const convertOptionsToValueArrayOrNull = (array: Option[]) =>
  array.length === 0 ? null : convertOptionsToValues(array);

export const convertFiltersToOption = <T>(array: T[] = []) => array.map(item => convertToDropdownOption<T>('', item));

export const findById = <T extends { [key in K]?: number }, K extends string>(
  items: T[],
  id: number,
  idField: K,
): T | undefined => items.find(item => item[idField] === id);

// checks whether 2 array of number ids share any values
export const isSharingId = (ids?: number[], selectedIds?: number[]) =>
  !!ids && !!selectedIds && ids.some(id => selectedIds.includes(id));

// filters out any falsy values and join what's left into a single file name
export const formatFileName = (fileInfo: (string | undefined)[]) => fileInfo.filter(Boolean).join(' - ');

// adds or removes an item in an array, depending on whether it's in it or not
export const toggleValue = <T>(value: T, items: T[]) => {
  const isIncluded = items.includes(value);
  return isIncluded ? items.filter(item => item !== value) : [...items, value];
};

const compareFields = <T>(field1: T, field2: T, language: string) => {
  if (typeof field1 === 'string' && typeof field2 === 'string')
    return field1.localeCompare(field2, language, { sensitivity: 'base' });
  return field1 > field2 ? 1 : -1;
};

export const sortObjectArray = <T extends Record<string, any>>(
  orderByField: Nullable<string>,
  array: T[],
  language: string,
  sortDirection = SORT_DIRECTION.ASC,
) =>
  orderByField
    ? [...array].sort((a, b) => {
        const aField = get(orderByField, a);
        const bField = get(orderByField, b);

        if (aField == null && bField == null) return 0;
        if (aField == null) return 1;
        if (bField == null) return -1;

        return sortDirection === SORT_DIRECTION.ASC
          ? compareFields(aField, bField, language)
          : compareFields(bField, aField, language);
      })
    : array;

export const sortFetchResultObjectArray = <T extends Record<string, any>>(
  array: T[],
  { order_by: orderByObj }: Omit<PaginationAndOrderParams, 'limit' | 'offset'>,
) => {
  const orderByEntries = Object.entries(orderByObj);
  if (!orderByEntries.length) {
    return array;
  }
  const [[orderByFieldSnake, sortDirection]] = orderByEntries;
  const orderByField = snakeToCamel(orderByFieldSnake);
  return sortObjectArray(
    orderByField,
    array,
    i18next.language,
    sortDirection.startsWith('ASC') ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC,
  );
};

export const isEmptyArray = (data: unknown) => isArray(data) && !data.length;

export const limitArray = <T>(arr: T[], { offset, limit }: Omit<PaginationAndOrderParams, 'order_by'>) =>
  limit === ALL_ITEMS ? arr : arr.slice(offset, offset + limit);
