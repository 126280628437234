import { useTranslation } from 'react-i18next';
import { TEAM_RADAR_TEMPLATES } from '@/consts/radar';
import { RadarCard } from '@/components/radar/RadarCard';
import { OverviewHeader } from '@/components/section/OverviewHeader';
import { RadarExportModal } from '@/components/radar/RadarExportModal';
import { VisContentsContainer } from '@/components/layout/VisContentsContainer';
import { TeamRadarChart } from './TeamRadarChart';
import { TeamRadarTableGroup, TeamRadarTableSummary } from './TeamRadarTableGroup';

export const TeamRadar = () => {
  const { t } = useTranslation(['radar', 'entity']);
  const label = t('dropdownLabel', { entity: t('team.one', { ns: 'entity' }) });

  return (
    <>
      <div className="flex flex-col gap-2 min-h-full">
        <OverviewHeader />
        <VisContentsContainer>
          <RadarCard templates={TEAM_RADAR_TEMPLATES} label={label}>
            <TeamRadarChart id="main" />
          </RadarCard>
          <TeamRadarTableGroup />
        </VisContentsContainer>
      </div>

      <RadarExportModal>
        <TeamRadarChart id="export" isInteractive={false} />
        <TeamRadarTableSummary />
      </RadarExportModal>
    </>
  );
};
