import React from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Icon, ScreenReaderOnly, Typography } from '@statsbomb/kitbag-components';
import { isWidgetFiltersModalOpenAtom } from '@/atoms/report/widget';
import { unwrappedSelectedTeamInfoAtom } from '@/atoms/queries/teams';
import { WidgetSettingsMenu } from './WidgetSettingsMenu';
import { GenderIcon } from '../icons/GenderIcon';

interface WidgetProps {
  children: React.ReactNode;
  title: string;
  description: string;
  navigationPath: string;
  onClickTitle: () => void;
  filtersCount?: number;
}

export const ReportWidget = ({
  title,
  children,
  description,
  navigationPath,
  onClickTitle,
  filtersCount = 0,
}: WidgetProps) => {
  const { t } = useTranslation(['widget', 'general', 'gender']);
  const setIsWidgetFiltersModalOpenAtom = useSetAtom(isWidgetFiltersModalOpenAtom);
  const team = useAtomValue(unwrappedSelectedTeamInfoAtom);

  return (
    <article className="h-[520px] flex flex-col border border-canvas-secondary-main bg-canvas-tertiary-main relative [&:not(:hover)_.heading]:opacity-sb-secondary">
      <Link to={navigationPath} onClick={onClickTitle} className="top-0 left-0 absolute w-full h-full">
        <ScreenReaderOnly as="span">{title}</ScreenReaderOnly>
      </Link>
      <header className="p-4 border-b border-canvas-secondary-main">
        <div className="flex items-center justify-between gap-2">
          <div className="flex flex-1 gap-1 overflow-hidden underline pointer-events-none">
            <Typography variant="headingRegular" as="h3" className="heading truncate underline" title={title}>
              {title}
            </Typography>
            {team && <GenderIcon gender={team.gender} />}
          </div>
          <div className="flex items-center gap-2">
            {filtersCount > 0 && (
              <Button onClick={() => setIsWidgetFiltersModalOpenAtom(true)} size="small" variant="ghost">
                <Icon variant="Filter" size="small" />
                <Typography variant="headingSmall" as="span">{`${filtersCount} ${t(
                  `filters.${filtersCount > 1 ? 'other' : 'one'}`,
                  {
                    ns: 'general',
                  },
                )}`}</Typography>
              </Button>
            )}
            <WidgetSettingsMenu widgetName={title} />
          </div>
        </div>
        <Typography
          variant="bodyMedium"
          as="p"
          className="opacity-sb-secondary truncate pointer-events-none"
          title={description}
        >
          {description}
        </Typography>
      </header>
      <div className="flex-1 min-h-0 pointer-events-none">{children}</div>
    </article>
  );
};
