import { FunctionComponent, useEffect } from 'react';
import { useSetAtom } from 'jotai';
import { VisDisplayMode } from '@/types/visualisation';
import { ErrorBoundary } from '@sentry/react';
import { useVisDisplayMode } from '@/hooks/useVisDisplayMode';
import { isSettingsPanelOpenAtom } from '@/atoms/sidebar';
import { GeneralErrorBoundaryFallback } from '../errorBoundary/ErrorBoundaryFallback';
import { VisContentsContainer } from '../layout/VisContentsContainer';

export const VisDisplaySection = ({
  EventsTableComponent,
  visualisationComponent,
}: {
  EventsTableComponent: FunctionComponent;
  visualisationComponent: JSX.Element;
}) => {
  const [visDisplayMode] = useVisDisplayMode();
  const setIsSettingsPanelOpen = useSetAtom(isSettingsPanelOpenAtom);

  const isDisplayBoth = visDisplayMode === VisDisplayMode.BOTH;
  const isDisplayTable = visDisplayMode === VisDisplayMode.TABLE;
  const isDisplayVis = visDisplayMode === VisDisplayMode.VIS;

  useEffect(() => {
    if (isDisplayTable) {
      setIsSettingsPanelOpen(false);
    }
  }, [isDisplayTable]);

  return (
    <ErrorBoundary fallback={<GeneralErrorBoundaryFallback />}>
      <VisContentsContainer>
        {(isDisplayVis || isDisplayBoth) && (
          <div className="flex-1 min-w-0 bg-canvas-tertiary-main">{visualisationComponent}</div>
        )}
        {(isDisplayTable || isDisplayBoth) && (
          <div className="flex-1 min-w-0 bg-canvas-tertiary-main">
            <EventsTableComponent />
          </div>
        )}
      </VisContentsContainer>
    </ErrorBoundary>
  );
};
