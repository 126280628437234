import { NestedObject } from '@/types/object';
import { TFunction } from 'i18next';
import { translateHyphenatedValue, translateValue } from '@/utils/table';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '@/consts/date';
import { UuidButton } from '@/components/button/UuidButton';
import { isLinkObject, isLinkType } from '@/types/generic';
import { TableLink } from '@/components/tables/TableLink';
import { Icon } from '@statsbomb/kitbag-components';
import { useDecimalPlaces } from './useDecimalPlaces';

export const useCellValue = (
  value: NestedObject[string],
  columnRule: string | undefined,
  eventKey: string,
  t?: TFunction,
) => {
  const formatNumberDecimals = useDecimalPlaces();
  const wholeNumberFormat = (value: number) => formatNumberDecimals(0, value);
  const twoDecimalsNumberFormat = (value: number) => formatNumberDecimals(2, value);

  switch (columnRule) {
    case 'string':
      return value || null;
    case 'string-translate':
      return translateValue(eventKey, value, t);
    case 'string-hyphen':
      return translateHyphenatedValue(eventKey, value, t);
    case 'integer':
      return typeof value === 'number' ? wholeNumberFormat(value) : null;
    case 'percentage':
      return typeof value === 'number' ? wholeNumberFormat(value * 100) : null;
    case 'float-2':
      return typeof value === 'number' ? twoDecimalsNumberFormat(value) : null;
    case 'timestamptz':
      return dayjs(String(value)).isValid() ? dayjs(String(value)).format(`${DATE_FORMAT} HH:mm:ss`) : null;
    case 'duration-m':
    case 'duration-s':
      return dayjs(Number(value)).isValid() ? dayjs(Number(value)).format('mm:ss:SSS') : null;
    case 'boolean':
      return value ? (
        <span className="flex justify-center" data-testid="formatted-boolean-icon">
          <Icon variant="TickDone" size="small" />
        </span>
      ) : null;
    case 'object':
    case 'array':
    case 'UUID':
      return <UuidButton />;
    case 'link':
      return isLinkObject(value) && isLinkType(eventKey) ? (
        <TableLink {...{ id: value.id, label: value.label, type: eventKey }} />
      ) : null;
    default:
      return value?.toString() || '';
  }
};
