import { tableColumnGroupsAtom } from '@/atoms/queries/tableColumns';
import { DefaultColumnsAtom, VisibleColumnsAtom } from '@/types/tableColumns';
import { ButtonIcon } from '@statsbomb/kitbag-components';
import { useAtomValue } from 'jotai';
import { Dispatch, SetStateAction, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditTableColumnsModal } from '../modal/EditTableColumnsModal/EditTableColumnsModal';

const EditColumnsButtonBase = ({
  disabled,
  visibleColumnsCount,
  setShowEditColumnsModal,
}: {
  disabled?: boolean;
  visibleColumnsCount?: number;
  setShowEditColumnsModal?: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation(['table']);

  return (
    <ButtonIcon
      size="small"
      icon="Visibility"
      displayText="left"
      variant="ghost"
      onClick={() => setShowEditColumnsModal && setShowEditColumnsModal(true)}
      disabled={disabled}
    >
      {visibleColumnsCount ? t('editColumnsWithCount', { count: visibleColumnsCount }) : t('editColumns')}
    </ButtonIcon>
  );
};

const EditColumnsButtonWithData = ({
  visibleColumnsAtom,
  defaultColumnsAtom,
}: {
  visibleColumnsAtom: VisibleColumnsAtom;
  defaultColumnsAtom: DefaultColumnsAtom;
}) => {
  const [showEditColumnsModal, setShowEditColumnsModal] = useState(false);
  const visibleColumnsCount = useAtomValue(visibleColumnsAtom).length;
  // Referencing these atoms so they're loaded at the point we show the modal
  useAtomValue(defaultColumnsAtom);
  useAtomValue(tableColumnGroupsAtom);

  return (
    <>
      <EditColumnsButtonBase
        visibleColumnsCount={visibleColumnsCount}
        setShowEditColumnsModal={setShowEditColumnsModal}
      />
      {showEditColumnsModal && (
        <EditTableColumnsModal
          setShowEditColumnsModal={setShowEditColumnsModal}
          visibleColumnsAtom={visibleColumnsAtom}
          defaultColumnsAtom={defaultColumnsAtom}
        />
      )}
    </>
  );
};

export const EditColumnsButton = ({
  visibleColumnsAtom,
  defaultColumnsAtom,
}: {
  visibleColumnsAtom: VisibleColumnsAtom;
  defaultColumnsAtom: DefaultColumnsAtom;
}) => (
  <Suspense fallback={<EditColumnsButtonBase disabled />}>
    <EditColumnsButtonWithData visibleColumnsAtom={visibleColumnsAtom} defaultColumnsAtom={defaultColumnsAtom} />
  </Suspense>
);
