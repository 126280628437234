import { Grid } from '@statsbomb/kitbag-components';
import { UserConfigWidgetDefinition } from '@/types/userConfigs';
import { entityVisualisationComponents } from '@/consts/widget';
import { EntityOrDataLocker } from '@/types/entity';
import { WidgetVisType } from '@/types/widget';
import { DashboardWidgetWrapper } from '../widget/DashboardWidgetWrapper';

const isValidWidget = (entity: EntityOrDataLocker, vis: WidgetVisType) => !!entityVisualisationComponents[entity][vis];

export const DashboardWidgets = ({
  entityId,
  widgets,
}: {
  entityId: number;
  widgets: UserConfigWidgetDefinition[];
}) => {
  const filteredWidgets = widgets.filter(({ definition }) => isValidWidget(definition.entity, definition.vis));

  return (
    <Grid>
      {filteredWidgets.map(({ configId, definition }) => (
        <Grid testId={configId} item md={12} lg={6} xl={definition.vis === 'info' ? 8 : 4} key={configId}>
          <DashboardWidgetWrapper key={configId} {...definition} widgetId={configId} entityId={entityId} />
        </Grid>
      ))}
    </Grid>
  );
};
