import { NestedObject } from '@/types/object';
import { alignTableCell } from '@/utils/table';
import { Typography } from '@statsbomb/kitbag-components';
import { TFunction } from 'i18next';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useCellValue } from '@/hooks/useCellValue';
import { TableCell } from './TableCell';

export const CellValueToDisplay = ({
  value,
  columnRule,
  eventKey,
  previewLinkParams,
  children,
  t,
}: {
  value: NestedObject[string];
  columnRule: string | undefined;
  eventKey: string;
  previewLinkParams?: string;
  children?: ReactNode;
  t?: TFunction;
}) => {
  const cellAlignment = alignTableCell(columnRule);
  const cellValue = useCellValue(value, columnRule, eventKey, t);

  const cellValueToRender =
    previewLinkParams && cellValue !== null ? (
      <Typography
        as={Link}
        variant="linkMedium"
        // TODO (KC-202: Highlighted columns can't be clicked - remove the z-index below once this has been resolved)
        className="relative z-10"
        to={`./preview?${previewLinkParams}`}
      >
        {cellValue}
      </Typography>
    ) : (
      (cellValue as string | JSX.Element)
    );

  const returnValue = children ? (
    <span className="inline-flex w-max items-center gap-1">
      {children}
      {cellValueToRender}
    </span>
  ) : (
    cellValueToRender
  );

  return <TableCell textAlign={cellAlignment}>{returnValue}</TableCell>;
};
