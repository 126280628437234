import { genderAtom, genderConfigAtom } from '@/atoms/queries/userConfigs';
import { useOptimisticUserConfigUpdate } from '@/hooks/useOptimisticUserConfigUpdate';
import { UserConfigGenderDefinition, UserConfigPutMutationParams } from '@/types/userConfigs';
import { Button, ButtonGroup, Typography } from '@statsbomb/kitbag-components';
import { Gender } from '@statsbomb/parachute-types';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

interface GenderToggleProps {
  isLoading?: boolean;
  gender?: Gender;
  genderConfig?: UserConfigGenderDefinition | null;
}

export const GenderToggleBase = ({ isLoading = false, gender, genderConfig }: GenderToggleProps) => {
  const { t } = useTranslation('preferences');

  const { updateUserConfigOptimistically, localValue } = useOptimisticUserConfigUpdate(gender, 'updateGender');

  const isMen = !localValue || localValue === Gender.M;

  const saveGender = async (newGender: Gender) => {
    const mutationParams = {
      configId: genderConfig?.configId,
      configType: 'gender',
      configDefinition: { gender: newGender },
    } as UserConfigPutMutationParams;

    await updateUserConfigOptimistically(mutationParams, newGender);
  };

  return (
    <div>
      <Typography variant="headingMedium">{t('data.gender.label')}</Typography>
      <ButtonGroup>
        <Button
          onClick={() => saveGender(Gender.M)}
          shape="pill"
          variant={isMen ? 'primary' : 'ghost'}
          isDisabled={isLoading}
        >
          {t('data.gender.m')}
        </Button>
        <Button
          onClick={() => saveGender(Gender.F)}
          shape="pill"
          variant={isMen ? 'ghost' : 'primary'}
          isDisabled={isLoading}
        >
          {t('data.gender.w')}
        </Button>
      </ButtonGroup>
    </div>
  );
};

export const GenderToggleWithData = () => {
  const genderConfig = useAtomValue(genderConfigAtom);
  const gender = useAtomValue(genderAtom);

  return <GenderToggleBase genderConfig={genderConfig} gender={gender} />;
};

export const GenderToggle = () => (
  <Suspense fallback={<GenderToggleBase isLoading />}>
    <GenderToggleWithData />
  </Suspense>
);
