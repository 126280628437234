import { useTranslation } from 'react-i18next';
import { Typography } from '@statsbomb/kitbag-components';
import { ContentState } from '@/components/contentState/ContentState';
import { DashboardWidgets } from '@/components/dashboard/DashboardWidgets';
import { StatusTranslationKey } from '@/types/status';
import { isNullish } from '@/utils/general';
import { Nullable } from '@/types/generic';
import { UserConfigWidgetDefinition } from '@/types/userConfigs';
import { ReactNode } from 'react';

export const DashboardContentHeading = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation('navigation');
  const heading = t('secondary.dashboard');

  return (
    <div className="flex flex-col gap-2">
      <div className="bg-canvas-tertiary-main w-full flex items-center justify-between px-4 py-3">
        <Typography variant="headingRegular">{heading}</Typography>
      </div>
      {children}
    </div>
  );
};

export const DashboardContent = ({
  entityId,
  dashboardWidgets,
}: {
  entityId: Nullable<number>;
  dashboardWidgets: UserConfigWidgetDefinition[];
}) => (
  <DashboardContentHeading>
    {isNullish(entityId) ? (
      <ContentState status={StatusTranslationKey.ERROR} />
    ) : (
      <DashboardWidgets entityId={entityId} widgets={dashboardWidgets} />
    )}
  </DashboardContentHeading>
);
