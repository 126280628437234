import { filteredTeamGamesAtom, teamGamesBySeasonAtom, teamSelectedGamesAtom } from '@/atoms/filters/team/teamGames';
import { unwrappedTeamAggsMinutesPlayedAtom } from '@/atoms/queries/team/teamRadar';
import { teamCyclesOrderedAtom } from '@/atoms/queries/teams';
import { RadarFilterCompetitionSummary } from '@/components/radar/filterSummaries/RadarFilterCompetitionSummary';
import { RadarFilterGameSummary } from '@/components/radar/filterSummaries/RadarFilterGameSummary';
import { RadarFilterMinsSummary } from '@/components/radar/filterSummaries/RadarFilterMinsSummary';
import { RadarFilterSeasonSummary } from '@/components/radar/filterSummaries/RadarFilterSeasonSummary';
import { RadarTable } from '@/components/radar/table/RadarTable';
import { RadarTableHeader } from '@/components/radar/table/RadarTableHeader';
import { useHasEmptyRadar } from '@/hooks/useHasEmptyRadar';
import { Suspense } from 'react';

export const TeamRadarTableSummary = () => {
  const hasEmptyRadar = useHasEmptyRadar();

  return (
    <div className="flex flex-col p-4 gap-2 @container">
      <RadarTable />
      {!hasEmptyRadar && (
        <Suspense>
          <div data-testid="team-radar-filter-summary" className="grid gap-2 grid-cols-1 @xs:grid-cols-2">
            <RadarFilterMinsSummary minutesAtom={unwrappedTeamAggsMinutesPlayedAtom} />
            <RadarFilterCompetitionSummary
              filteredGamesDataAtom={filteredTeamGamesAtom}
              selectedGamesAtom={teamSelectedGamesAtom}
              orderedCyclesAtom={teamCyclesOrderedAtom}
            />
            <RadarFilterSeasonSummary
              filteredGamesDataAtom={filteredTeamGamesAtom}
              selectedGamesAtom={teamSelectedGamesAtom}
            />
            <RadarFilterGameSummary
              gamesGroupedBySeasonAtom={teamGamesBySeasonAtom}
              selectedGamesAtom={teamSelectedGamesAtom}
            />
          </div>
        </Suspense>
      )}
    </div>
  );
};

export const TeamRadarTableGroup = () => (
  <div className="bg-canvas-tertiary-main flex-1">
    <RadarTableHeader />
    <TeamRadarTableSummary />
  </div>
);
