import { Link, useLocation } from 'react-router-dom';
import { Menu, MenuItem, MenuItemText, SideNavigationItem, SideNavigationMenuList } from '@statsbomb/kitbag-components';
import { NavigationItem, useNavigationItems } from '@/hooks/useNavigationItems';
import { useState } from 'react';
import { Nullable } from '@/types/generic';
import { ErrorBoundary } from '@sentry/react';
import { isSettingsPanelOpenAtom } from '@/atoms/sidebar';
import { useAtom, useSetAtom } from 'jotai';
import { navigationOpenAtom } from '@/atoms/navigation';
import { useTheme } from 'styled-components';

const SideNavLinks = ({ navItems }: { navItems: NavigationItem[] }) => {
  const { pathname } = useLocation();

  // The split always has 3 elements, an empty string, the language and the section
  const pathSection = String(pathname.split('/').at(2));

  const [selectedSubmenu, setSelectedSubmenu] = useState<Nullable<string>>(null);
  const [isNavOpen, setIsNavOpen] = useAtom(navigationOpenAtom);
  const setIsSettingsPanelOpen = useSetAtom(isSettingsPanelOpenAtom);

  const theme = useTheme();
  const isTouch = theme.mqMatch('md');

  const handleMenuItemClick = (menuItemId: string, hasSubNav: boolean) => {
    if (isTouch) {
      if (hasSubNav) {
        const newSelectedSubmenu = selectedSubmenu === null ? menuItemId : null;
        setSelectedSubmenu(newSelectedSubmenu);
      } else {
        setIsNavOpen(false);
      }
    }

    setIsSettingsPanelOpen(false);
  };

  // The navigation for a submenu is handled in the componentProps of the MenuItem
  const handleSubmenuItemClick = () => {
    if (isTouch) {
      // Just close the nav on mobile and clear the selected submenu
      setIsNavOpen(false);
      setSelectedSubmenu(null);
    }

    setIsSettingsPanelOpen(false);
  };

  return (
    <SideNavigationMenuList>
      {navItems.map(({ id, icon, path, label, subNav }) => {
        const isActiveSection = path.includes(pathSection);
        const hasSubNav = !!subNav?.length;

        return (
          <SideNavigationItem
            isOpen={isNavOpen}
            version={2}
            key={id}
            menuItemId={id}
            icon={icon}
            isTouch={isTouch}
            isSelected={isActiveSection}
            selectedSubmenu={selectedSubmenu}
            component={Link}
            componentProps={{
              to: isTouch && hasSubNav ? null : path,
              onClick: () => handleMenuItemClick(id, hasSubNav),
            }}
            isSubmenuOpen={selectedSubmenu === id}
            subMenu={
              hasSubNav && (
                <Menu variant="subnavigation">
                  {subNav.map(({ id: subNavId, label, route }) => (
                    <li key={subNavId}>
                      <MenuItem
                        component={Link}
                        componentProps={{
                          to: route,
                        }}
                        isActive={pathname === route}
                        variant="subnavigation"
                        size={isTouch ? 'touch' : 'small'}
                        opacity="secondary"
                        onClick={handleSubmenuItemClick}
                      >
                        <MenuItemText isTouch={isTouch}>{label}</MenuItemText>
                      </MenuItem>
                    </li>
                  ))}
                </Menu>
              )
            }
          >
            {label}
          </SideNavigationItem>
        );
      })}
    </SideNavigationMenuList>
  );
};

export const EmptySideNavLinks = () => <SideNavLinks navItems={[]} />;

const MainSideNavLinksWithData = () => {
  const { primary } = useNavigationItems();
  return <SideNavLinks navItems={primary} />;
};

export const MainSideNavLinks = () => (
  <ErrorBoundary fallback={<EmptySideNavLinks />}>
    <MainSideNavLinksWithData />
  </ErrorBoundary>
);
