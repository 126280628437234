import { StatusTranslationKey } from '@/types/status';
import { isEmptyArray } from '@/utils/array';
import { isEmptyObject, isObjectWithEmptyValues } from '@/utils/object';
import { Atom, useAtomValue } from 'jotai';
import { loadable } from 'jotai/utils';

const isEmptyData = (data: unknown) =>
  data === null || isEmptyArray(data) || isEmptyObject(data) || isObjectWithEmptyValues(data);

export const useLoadableStatus = (atom: Atom<Promise<unknown>>) => {
  const loadableAtom = useAtomValue(loadable(atom));
  if (loadableAtom.state === 'loading') return StatusTranslationKey.LOADING;
  if (loadableAtom.state === 'hasError') return StatusTranslationKey.ERROR;
  if (isEmptyData(loadableAtom.data)) return StatusTranslationKey.NO_DATA;

  return undefined;
};
