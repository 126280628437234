import { PITCH_COLOURING_MODES, PITCH_CROP, PITCH_ORIENTATION } from '@/consts/visualisations';
import { Visualisation } from '@statsbomb/parachute-types';
import { Atom } from 'jotai';
import { BODY_PARTS } from '@/consts/bodyParts';
import { ArrowEvent, MarkerEvent } from './event';
import { KeysToCamelCase, SBID } from './generic';
import { PromiseAtom } from './atom';

export type PitchOrientation = (typeof PITCH_ORIENTATION)[number];
export type PitchCrop = (typeof PITCH_CROP)[number];

export type ConfigVisualisation = KeysToCamelCase<Visualisation>;
export interface VisualisationComponentProps {
  /* need this as I currently don't want to allow interaction on preview pages
  but the way the VisualisationComponent / event map component has been structured
  the preview pages also have the onDragEnd prop passed in.  
*/
  allowInteraction?: boolean;
  rotationName?: string;
  visibleArea?: string;
}

export interface PitchVisComponentProps extends VisualisationComponentProps {
  isPreview?: boolean;
}

export interface PitchVisComponents {
  heatMap: () => JSX.Element;
  eventMap: (props: PitchVisComponentProps) => JSX.Element;
  passSonar: (props: PitchVisComponentProps) => JSX.Element;
  shotMap: (props: PitchVisComponentProps) => JSX.Element;
}

interface WidgetVisComponent extends VisualisationComponentProps {
  isWidget: boolean;
  id: SBID;
}

export interface VisComponents extends PitchVisComponents {
  radar: (props: WidgetVisComponent) => JSX.Element;
  positionMap?: (props: WidgetVisComponent) => JSX.Element;
  info?: (props: WidgetVisComponent) => JSX.Element;
  defensiveActivity?: (props: WidgetVisComponent) => JSX.Element;
  squad?: (props: WidgetVisComponent) => JSX.Element;
  minutesPlayed?: (props: WidgetVisComponent) => JSX.Element;
}

export enum VisDisplayMode {
  BOTH = 'both',
  VIS = 'vis',
  TABLE = 'table',
}

export type VisualisationMap = Record<keyof PitchVisComponents, (props: VisualisationComponentProps) => JSX.Element>;

export interface FallBackProps {
  isPreview?: boolean;
}

export interface PitchEvents {
  markerEvents: MarkerEvent[];
  arrowEvents: ArrowEvent[];
}

export type PitchEventsPromiseAtom = PromiseAtom<PitchEvents>;
export type UnwrappedPitchEventsAtom = Atom<PitchEvents>;

export interface EventMapBaseProps {
  allowInteraction?: boolean;
  unwrappedPitchEventsAtom: UnwrappedPitchEventsAtom;
  isPreview?: boolean;
}

export interface EventMapProps extends EventMapBaseProps {
  pitchEventsAtom: PitchEventsPromiseAtom;
}

export interface HeatMapProps {
  pitchEventsAtom: PitchEventsPromiseAtom;
}

export type ShotMapEventMarker = {
  type: string;
  eventId: string;
  outcome: string | null;
  xg: number;
  penalty: boolean;
  freeKick: boolean;
  bodyPart: (typeof BODY_PARTS)[number];
  startX: number;
  startY: number;
  followsDribble: boolean;
  followsThroughBall: boolean;
};

export type PitchMarkerColouringMode = (typeof PITCH_COLOURING_MODES)[number];
