import { HeatMap } from '@/components/visualisations/HeatMap';
import { MetricKeyFallbackWrapper } from '@/components/metric/MetricKeyFallbackWrapper';
import {
  teamEventMarkersForMetricAtom,
  unwrappedTeamEventMarkersForMetricAtom,
} from '@/atoms/queries/team/teamEventData';

export const TeamHeatMap = () => (
  <MetricKeyFallbackWrapper>
    <HeatMap
      pitchEventsAtom={teamEventMarkersForMetricAtom}
      unwrappedPitchEventsAtom={unwrappedTeamEventMarkersForMetricAtom}
    />
  </MetricKeyFallbackWrapper>
);
