import { PitchHeatmapLayer, PitchViz, Legend, GradientBar } from '@statsbomb/kitbag-datavis';
import { useAtomValue } from 'jotai';
import { BaseEvent, VisEvent } from '@/types/event';
import { HeatMapProps, UnwrappedPitchEventsAtom } from '@/types/visualisation';
import { PITCH_HEIGHT, PITCH_WIDTH, SCALE_FACTOR } from '@/consts/HeatMap';
import { useGetPitchRotation } from '@/hooks/useGetPitchRotation';
import { usePitchCrop } from '@/hooks/usePitchCrop';
import { showLegendAtom, showMarkerStartAtom } from '@/atoms/visualisation';
import { isNullish } from '@/utils/general';
import { CardBody, useRect } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { useLoadableStatus } from '@/hooks/useLoadableStatus';
import { useStatus } from '@/hooks/useStatus';
import { PitchVisWrapper } from './PitchVisualisation/PitchVisWrapper';
import { PitchVisWithAttackingDirectionArrow } from './PitchVisualisation/PitchVisWithAttackingDirectionArrow';
import { CardHeightWrapper } from '../card/CardHeightWrapper';
import { ErrorCard } from '../contentState/ErrorCard';

export const HeatMapBase = ({ eventsForMetric = [] }: { eventsForMetric?: VisEvent[] | BaseEvent[] }) => {
  const pitchRotation = useGetPitchRotation(false);
  const visibleArea = usePitchCrop(false);
  const showMarkerStart = useAtomValue(showMarkerStartAtom);
  const { t } = useTranslation('visualisation');
  const [legendRef, legendRect] = useRect<HTMLLegendElement>();
  const showLegend = useAtomValue(showLegendAtom);

  const pitchViz = (
    <PitchViz
      /* this is intentionally empty to overwrite pitch viz svg styles
    to position the arrow correctly */
      className=""
      rotationName={pitchRotation}
      pitchFocusZoneName={visibleArea}
      underlay={
        <PitchHeatmapLayer
          data={eventsForMetric.map(({ startX, startY, endX, endY }) => {
            const x = showMarkerStart || isNullish(endX) ? startX : endX;
            const y = showMarkerStart || isNullish(endY) ? startY : endY;

            return {
              x,
              y,
            };
          })}
          width={PITCH_WIDTH}
          height={PITCH_HEIGHT}
          scaleFactor={SCALE_FACTOR}
        />
      }
    />
  );

  return (
    <div className="h-full w-full" data-testid="heatmap-with-data">
      <PitchVisWrapper>
        {showLegend ? (
          <>
            <PitchVisWithAttackingDirectionArrow pitchVisType="heatmap" pitchRotation={pitchRotation}>
              {pitchViz}
            </PitchVisWithAttackingDirectionArrow>
            <Legend legendRef={legendRef}>
              <GradientBar
                colSpan={2}
                title={t('legend.heatMap.title')}
                minText={t('legend.heatMap.minText')}
                maxText={t('legend.heatMap.maxText')}
                legendWidth={legendRect.width}
              />
            </Legend>
          </>
        ) : (
          pitchViz
        )}
      </PitchVisWrapper>
    </div>
  );
};

const HeatMapWithData = ({
  pitchEventsAtom,
  unwrappedPitchEventsAtom,
}: HeatMapProps & { unwrappedPitchEventsAtom: UnwrappedPitchEventsAtom }) => {
  const { markerEvents, arrowEvents } = useAtomValue(unwrappedPitchEventsAtom);
  const allEvents = [...markerEvents, ...arrowEvents];

  const status = useLoadableStatus(pitchEventsAtom);
  const statusProps = useStatus('general', status);

  return (
    <CardHeightWrapper>
      <CardBody {...statusProps}>
        <HeatMapBase eventsForMetric={allEvents} />
      </CardBody>
    </CardHeightWrapper>
  );
};

export const HeatMap = (props: HeatMapProps & { unwrappedPitchEventsAtom: UnwrappedPitchEventsAtom }) => (
  <ErrorCard translationNameSpace="general" fallbackChildren={<HeatMapBase eventsForMetric={[]} />}>
    <HeatMapWithData {...props} />
  </ErrorCard>
);
