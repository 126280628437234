import { ShotMapEventMarker } from '@/types/visualisation';
import { useXGScale } from '@statsbomb/kitbag-datavis';
import { useTheme } from 'styled-components';

export const useShotMarkerFill = () => {
  const theme = useTheme();
  const { dataObjects } = theme.colours.visualisations;
  const getXgColor = useXGScale();

  const getMarkerFill = (event: ShotMapEventMarker) => {
    if (event.type === 'own_goal_for') return dataObjects.inverse;
    if (event.outcome === 'blocked') return dataObjects.neutral.main;
    return getXgColor(event.xg);
  };

  return getMarkerFill;
};
