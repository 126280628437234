import { atom } from 'jotai';
import { Nullable } from '@/types/generic';
import { SelectedVideoIds } from '@/types/video';
import { selectedVisEventsAtom } from './vis/selection';

export const selectedVideoIdsBaseAtom = atom<Nullable<SelectedVideoIds[]>>(null);

export const selectedVideoIdsAtom = atom(get => {
  const selectedVideoIds = get(selectedVideoIdsBaseAtom);
  const selectedVisEvents = get(selectedVisEventsAtom);

  const selectedVisArqamIds = selectedVisEvents?.reduce(
    (acc, { eventId, videoEventId }) => (videoEventId ? [...acc, { eventId, videoEventId }] : acc),
    <SelectedVideoIds[]>[],
  );

  return selectedVisArqamIds?.length ? selectedVisArqamIds : selectedVideoIds;
});

export const selectedGameIdForVideosAtom = atom<Nullable<number>>(null);
