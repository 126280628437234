import { atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { playerIdAtom } from '@/atoms/player/player';
import { PlayerGameMinutesWithRels } from '@statsbomb/parachute-types';
import { convertFilterParamsToString } from '@/utils/api';
import { atom } from 'jotai';
import { competitionIdsToFilterByAtom } from '@/atoms/filters/highLevel/competitions';
import { seasonIdsToFilterByAtom } from '@/atoms/filters/highLevel/seasons';
import { toObject } from '@/utils/object';
import { selectedTeamIdsAtom } from '@/atoms/filters/highLevel/teams';
import { unwrappedPlayerSelectedGamesForQueryAtom } from '@/atoms/filters/player/playerGames';
import { convertOptionsToValues } from '@/utils/array';
import { ALL_ITEMS } from '@/consts/interface';
import { fetchClientAtom } from '../client';

const playerMinutesPlayedFilterParamsAtom = atom(get => ({
  in: {
    ...toObject('input.competition_id', get(competitionIdsToFilterByAtom)),
    ...toObject('input.season_id', get(seasonIdsToFilterByAtom)),
    ...toObject('input.team_id', get(selectedTeamIdsAtom)),
    ...toObject('input.game_id', convertOptionsToValues(get(unwrappedPlayerSelectedGamesForQueryAtom))),
  },
}));

export const playerMinutesPlayedAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['playerMinutesPlayed', get(playerIdAtom), get(playerMinutesPlayedFilterParamsAtom)] as const;
  const queryFn = async ({ queryKey: [, playerId, filterParams] }: { queryKey: typeof queryKey }) => {
    const { fetch } = get(fetchClientAtom);

    return (await fetch(
      `/player/${playerId}/games/minutes-played${convertFilterParamsToString({
        ...filterParams,
        limit: ALL_ITEMS,
        order_by: { 'game.date': 'ASC' },
      })}`,
    )) as Promise<PlayerGameMinutesWithRels[]>;
  };

  return { queryKey, queryFn };
});
