import { groupBy, memoize } from 'lodash/fp';
import { atom } from 'jotai';
import { loadable, unwrap } from 'jotai/utils';
import { PlayerWithRels, Search, SearchResult, TeamWithRels } from '@statsbomb/parachute-types';
import { unwrappedRawPlayersAtom } from '@/atoms/queries/players';
import { unwrappedRawTeamsAtom } from '@/atoms/queries/teams';
import { distance } from 'fastest-levenshtein';
import { searchFormattedString } from '@/utils/string';
import { MAX_SEARCH_RESULTS_COUNT, MIN_QUERY_LENGTH } from '@/consts/search';
import { searchValueAtom } from '../search/search';

export type SearchableData = (PlayerWithRels | TeamWithRels) & { searchName: string; searchNickname: string };

// convert full types into simple search results
const toSearchResult = (res: PlayerWithRels | TeamWithRels): SearchResult => {
  const isPlayer = 'player_id' in res;
  return {
    type: isPlayer ? 'player' : 'team',
    age: isPlayer ? res.age : null,
    id: isPlayer ? res.player_id : res.team_id,
    name: res.name,
    nickname: res.nickname,
    code: (isPlayer ? res.nationality_area_code : res.area_code) || '',
    gender: res.gender,
  };
};

const combinedSearchDataAtom = atom(async get => {
  const players = await get(unwrappedRawPlayersAtom);
  const teams = await get(unwrappedRawTeamsAtom);

  // pre-calculate search-friendly names to make matching quicker later
  return [...players, ...teams].map((item: PlayerWithRels | TeamWithRels) => ({
    ...item,
    searchName: searchFormattedString(item.name),
    searchNickname: searchFormattedString(item.nickname || ''),
  }));
});

export const calculateSearchResults = memoize((query: string, searchData: SearchableData[]) => {
  const queryStrings = searchFormattedString(query).split(' ');

  const matchingData = searchData.filter(p =>
    queryStrings.map(subq => p.searchName.includes(subq) || p.searchNickname.includes(subq)).every(Boolean),
  );

  const sortedData = matchingData.sort(
    (a, b) =>
      Math.min(distance(query, a.searchName), distance(query, a.searchNickname)) -
      Math.min(distance(query, b.searchName), distance(query, b.searchNickname)),
  );

  const topData = sortedData.slice(0, MAX_SEARCH_RESULTS_COUNT);

  const results = groupBy('type', topData.map(toSearchResult));

  // ensure order by explicitly stating it:
  return {
    player: results.player ?? [],
    team: results.team ?? [],
  };
});

const searchQueryResultsAtom = atom<Promise<Search>>(async get => {
  const query = get(searchValueAtom).trim();
  const searchData = await get(combinedSearchDataAtom);
  if (query.length < MIN_QUERY_LENGTH) return { player: [], team: [] };

  return calculateSearchResults(query, searchData);
});

/**
 * Both unwrapped and loadable are being used in the Search component as unwrapped does not hold a loading state.
 * loadable is only being used to show the loading state of the search query. This keeps the current search
 * component from being empty when the search query is loading and working the same as current IQ.
 */
export const unwrappedSearchQueryResult = unwrap(searchQueryResultsAtom, prev => prev ?? {});
export const loadableSearchQueryResult = loadable(searchQueryResultsAtom);
