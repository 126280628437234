import { CardHeader } from '@statsbomb/kitbag-components';
import { playerMetricDataAtom } from '@/atoms/player/metric';
import { ExpandVideoButton } from '@/components/button/ExpandVideoButton';
import { PreviewItemsLayout } from '@/components/layout/PreviewItemsLayout';
import { MetricKeyDropdown } from '@/components/metric/MetricKeyDropdown';
import { PlayerVisEventsTable } from '@/components/player/vis/PlayerVisEventsTable';
import { VisualisationComponent } from '@/components/visualisations/PitchVisualisation/VisualisationComponent';
import { playerPitchVisComponents } from '@/consts/visualisationComponents';

export const PlayerVideoPreview = () => (
  <>
    <CardHeader>
      <MetricKeyDropdown metricDataAtom={playerMetricDataAtom} />
      <ExpandVideoButton />
    </CardHeader>
    <PreviewItemsLayout>
      <VisualisationComponent allowInteraction visualisations={playerPitchVisComponents} />
      <PlayerVisEventsTable />
    </PreviewItemsLayout>
  </>
);
