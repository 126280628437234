import { CardHeader } from '@statsbomb/kitbag-components';
import { VideoPlayer } from './VideoPlayer';
import { VideoPlayerHeader } from './VideoPlayerHeader';
import { CloseCardHeaderButton } from '../button/CloseCardHeaderButton';

export const VideoPreview = () => (
  <>
    <CardHeader>
      <VideoPlayerHeader navigationPath="../.." />
      <CloseCardHeaderButton navigationPath=".." />
    </CardHeader>
    <VideoPlayer />
  </>
);
