import { ErrorBoundary } from '@sentry/react';
import { CardHeader, Typography } from '@statsbomb/kitbag-components';
import { ReactNode } from 'react';
import { GeneralErrorBoundaryFallback } from '../errorBoundary/ErrorBoundaryFallback';

/**
 * This component should be used to hold the controls which
 * appear in the top heading of our 50/50 layout.
 */
export const SectionControlsWrapper = ({
  heading,
  headerComponent,
  testId = 'child-components',
  children,
}: {
  heading: string;
  headerComponent?: ReactNode;
  testId?: string;
  children?: ReactNode;
}) => (
  <ErrorBoundary fallback={<GeneralErrorBoundaryFallback />}>
    <div className="bg-canvas-tertiary-main [&>header]:!pl-4">
      <CardHeader>
        <Typography variant="headingRegular" className="pr-2">
          {heading}
        </Typography>
        {headerComponent}
        <div className="ml-auto flex items-center gap-2" data-testid={testId}>
          {children}
        </div>
      </CardHeader>
    </div>
  </ErrorBoundary>
);
