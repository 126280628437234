import { loadSelectedFilterSetAtom, selectedFilterSetAtom, selectedFilterSetIdAtom } from '@/atoms/filters/userFilters';
import { UserFiltersButtons } from '@/components/filters/UserFilters/UserFiltersButtons';
import { DeleteFilterSetModal } from '@/components/modal/DeleteFilterSetModal/DeleteFilterSetModal';
import { LoadFilterSetModal } from '@/components/modal/LoadFilterSetModal/LoadFilterSetModal';
import { SaveFilterModal } from '@/components/modal/SaveFilterModal/SaveFilterModal';
import { FILTER_SET_ID } from '@/consts/searchKeys';
import { ErrorBoundary } from '@sentry/react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const UserFiltersWithData = () => {
  const setSelectedFilterSetId = useSetAtom(selectedFilterSetIdAtom);
  const loadSelectedFilters = useSetAtom(loadSelectedFilterSetAtom);
  const selectedFilterSet = useAtomValue(selectedFilterSetAtom);

  const [searchParams] = useSearchParams();
  const filterSetId = searchParams.get(FILTER_SET_ID);

  useEffect(() => setSelectedFilterSetId(filterSetId), [filterSetId]);
  useEffect(() => loadSelectedFilters(), [selectedFilterSet?.configId]);

  return (
    <>
      <UserFiltersButtons />
      <SaveFilterModal />
      <LoadFilterSetModal />
      <DeleteFilterSetModal />
    </>
  );
};

export const UserFilters = () => (
  <ErrorBoundary fallback={<UserFiltersButtons />}>
    <UserFiltersWithData />
  </ErrorBoundary>
);
