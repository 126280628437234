import { ShotMapEventMarker } from '@/types/visualisation';

export const getShotMarkerShape = (event: ShotMapEventMarker) => {
  switch (true) {
    case event.type === 'own_goal_for':
      return 'HEXAGON';
    case event.penalty || event.freeKick:
      return 'SQUARE';
    case event.followsThroughBall:
      return 'PENTAGON';
    case event.followsDribble:
      return 'DIAMOND';
    case event.bodyPart === 'head':
      return 'CIRCLE';
    default:
      return 'HEXAGON';
  }
};
