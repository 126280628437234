import { defaultUserTeamAndPlayerIdsAtom } from '@/atoms/queries/user';
import { unwrappedDefaultTeamMostRecentGameIdAtom } from '@/atoms/user';
import { useDataLockerNavItems } from '@/components/navigation/DataLockerNav';
import { usePlayerNavItems } from '@/components/navigation/PlayerNav';
import { useTeamNavItems } from '@/components/navigation/TeamNav';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useShouldShowHiddenFeature } from './useShouldShowHiddenFeature';

export interface NavigationItem {
  id: string;
  icon: string;
  path: string;
  label: string;
  subNav?: SubNavigationItem[];
}

export interface SubNavigationItem {
  id: string;
  label: string;
  route: string;
}

export const useNavigationItems = () => {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation('navigation');
  const { data } = useAtomValue(defaultUserTeamAndPlayerIdsAtom);
  const defaultGameId = useAtomValue(unwrappedDefaultTeamMostRecentGameIdAtom);

  const playerPath = data ? `/${locale}/player/${data.playerId}/` : '';
  const teamPath = data ? `/${locale}/team/${data.teamId}/` : '';
  const gamePath = defaultGameId ? `/${locale}/match/${defaultGameId}/` : '';

  // TODO (PPC-1751: unhide game dashboard)
  const shouldShowHiddenFeature = useShouldShowHiddenFeature();
  /* istanbul ignore next */
  const gameRoute = shouldShowHiddenFeature
    ? [
        {
          id: 'matches',
          icon: 'MatchSoccer',
          path: gamePath ? `${gamePath}dashboard` : '#match',
          label: t('primary.game'),
        },
      ]
    : [];

  const navigationItems: { primary: NavigationItem[]; footer: NavigationItem[] } = {
    primary: [
      {
        id: 'dashboard',
        icon: 'Dashboard',
        path: `/${locale}/reports`,
        label: t('primary.report'),
      },
      {
        id: 'player',
        icon: 'PlayerSoccer',
        path: playerPath ? `${playerPath}dashboard` : '#player',
        label: t('primary.player'),
        subNav: usePlayerNavItems(playerPath),
      },
      {
        id: 'team',
        icon: 'TeamSoccer',
        path: teamPath ? `${teamPath}dashboard` : '#team',
        label: t('primary.team'),
        subNav: useTeamNavItems(teamPath),
      },
      ...gameRoute,
      {
        id: 'data-locker',
        icon: 'IqScout',
        path: `/${locale}/data-locker`,
        label: `${t('primary.dataLocker')}`,
        subNav: useDataLockerNavItems(),
      },
    ],
    footer: [
      {
        id: 'settings',
        icon: 'AccountSettings',
        path: `/${locale}/settings`,
        label: t('primary.settings'),
      },
    ],
  };
  return navigationItems;
};
