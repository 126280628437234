import { DISPLAY_MODE } from '@/consts/searchKeys';
import { VisDisplayMode } from '@/types/visualisation';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useVisDisplayMode = (initialDisplayMode?: VisDisplayMode) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const visDisplayMode = searchParams.get(DISPLAY_MODE) as VisDisplayMode | null;

  const setVisDisplayMode = (mode: VisDisplayMode, replace = false) => {
    searchParams.set(DISPLAY_MODE, mode);
    setSearchParams(searchParams, { replace });
  };

  useEffect(() => {
    if (!visDisplayMode && initialDisplayMode) {
      setVisDisplayMode(initialDisplayMode, true);
    }
  }, []);

  return [visDisplayMode, setVisDisplayMode] as const;
};
