import { playerDataDefaultColumnsAtom, playerDataVisibleColumnsAtom } from '@/atoms/dataLocker/player/table';
import { tableColumnEntitiesAtom } from '@/atoms/queries/tableColumns';
import { teamSquadFilterParamsAtom } from '@/atoms/queries/team/teamAggData';
import { orderByAtom, orderDirectionAtom } from '@/atoms/table';
import { unwrappedSelectedTeamNameAtom } from '@/atoms/team/info';
import { TableDataControls } from '@/components/controls/TableDataControls';
import { PageTitle } from '@/components/head/PageTitle';
import { TeamLayoutWithFilters } from '@/components/layout/team/TeamLayoutWithFilters';
import { TableScopeProvider } from '@/components/tables/TableScopeProvider';
import { TeamSquadTableWithPagination } from '@/components/tables/TeamSquadTable';
import { useCsvDownload } from '@/hooks/exporting/useCsvDownload';
import { useEntityPageTitle } from '@/hooks/useEntityPageTitle';
import { useTableColumns } from '@/hooks/useTableColumns';
import { playersNormalisedAggregatesUrl } from '@/query/url';
import { SORT_DIRECTION } from '@/types/ordering';
import { Card } from '@statsbomb/kitbag-components';
import { useAtomValue, useSetAtom } from 'jotai';
import { ScopeProvider } from 'jotai-scope';
import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

const TeamSquadPageTable = () => {
  const setOrderTableBy = useSetAtom(orderByAtom);
  const setOrderDirection = useSetAtom(orderDirectionAtom);

  // Using useLayoutEffect to prevent instances where the table briefly
  // renders without default ordering when navigating via the tabs
  useLayoutEffect(() => {
    // Set squad page default ordering
    setOrderDirection(SORT_DIRECTION.DESC);
    setOrderTableBy('aggregates.minutes_played');
  }, []);

  return <TeamSquadTableWithPagination />;
};

const TableWithControls = ({ pageTitle }: { pageTitle: string }) => {
  const { t } = useTranslation('navigation');
  useTableColumns(['player', 'player_metric']);
  const teamSquadFilterParams = useAtomValue(teamSquadFilterParamsAtom);
  useCsvDownload(pageTitle, playersNormalisedAggregatesUrl(teamSquadFilterParams));

  return (
    <Card>
      <TableDataControls
        visibleColumnsAtom={playerDataVisibleColumnsAtom}
        defaultColumnsAtom={playerDataDefaultColumnsAtom}
        heading={t(`secondary.squad`)}
      />
      <TableScopeProvider>
        <TeamSquadPageTable />
      </TableScopeProvider>
    </Card>
  );
};

export const TeamSquadPage = () => {
  const { t } = useTranslation('navigation');
  const teamName = useAtomValue(unwrappedSelectedTeamNameAtom);
  const pageTitle = useEntityPageTitle(t('secondary.squad'), teamName);

  return (
    <ScopeProvider atoms={[tableColumnEntitiesAtom]}>
      <PageTitle>{pageTitle}</PageTitle>
      <TeamLayoutWithFilters>
        <TableWithControls pageTitle={pageTitle} />
      </TeamLayoutWithFilters>
    </ScopeProvider>
  );
};
