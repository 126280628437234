import { defaultSeasonsAtom } from '@/atoms/filters/highLevel/seasons';
import { renderedRadarDataAtom } from '@/atoms/radar';
import { METRIC_KEY } from '@/consts/searchKeys';
import { useUpdateQueryString } from '@/hooks/useUpdateQueryString';
import { LoadableRadarDataAtom, SelectedGamesAtom } from '@/types/radar';
import { snakeToCamel } from '@/utils/queries';
import { ResponsiveRadar, RadarData } from '@statsbomb/kitbag-datavis';
import { useAtomValue, useSetAtom } from 'jotai';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { selectedVisualisationAtom, showArrowsAtom } from '@/atoms/visualisation';
import { useResetSelectionLayer } from '@/hooks/useResetSelectionLayer';
import { useGetEntity } from '@/hooks/useGetEntity';
import { useDeepEffect } from '@/hooks/useDeepEffect';
import { Card, CardBody } from '@statsbomb/kitbag-components';
import { SBID } from '@/types/generic';
import { CardHeightWrapper } from '../card/CardHeightWrapper';

const RadarChartBase = ({
  id,
  isLoading,
  isInteractive = true,
}: {
  id: SBID;
  isLoading?: boolean;
  isInteractive?: boolean;
}) => {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation(['metrics', 'status']);
  const navigate = useNavigate();
  const { entity } = useGetEntity();
  const [searchParams] = useSearchParams();
  const resetSelectionLayer = useResetSelectionLayer();
  const createQueryString = useUpdateQueryString(searchParams);

  const renderedRadarData = useAtomValue(renderedRadarDataAtom);
  const setSelectedVis = useSetAtom(selectedVisualisationAtom);
  const setShowArrows = useSetAtom(showArrowsAtom);
  const selectedMetricKey = searchParams.get(METRIC_KEY) || '';

  const translatedRenderedRadarData = renderedRadarData.map(data => ({
    ...data,
    label: t(`${snakeToCamel(data.key)}.name`, { ns: 'metrics' }),
  }));

  const handleClick = (metric: RadarData) => {
    const dataForSlice = translatedRenderedRadarData.find(d => d.key === metric.key);
    if (!dataForSlice) return;

    setSelectedVis(null);
    setShowArrows(false);
    resetSelectionLayer();

    const isSameKey = metric.key === selectedMetricKey;
    const paramsToAdd = isSameKey ? [] : [{ key: METRIC_KEY, value: dataForSlice.key }];
    const paramsToDelete = isSameKey ? [METRIC_KEY] : [];
    const navigationPath = isSameKey ? './' : 'preview';

    navigate(`${navigationPath}?${createQueryString(paramsToAdd, paramsToDelete)}`);
  };

  const statusProps = isLoading
    ? {
        statusDescription: t('general.loading.description', { ns: 'status' }),
        statusTitle: t('general.loading.title', { ns: 'status' }),
        statusType: 'loading',
      }
    : null;

  return (
    <Card>
      <CardHeightWrapper>
        <CardBody {...statusProps}>
          <ResponsiveRadar
            id={`${entity}-${id}`}
            data={translatedRenderedRadarData}
            onSliceClick={isInteractive ? handleClick : undefined}
            locale={locale}
            selectedKey={selectedMetricKey}
          />
        </CardBody>
      </CardHeightWrapper>
    </Card>
  );
};

// We want to know when we're loading new data for the radar so that we can render a loading message.
// But we can't use suspense in this case as that will cause us to lose the smooth animation from one
// radar state to the next (I don't really understand this to be honest, but allowing it to fallback to
// the suspense causes a full radar to be rendered)
const RadarChartWithData = ({
  id,
  loadableRadarDataAtom,
  isInteractive,
  isLoading,
}: {
  id: SBID;
  loadableRadarDataAtom: LoadableRadarDataAtom;
  isInteractive?: boolean;
  isLoading?: boolean;
}) => {
  const newRadarDataLoadable = useAtomValue(loadableRadarDataAtom);

  const newRadarData = newRadarDataLoadable.state === 'hasData' && newRadarDataLoadable.data;
  const setRenderedRadarData = useSetAtom(renderedRadarDataAtom);

  useDeepEffect(() => {
    if (newRadarData) setRenderedRadarData(newRadarData);
  }, [newRadarData]);

  return (
    <RadarChartBase
      id={id}
      isLoading={isLoading || newRadarDataLoadable.state === 'loading'}
      isInteractive={isInteractive}
    />
  );
};

// We want to prevent the aggregates query from being called until there are selected
// games and default seasons are populated. This component ensures that by rendering a
// placeholder component until those things are loaded
const RadarChartWithFallback = ({
  id,
  loadableSelectedGamesAtom,
  loadableRadarDataAtom,
  isInteractive,
}: {
  id: SBID;
  loadableSelectedGamesAtom: SelectedGamesAtom;
  loadableRadarDataAtom: LoadableRadarDataAtom;
  isInteractive?: boolean;
}) => {
  const defaultSeasons = useAtomValue(defaultSeasonsAtom);
  const loadableSelectedGames = useAtomValue(loadableSelectedGamesAtom);

  return (
    <RadarChartWithData
      id={id}
      loadableRadarDataAtom={loadableRadarDataAtom}
      isInteractive={isInteractive}
      isLoading={!defaultSeasons || loadableSelectedGames.state === 'loading'}
    />
  );
};

export const RadarChart = ({
  id,
  loadableSelectedGamesAtom,
  loadableRadarDataAtom,
  isWidget = false,
  isInteractive,
}: {
  id: SBID;
  loadableSelectedGamesAtom: SelectedGamesAtom;
  loadableRadarDataAtom: LoadableRadarDataAtom;
  isWidget?: boolean;
  isInteractive?: boolean;
}) => (
  <Suspense fallback={<RadarChartBase id={id} isLoading />}>
    {/* we don't care about waiting on the default seasons if in a widget */}
    {isWidget ? (
      <RadarChartWithData id={id} loadableRadarDataAtom={loadableRadarDataAtom} isInteractive={false} />
    ) : (
      <RadarChartWithFallback
        id={id}
        loadableSelectedGamesAtom={loadableSelectedGamesAtom}
        loadableRadarDataAtom={loadableRadarDataAtom}
        isInteractive={isInteractive}
      />
    )}
  </Suspense>
);
