import { unwrappedSelectedTeamNameAtom } from '@/atoms/team/info';
import { PageTitle } from '@/components/head/PageTitle';
import { TeamLayoutWithFilters } from '@/components/layout/team/TeamLayoutWithFilters';
import { TeamRadar } from '@/components/team/radar/TeamRadar';
import { useEntityPageTitle } from '@/hooks/useEntityPageTitle';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

export const TeamRadarPage = () => {
  const { t } = useTranslation('navigation');
  const teamName = useAtomValue(unwrappedSelectedTeamNameAtom);
  const pageTitle = useEntityPageTitle(t('secondary.radar'), teamName);

  return (
    <>
      <PageTitle>{pageTitle}</PageTitle>
      <TeamLayoutWithFilters>
        <TeamRadar />
      </TeamLayoutWithFilters>
    </>
  );
};
