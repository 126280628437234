import { Children } from 'react';
import { ButtonWithMenu } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { atom, useAtom } from 'jotai';
import { ScopeProvider } from 'jotai-scope';

export const moreOptionsMenuIsOpenAtom = atom(false);

interface MoreOptionsMenuProps {
  children: JSX.Element | (JSX.Element | undefined)[];
  placement?: string;
  title?: string;
  testId?: string;
  disabled?: boolean;
  size?: string;
  keysToExcludeFromClose?: string[];
}

const MoreOptionsMenuBase = ({
  children,
  placement = 'bottom-end',
  keysToExcludeFromClose = [],
  title,
  ...props
}: MoreOptionsMenuProps) => {
  const { t } = useTranslation('general');
  const [isOpen, setIsOpen] = useAtom(moreOptionsMenuIsOpenAtom);
  const menuTitle = title || t('optionsMenu');

  return (
    <ButtonWithMenu
      icon="More"
      isOpen={isOpen}
      onClick={() => setIsOpen(true)}
      onOutsideClick={() => setIsOpen(false)}
      placement={placement}
      title={menuTitle}
      variant="ghost"
      {...props}
    >
      {Children.map(children, menuItem => {
        /* handles overriding the setIsOpen(false) above to persist the open state on click */
        if (menuItem?.key && keysToExcludeFromClose.includes(menuItem.key)) {
          return menuItem;
        }

        return (
          <span onClick={() => setIsOpen(false)} role="presentation">
            {menuItem}
          </span>
        );
      })}
    </ButtonWithMenu>
  );
};

export const MoreOptionsMenu = (props: MoreOptionsMenuProps) => (
  <ScopeProvider atoms={[moreOptionsMenuIsOpenAtom]}>
    <MoreOptionsMenuBase {...props} />
  </ScopeProvider>
);

export const MoreOptionsMenuFallback = () => (
  <MoreOptionsMenu disabled>
    <span />
  </MoreOptionsMenu>
);
