import { unwrappedTeamDataVisibleColumnsAtom } from '@/atoms/dataLocker/team/table';
import { tableColumnsAtom } from '@/atoms/queries/tableColumns';
import { hasRetrievedAllTeamAggsAtom, teamAggsCountAtom, teamAggsDataAtom } from '@/atoms/queries/team/teamAggData';
import { ErrorBoundary } from '@sentry/react';
import { useAtom, useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { useSetDataLockerDebouncedFilterParams } from '@/hooks/apiFilterParams/useSetDataLockerDebouncedFilterParams';
import { TEAM_AGGS_FILTER_ATOMS } from '@/consts/dataLocker/team';
import { GeneralErrorBoundaryFallback } from '../errorBoundary/ErrorBoundaryFallback';
import { AggsTable } from './AggsTable';
import { AggsTableErrorFallback } from './AggsTableErrorFallback';
import { TablePagination } from './TablePagination';

const TeamAggsTableWithData = () => {
  useSetDataLockerDebouncedFilterParams(TEAM_AGGS_FILTER_ATOMS);
  const [{ data, isPending }] = useAtom(teamAggsDataAtom);
  const tableColumns = useAtomValue(tableColumnsAtom);
  const visibleColumns = useAtomValue(unwrappedTeamDataVisibleColumnsAtom);
  const hasRetrievedAllTeamAggs = useAtomValue(hasRetrievedAllTeamAggsAtom);

  return (
    <AggsTable
      data={data}
      isLoading={isPending}
      availableColumns={tableColumns}
      visibleColumns={visibleColumns}
      entity="team"
      hasRetrievedAllAggs={hasRetrievedAllTeamAggs}
    />
  );
};

export const TeamAggsTableWithPagination = () => (
  <ErrorBoundary fallback={<GeneralErrorBoundaryFallback />}>
    <AggsTableErrorFallback>
      <Suspense fallback={<AggsTable isLoading entity="team" />}>
        <TeamAggsTableWithData />
      </Suspense>
    </AggsTableErrorFallback>
    <TablePagination dataLengthAtom={teamAggsCountAtom} />
  </ErrorBoundary>
);
