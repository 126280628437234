import { useAtomValue } from 'jotai';
import { useSetMetricKey } from '@/hooks/useMetricKey';
import { useSetGameIdForVideos } from '@/hooks/useSetGameIdForVideos';
import { teamEventVideoIdsAtom, teamGameEventDescriptionsAtom } from '@/atoms/queries/team/teamEventData';
import { useSetTeamId } from '@/hooks/useTeamId';
import { GameStatsVideoLayout } from '@/components/layout/video/GameStatsVideoLayout';

export const TeamGamesVideoPage = () => {
  useSetMetricKey();
  useSetTeamId();
  useSetGameIdForVideos();

  const teamEventVideoIds = useAtomValue(teamEventVideoIdsAtom);
  const teamGameEventDescriptions = useAtomValue(teamGameEventDescriptionsAtom);

  return <GameStatsVideoLayout videoEventIds={teamEventVideoIds} descriptionsData={teamGameEventDescriptions} />;
};
