import { PITCH_COLOURING_MODES, PITCH_CROP, PITCH_ORIENTATION } from '@/consts/visualisations';
import { Nullable } from '@/types/generic';
import { ConfigVisualisation, PitchCrop, PitchMarkerColouringMode, PitchOrientation } from '@/types/visualisation';
import { atom } from 'jotai';
import { ChartOrientation } from '@statsbomb/kitbag-datavis';
import { visualisationConfigsAtom } from './queries/visualisation';

export const defaultVisualisationAtom = atom(async get => {
  const visConfigs = await get(visualisationConfigsAtom);
  return visConfigs[0];
});

export const pitchOrientationUserPreferenceAtom = atom<PitchOrientation>(PITCH_ORIENTATION[0]);
export const pitchOrientationReportWidgetAtom = atom<Nullable<PitchOrientation>>(null);
export const pitchOrientationAtom = atom(
  get => get(pitchOrientationReportWidgetAtom) || get(pitchOrientationUserPreferenceAtom),
  (get, set, orientation: PitchOrientation) => {
    set(pitchOrientationUserPreferenceAtom, orientation);
    if (get(pitchOrientationReportWidgetAtom)) {
      set(pitchOrientationReportWidgetAtom, null);
    }
  },
);
export const pitchCropAtom = atom<PitchCrop>(PITCH_CROP[0]);

export const showArrowsAtom = atom(false);

export const isStartPlacementAtom = atom(true);

// used to cancel out "end placement" selection while displaying arrows
export const showMarkerStartAtom = atom(get => {
  const isStartPlacement = get(isStartPlacementAtom);
  const showArrows = get(showArrowsAtom);

  return isStartPlacement || showArrows;
});

export const overwrittenVisualisationAtom = atom<Nullable<ConfigVisualisation>>(null);

export const selectedVisualisationAtom = atom(
  get => get(overwrittenVisualisationAtom) || get(defaultVisualisationAtom),
  (_get, set, action: Nullable<ConfigVisualisation>) => set(overwrittenVisualisationAtom, action),
);

export const showLegendAtom = atom(true);

export const pitchMarkerColouringModeAtom = atom<PitchMarkerColouringMode>(PITCH_COLOURING_MODES[0]);

export const barChartOrientationAtom = atom<ChartOrientation>('HORIZONTAL');
