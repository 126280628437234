import { useHydrateAtoms } from 'jotai/utils';
import { metricKeyAtom } from '@/atoms/metric';
import { ErrorCard } from '@/components/contentState/ErrorCard';
import { HeatMapBase } from '@/components/visualisations/HeatMap';
import { TeamHeatMap } from './TeamHeatMap';

const TeamDefensiveActivityWithData = () => {
  useHydrateAtoms([[metricKeyAtom, 'defensive_actions']]);

  return <TeamHeatMap />;
};

export const TeamDefensiveActivity = () => (
  <ErrorCard translationNameSpace="general" fallbackChildren={<HeatMapBase eventsForMetric={[]} />}>
    <TeamDefensiveActivityWithData />
  </ErrorCard>
);
