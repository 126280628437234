import { ErrorBoundary } from '@sentry/react';
import { StatusTranslationKey } from '@/types/status';
import { ReactNode } from 'react';
import { TableWrapper } from './TableWrapper';
import { ContentState } from '../contentState/ContentState';

export const AggsTableErrorFallback = ({ children }: { children: ReactNode }) => (
  <ErrorBoundary
    fallback={
      <TableWrapper>
        <ContentState status={StatusTranslationKey.ERROR} />
      </TableWrapper>
    }
  >
    {children}
  </ErrorBoundary>
);
