import { pitchMarkerColouringModeAtom } from '@/atoms/visualisation';
import { PitchOrientation } from '@/types/visualisation';
import { AttackingDirectionArrow } from '@statsbomb/kitbag-datavis';
import { useAtomValue } from 'jotai';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { twJoin } from 'tailwind-merge';

export const PitchVisWithAttackingDirectionArrow = ({
  pitchRotation,
  children,
  pitchVisType,
}: {
  pitchRotation: PitchOrientation;
  children: ReactNode;
  pitchVisType?: 'eventmap' | 'heatmap';
}) => {
  const { t } = useTranslation('visualisation');
  const pitchMarkerColouringMode = useAtomValue(pitchMarkerColouringModeAtom);
  const hasLegend = pitchVisType === 'heatmap' || pitchMarkerColouringMode !== 'default';
  /* when the legend is in xg colour mode it renders 2 legend items, whereas non xg mode only renders 1 currently
    the height of xg mode is 94px + 8px gap = 102px
    the height of non xg mode is 54px + 8px gap = 62px
    these values allow the pitch and legend to fit correctly into the card
  */
  const legendHeight = pitchMarkerColouringMode === 'xg' ? 'h-[calc(100%-102px)]' : 'h-[calc(100%-62px)]';

  return (
    <div
      className={twJoin(
        'w-full flex justify-center gap-2',
        pitchRotation === 'VERTICAL' && 'flex-row',
        pitchRotation === 'VERTICAL_REVERSE' && 'flex-row-reverse',
        (pitchRotation === 'HORIZONTAL' || pitchRotation === 'HORIZONTAL_REVERSE') && 'flex-col-reverse',
        !hasLegend ? 'h-full' : legendHeight,
      )}
    >
      <div className={twJoin((pitchRotation === 'VERTICAL_REVERSE' || pitchRotation === 'HORIZONTAL') && 'self-end')}>
        <AttackingDirectionArrow rotationName={pitchRotation} text={t('attackingDirection')} />
      </div>
      {children}
    </div>
  );
};
