import { CONFIG_NAME_MAX_LENGTH } from '@/consts/userConfigs';
import { ConfirmationDialog, TextField } from '@statsbomb/kitbag-components';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const GenericSaveModal = ({
  id,
  title,
  onConfirm,
  onCancel,
  confirmLabel,
  value,
  onChange,
  existingConfigNames,
}: {
  id: string;
  title: string;
  onConfirm: () => Promise<void>;
  onCancel: () => void;
  confirmLabel: string;
  value: string;
  onChange: (newValue: string) => void;
  existingConfigNames: string[];
}) => {
  const { t } = useTranslation(['general', 'modal']);
  const [isLoading, setIsLoading] = useState(false);

  const saveModalInputRef = useRef<HTMLInputElement>(null);

  const isEmptyValue = value.trim() === '';
  const isTooLong = value.length > CONFIG_NAME_MAX_LENGTH;
  const isValueTaken = existingConfigNames.includes(value);

  const isDisabledConfirm = isEmptyValue || isTooLong || isValueTaken;

  const getErrorMessage = () => {
    if (isTooLong) {
      return t('saveModal.maxCharacters', { ns: 'modal' });
    }

    if (isValueTaken) {
      return t('saveModal.alreadyExists', { ns: 'modal' });
    }

    return undefined;
  };
  const errorMessage = getErrorMessage();

  // auto selects the whole text within the input when the modal opens
  useEffect(() => saveModalInputRef.current?.select(), []);

  return (
    <ConfirmationDialog
      id={id}
      testId={id}
      title={title}
      isOpen
      onConfirm={async () => {
        setIsLoading(true);
        await onConfirm();
        setIsLoading(false);
      }}
      onCancel={onCancel}
      cancelLabel={t('cancel')}
      confirmLabel={confirmLabel}
      isDisabledConfirm={isDisabledConfirm}
      isPendingConfirm={isLoading}
    >
      <div className="sm:min-w-[400px] md:min-w-[450px]">
        <TextField
          ref={saveModalInputRef}
          id={`${id}-input`}
          label={t('saveModal.label', { ns: 'modal' })}
          labelPosition="top"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
          value={value}
          errorMessage={errorMessage}
        />
      </div>
    </ConfirmationDialog>
  );
};
