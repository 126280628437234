import { useAtomValue } from 'jotai';
import { CardBody } from '@statsbomb/kitbag-components';
import { playerShotMarkersAtom, unwrappedPlayerShotMarkersAtom } from '@/atoms/queries/player/playerEventData';
import { useStatus } from '@/hooks/useStatus';
import { ShotMap } from '@/components/visualisations/ShotMap/ShotMap';
import { useLoadableStatus } from '@/hooks/useLoadableStatus';
import { CardHeightWrapper } from '@/components/card/CardHeightWrapper';
import { ErrorCard } from '@/components/contentState/ErrorCard';

const PlayerShotMapWithData = () => {
  const unwrappedPlayerShotMarkers = useAtomValue(unwrappedPlayerShotMarkersAtom);
  const status = useLoadableStatus(playerShotMarkersAtom);
  const statusProps = useStatus('general', status);

  return (
    <CardHeightWrapper>
      <CardBody {...statusProps}>
        <ShotMap events={unwrappedPlayerShotMarkers} />
      </CardBody>
    </CardHeightWrapper>
  );
};

export const PlayerShotMap = () => (
  <ErrorCard translationNameSpace="general" fallbackChildren={<ShotMap events={[]} />}>
    <PlayerShotMapWithData />
  </ErrorCard>
);
